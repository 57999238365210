<template>
    <div id="equipment">
        <div class="center-left">
            <div class="center-top">
                <div class="right-abnormal">
                    <div class="title-view">
                        <span class="title-text">我的设备</span>
                    </div>
                    <div class="right-list">
                        <div class="eq-item" v-for="item in equipmentList" :key="item.equipmentID">
                            <div class="icon-view" :class="item.isSetup?'':'icon-view-none'">
                                <span class="iconfont" :class="setIcon(item.equipmentID)"></span>
                            </div>
                            <span style="height:32px">{{item.equipmentName}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="oder-view">
                <div class="oder-center">
                    <div class="title-view">
                        <span class="title-text">设备列表</span>
                    </div>
                    <div class="tab-view">
                        <Table border :columns="columns12" :data="data6">
                            <template slot-scope="{ row }" slot="name">
                                <strong>{{ row.name }}</strong>
                            </template>
                            <template slot-scope="{ row }" slot="action">
                                <span v-if="row.isSetup==1">已安装</span>
                                <div v-else>未安装</div>
                            </template>
                            <template slot-scope="{ row,index }" slot="img">
                                <Poptip placement="left" width="200" @on-popper-show="onPopperShow(index)" @on-popper-hide="onPopperHide(index)">
                                    <img v-if="row.imgShow" class="btnImg" src="@/assets/image/show.png">
                                    <img v-else class="btnImg" src="@/assets/image/hide.png" alt="">
                                    <div class="img-wrap" slot="content">
                                        <img class="img-item" v-if="row.installUrl" :src="row.installUrl" alt=""> 
                                        <div v-else>暂无图片</div>
                                    </div>
                                </Poptip>
                            </template>
                        </Table>
                    </div>
                </div>
            </div>
            <div class="oder-view" style="paddingTop:30px;">
                <div class="oder-center">
                    <div class="title-view">
                        <span class="title-text">设备巡检</span>
                    </div>
                    <div class="tab-view">
                        <Table border :columns="columns1" :data="data1">
                            
                        </Table>
                    </div>
                </div>
            </div>
            <div class="oder-view" style="paddingTop:30px;">
                <div class="oder-center">
                    <div class="title-view">
                        <span class="title-text">设备应用</span>
                    </div>
                    <div class="tab-view">
                        <Table border :columns="columns2" :data="data2">
                 
                        </Table>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="center-right">
            <div class="healthy-right">
                <div class="user-info">
                    <div class="user-name">
                        <span class="name">{{baseInfo.userName?baseInfo.userName:'--'}}</span>
                        <span class="sex" v-if="baseInfo.sex===0">男</span>
                        <span class="sex" v-else-if="baseInfo.sex===1">女</span>
                    </div>
                    <div class="user-cerner">
                        <span class="center-title">地址：</span>
                        <p class="center-cotnet">{{baseInfo.address}}</p>
                    </div>
                </div>
                <div class="account-box">
                    <el-calendar v-model="value"></el-calendar>
                </div>
                <div class="right-img">
                    <img style="width:100%;height:100%" src="@/assets/image/right-bg.png" alt="">
                </div>
                
            </div>
        </div> -->

    </div>
</template>

<script>
    var echarts = require('echarts/lib/echarts');
    let myChart
    import axios from '@/utils/http.js'
    import wodrHttp from '@/utils/wodrHttp'
    import {mapState} from 'vuex'
    import countTo from 'vue-count-to'
    export default {
        name: "Home",
        components: {
            countTo
        },
        data () {
            return {
                columns12: [
                    {
                        title: '设备ID',
                        key: 'equipmentNo'
                    },
                    {
                        title: '设备型号',
                        key: 'equipmentModel'
                    },
                    {
                        title: '设备名称',
                        key: 'equipmentName'
                    },
                    {
                        title: '设备状态',
                        slot: 'action',
                        align: 'center'
                    },
                    {
                        title: '安装图片',
                        slot: 'img',
                        align: 'center'
                    }
                ],
                columns1: [
                    {
                        title: '巡检时间',
                        key: 'checkTime'
                    },
                    {
                        title: '巡检地址',
                        key: 'address'
                    },
                    {
                        title: '巡检结果',
                        key: 'result'
                    }
                ],
                columns2: [
                    {
                        title: '设备名称',
                        key: 'name'
                    },
                    {
                        title: '异常时间',
                        key: 'createTime'
                    },
                    {
                        title: '异常地址',
                        key: 'address'
                    },
                    {
                        title: '异常内容/代码',
                        key: 'content'
                    },
                    {
                        title: '处理结果',
                        key: 'result'
                    },
                ],
                data6: [],
                data1:[],
                data2:[],
                equipmentList:[],
                abnormal:{
                    hujiao:{
                        yichang:[],
                        chuli:[]
                    },
                    yanwu:{
                        yichang:[],
                        chuli:[]
                    },
                    ranqi:{
                        yichang:[],
                        chuli:[]
                    },
                    shuiqin:{
                        yichang:[],
                        chuli:[]
                    }
                },
                carouselData:[],
                menciData:[],
                report1:0,
                report2:0,
                modal1:false,
                tableList:[],
                mdalTitle:'',
                isSetup:'',
                value:'',
                tabList:[
                    {
                        label:'状态分布',
                        value:1
                    },
                    {
                        label:'异常分布',
                        value:2
                    },
                    {
                        label:'体动分布',
                        value:3
                    },
                    {
                        label:'心率分布',
                        value:4
                    },
                    {
                        label:'呼吸分布',
                        value:5
                    }
                ],
                tabActive:{
                    label:'速率分布',
                    value:1
                },
                echartDatas:{},
                echartMonitor:{},
                infoData:{
                    xinlv:0,
                    huxi:0,
                    zaichuang:'--',
                    guohuan:0,
                    zanting:0,
                    caoshi:0
                },
                users:{
                    name: "--",
                    belongAreaName: "--",
                    genderName:'',
                    age:'--',
                    tenantsName:''
                },
                reportData1:[],
                reportData2:[],
                echartTimes:[],
                dateTime:this.setTime()
            }
        },
        mounted(){
            if(!this.vcCardNO) return
            this.getAbnormalData()
            // this.getlistsData()
            // this.getchartData()
            // document.getElementById('ysOpenDevice').src = '/static/ui_voice.html?accessToken=at.bdxzx77idwf54ljybhh5yg161k4ryycl-4u9rkuwbyy-0nj0yfh-ffwmrvook'+'&ezopen='+'ezopen://open.ys7.com/G43144313/1.hd.live'
        },
        computed:{...mapState(['userId','type','vcCardNO','phone','baseInfo'])},
        methods: {
            getAbnormalData(){
                axios.get(this.$api.GetUserFamilyBedDevice + `?cardNo=${this.vcCardNO}`).then(res=>{
                    this.equipmentList = res.data.result
                    res.data.result.forEach(item=>{
                        item.imgShow = false
                    })
                    let a = res.data.result.filter(item=>{
                        return item.isSetup
                    })
                    let b = res.data.result.filter(item=>{
                        return !item.isSetup
                    })
                    this.data6 = [...a]
                })
                axios.get(this.$api.GetUserDeviceCheckWorkOrder + `?cardNo=${this.vcCardNO}`).then(res=>{
                    console.log(res)
                    this.data1 = res.data.result
                })
                axios.get(this.$api.GetFamilyBedAlarmWorkOrderPager + `?cardNo=${this.vcCardNO}&pageIndex=0&pageSize=10`).then(res=>{
                    console.log(res)
                    this.data2 = res.data.result.dataList
                })
                // //紧急呼叫
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'c530db59-713b-4aa7-826f-e050208178ee').then(res=>{ //紧急呼叫异常
                //     if(res.data.code===200) {
                //         this.abnormal.hujiao.yichang = res.data.result.alarmDataList
                //         this.abnormal.hujiao.chuli = res.data.result.doneDataList
                //     }
                // })
                // //烟雾告警
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'9807fd8e-82b1-4751-ab10-363c6b889c22').then(res=>{
                //     if(res.data.code===200) {
                //         this.abnormal.yanwu.yichang = res.data.result.alarmDataList
                //         this.abnormal.yanwu.chuli = res.data.result.doneDataList
                //     }

                // })
                // //燃气告警
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'617ef70c-e907-4e21-8807-eaa520609abf').then(res=>{
                //     if(res.data.code===200) {
                //         this.abnormal.ranqi.yichang = res.data.result.alarmDataList
                //         this.abnormal.ranqi.chuli = res.data.result.doneDataList
                //     }
                // })
            },
            getlistsData(){
                axios.get('AFXFamilyBed/Equipment/GetUserFamilyBedDevice?cardNo='+this.vcCardNO).then(res=>{
                    if(res.data.code===200) {
                        res.data.result.forEach(item=>{
                            item.imgShow = false
                        })
                        let a = res.data.result.filter(item=>{
                            return item.isSetup
                        })
                        let b = res.data.result.filter(item=>{
                            return !item.isSetup
                        })
                        this.data6 = [...a]
                    }
                    console.log(this.data6)
                })
            },
            getchartData(){
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    // type: 'ios-sunny-outline',
                                    // type: 'md-sunny',
                                    // type: 'md-resize',
                                    type: 'md-nuclear',
                                    size: 22
                                }
                            }),
                            h('div', '加载中...')
                        ])
                    }
                });
                axios.get('AFXFamilyBed/FamilyBedData/GetActivityDataDayReport?cardNo='+this.vcCardNO+'&topCount=30').then(res=>{
                    if(res.data.code===200){
                        this.report1 = res.data.result.data.length===0?0:res.data.result.data[res.data.result.data.length-1]
                        this.reportData1 = res.data.result.data
                        this.echartTimes = res.data.result.times
                    }
                })
            },
            getList(id,state,title){
                this.isSetup = state
                this.tableList = this.abnormal[id][state]
                this.mdalTitle = title
                this.modal1 = true
            },
            setTime(){
                let time = new Date()
                let year = time.getFullYear()
                let mont = time.getMonth()+1
                let day = time.getDate()
                return year+'-'+mont+'-'+day
            },
            setIcon(id){
                let result = ''
                console.log(id)
                switch(id) {
                    case '1BB16450-9EAB-42EE-AB7E-F36917FB2587':  //门磁智能监测终端
                        result = 'icon-a-zu3967' 
                        break;
                    case '36B6C234-B927-40AA-A833-65C3936DB0F7':  //人体活动智能监测终端
                        result = 'icon-a-zu1386'
                        break;
                    case '55D203DF-7A32-4A03-A19C-6C019DBC2256':  //智能监测主机
                        result = 'icon-a-zu39691'
                        break;
                    case '686E51E1-7E82-4F73-A2E2-39E46038C629':  //烟火探测报警器
                        result = 'icon-a-zu1407'
                        break;
                    case '83a3df26-b152-4eae-9cd5-dbf419d2a70e':  //摄像头
                        result = 'icon-a-zu3965'
                        break;
                    case '95294537-EE3F-4573-96A7-C9DBCF3B3861':  //无线呼叫器
                        result = 'icon-a-zu3966'
                        break;
                    case 'AF5CA316-92A2-4302-9877-7FD6FDD8A135':  //燃气智能监测终端
                        result = 'icon-a-zu1406'
                        break;
                    case 'C5718444-3A0F-459E-AA38-6265C0E77D42':  //智能床垫
                        result = 'icon-chuang1'
                        break;    
                    case '725abed0-83f6-4049-bed8-4cb48cda7bfc':  //智能床垫
                        result = 'icon-chuang1'
                        break;     
                    case '61cd35fa-7114-4763-892d-f9d26c0bc3b3':  //水浸智能监测终端
                        result = 'icon-a-zu39681'
                        break;    
                    // case '设备巡检':  //设备巡检
                    //     result = 'iconfont icon-shebei'
                    //     break;
                    // case '燃气报警':  //燃气报警
                    //     result = 'iconfont icon-ranqiguan'
                    //     break;
                    // case '电话回访':  //电话回访
                    //     result = 'iconfont icon-caozuo-dianhua'
                    //     break;
                    // case '上门服务':  //上门服务
                    //     result = 'iconfont icon-shangmenfuwu'
                    //     break;
                    // case '烟感报警':  //烟感报警
                    //     result = 'iconfont icon-yangan'
                    //     break;
                    // case '紧急呼叫':  //紧急呼叫
                    //     result = 'iconfont icon-gaojing-jinji'
                    //     break;                                             
                    default:
                        result = 'iconfont icon-jingshenke'
                }
                return result 
            }
        },
        watch: {
            value(val,avl){
                let time = val.getFullYear()+'-'+(val.getMonth()+1)+'-'+val.getDate()
                this.dateTime = time
            }
        }
    };
</script>

<style lang="scss">
    #equipment {
        width: 100%;
        padding: 20px;
        // display: flex;
        overflow-y: auto;
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 20px;
        }
        .healthy-right {
            display: flex;
            flex-direction: column;
            height: 100%;
            .account-box {
                background: #FFFFFF;            
                border-radius: 5px;
                box-shadow: 0px 1px 4px #E5E9F2;
                overflow: hidden;
                flex-shrink: 0;
            }
            .healthy-bottom {
                .healthy-bottom-titles {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 20px;
                    background-color: #fff;
                    box-shadow: 0px 4px 15px #D6DCEA;
                    border-radius: 5px;
                    margin-top: 30px;
                    .frequency {
                        display: flex;
                        align-items: center;
                        .img-view {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: #FFEABB;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            .title-img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                    .frequency-num {
                        color: #F85F3C;
                        font-size: 23px;
                        font-weight: 700;
                    }
                }
                .healthy-bottom-list {
                    display: flex;
                    flex-direction: column;
                    .healthy-bottom-item {
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;
                        height: 47px;
                        background-color: #fff;
                        margin-top: 10px;
                        color: #999999;
                    }
                }
            }
            .right-img {
                flex: 1;
                margin: 20px 0 0;
                flex-shrink: 0;
                background: url('../assets/image/right-bg.png') no-repeat;
                background-size: 100% 100%;
            }
        }
        .oder-view-imgs {
            width: 100%;
            display: flex;
            .oder-view-left {
                flex: 1;
            }
        }
        .oder-view {
            .oder-center {
                flex: 1;
                .title-view {
                    margin-bottom: 0;
                }
            }
            .oder-right {
                width: 350px;
                height: 850px;
                background: url('../assets/image/right-bg.png') no-repeat;
                background-size: 100% 100%;
                // margin-top: 40px;
                margin-left: 20px;
                border-radius: 5px;
            }
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .center-left {
            display: flex;
            flex-direction: column;
            flex: 1;
            .center-top {
                // display: flex;
                // margin-bottom: 23px;
            }
            .right-abnormal {
                overflow: hidden;
                .right-list {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    border-radius: 5px;
                    padding:0 0 10px;
                    .eq-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        // height: 90px;
                        background-color: #fff;
                        box-shadow: 0px 4px 15px #D6DCEA;
                        border-radius: 5px;
                        font-size: 12px;
                        color: #2176FF;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        padding: 10px 10px 5px;
                        .icon-view {
                            width: 45px;
                            height: 45px;
                            background-color: #35C196;
                            border-radius: 50%;
                            margin-bottom: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .iconfont {
                                color: #fff;
                                font-size: 24px;
                            }
                        }
                        .icon-view-none {
                            background-color: #DCDDDE;
                        }
                    }
                }
            }
            .tab-view {
                margin-top: 14px;
                width: 100%;
                // height: 390px;
                // margin-bottom: 30px;
                // display: flex;
            }
            .img-wrap {
                .img-item {
                    width: 150px;
                    height: 150px;
                }
            }
        }
        .center-right {
            width: 270px;
            // background-color: #fff;
            margin-left: 20px;
            flex-shrink: 0;
            .equi-view {
                margin-top: 6px;
                display: flex;
                flex-direction: column;
                .view-title {
                    color: #31394D;
                    margin-bottom: 5px;
                    text-align: left;
                }
                .equi-list {
                    display: flex;
                    flex-direction: column;
                    padding: 14px 19px 0;
                    background-color: #fff;
                    box-shadow: 0px 1px 4px #E5E9F2;
                    border-radius: 5px;
                    .equi-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        .item-img {
                            width: 60px;
                            height: 60px;
                            margin-right: 11px;
                        }
                        .item-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .title1 {
                                color: #31394D;
                            }
                            .title2 {
                                font-size: 12px;
                                color: #748AA1;
                                margin-top: 2px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .user-info {
                width: 100%;
                height: 90px;
                flex-shrink: 0;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);              
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                color: #fff;
                padding: 0 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 20px;
                .user-name {
                    display: flex;
                    align-items: center;
                    .user-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #ccc;
                        margin-right: 10px;
                    }
                    .name {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                    .sex {
                        font-size: 12px;
                    }
                }
                .user-cerner {
                    display: flex;
                    margin-top: 5px;
                    font-size: 12px;
                    .center-title {
                        flex-shrink: 0;
                    }
                    .center-cotnet {
                        text-align: left;
                    }
                }
            }
        }
        .btnImg {
            width: 30px;
            height: 30px;
        }
        .el-calendar-table .el-calendar-day{
            height: 38px;
            line-height: 34px;
            padding: 0;
            span {
                display: inline-block;
                width: 28px;
                height: 35px;
                background-color: #E5E9F2;
                border-radius: 5px;
                color: #91a3b6;
            }
        }
        .el-calendar-table td {
            border: 0;
        }
        .el-calendar-table .el-calendar-day:hover {
            span {
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar-table .is-today{
            span{
                background-color: #fff;
                border: 1px solid #2176FF;
                color: #7b90a6;
            }
        }
        .el-calendar-table .is-selected {
            span{
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar__body {
            padding: 0 15px 10px;
        }
        .el-calendar__header {
            display: flex;
            flex-direction: column;
            border-bottom: 0;
            padding: 12px 20px 0;
        }
        .el-calendar-table .prev,
        .el-calendar-table .next {
            span {
                background-color: #F4F7FB;
            }
        }
    }
</style>
