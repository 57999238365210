<template>
    <div id="home2">
        <div class="homeLeft">
            <Card style="width:100%;height:100%">
                <!-- 账户主要表格 -->
                <div class="account-box">
                    <el-calendar v-model="value" @click="calendarCahnge"></el-calendar>
                </div>
                <div class="surveUnusual">
                    <div class="survey">
                        <h5>健康概况</h5>
                        <ul>
                            <li><span>平均心率</span><span id="xl">{{infoData.xinlv+' bpm'}}</span></li>
                            <li><span>平均呼吸率</span><span id="hxl">{{infoData.huxi+' bpm'}}</span></li>
                            <li><span>在床时间</span><span id="zc">{{infoData.zaichuang}}</span></li>
                        </ul>
                    </div>
                    <div class="unusual">
                        <h5>异常状态信息</h5>
                        <ul>
                            <li><span>呼吸过缓</span><span id="hxgh">{{infoData.guohuan+'次'}}</span></li>
                            <li><span>呼吸暂停</span><span id="hxzt">{{infoData.zanting+'次'}}</span></li>
                            <li><span>离床超时</span><span id="lccs">{{infoData.caoshi+'次'}}</span></li>
                        </ul>
                    </div>
                </div>
            </Card>
        </div>
        <div class="homeRight">
            <h4>{{times.time1+' - '+ times.time2}}</h4>
            <Card style="width:100%;height:315px">
                <div v-if="chartShow" id="echarts1" class="echarts"  ref="chart1">

                </div>
                <div v-else class="textNone">暂无数据</div>
            </Card>   
            <Card style="width:100%;height:315px">
                <div v-if="chartShow" id="echarts2" class="echarts"  ref="chart2">

                </div>
                <div v-else class="textNone">暂无数据</div>
            </Card>   
            <Card style="width:100%;height:315px">
                <div v-if="chartShow" id="echarts3" class="echarts"  ref="chart3">

                </div>
                <div v-else class="textNone">暂无数据</div>
            </Card>   
            <Card style="width:100%;height:315px">
                <div v-if="chartShow" id="echarts4" class="echarts"  ref="chart4">

                </div>
                <div v-else class="textNone">暂无数据</div>
            </Card>   
            <Card style="width:100%;height:315px">
                <div v-if="chartShow" id="echarts5" class="echarts"  ref="chart5"> </div>
                <div v-else class="textNone">暂无数据</div>
            </Card>                                       
        </div>
    </div>
</template>

<script>
import axios from '@/utils/http.js'
import {mapState} from 'vuex'
var echarts = require('echarts/lib/echarts');
export default {
    data () {
        return {
            value: new Date(),
            infoData:{
                xinlv:0,
                huxi:0,
                zaichuang:'--',
                guohuan:0,
                zanting:0,
                caoshi:0
            },
            times:{
                time1:'--',
                time2:'--'
            },
            chartShow:true,
            dateTime:this.setTime()
        }
    },
    computed:{...mapState(['userId','type','phone','tag','vcCardNO'])},
    mounted () {
        if(!this.vcCardNO) return this.chartShow = false
        if(this.phone) {
            this.getchartData()
        }else {
            axios.get('UserInfo/GetSimpleInfoByCardNo?cardNo='+this.vcCardNO+'&tag='+this.tag).then(res=>{
                this.$store.commit('setUserId',res.data.result.id) 
                this.$store.commit('setPhone',res.data.result.mobile)
                this.getchartData(res.data.result.mobile)
            })
        }
    },
    methods: {
        setTime(){
            let time = new Date()
            let year = time.getFullYear()
            let mont = time.getMonth()+1
            let day = time.getDate()
            return year+'-'+mont+'-'+day
        },
        getchartData(phone){
            this.$Spin.show({
                render: (h) => {
                    return h('div', [
                        h('Icon', {
                            'class': 'demo-spin-icon-load',
                            props: {
                                // type: 'ios-sunny-outline',
                                // type: 'md-sunny',
                                // type: 'md-resize',
                                type: 'md-nuclear',
                                size: 22
                            }
                        }),
                        h('div', '加载中...')
                    ])
                }
            });
            phone = phone?phone:this.phone
            axios.get('http://47.110.226.187:8070/api/AnfusleepHealthDaily/GetUserHealthDailyDetails?date='+this.dateTime+'&phoneNumber='+phone).then(res=>{
                if(res.data.code==200){
                    // this.$Spin.hide()
                    let mycharArr = res.data
                    let xData2 = []
                    this.infoData.xinlv = mycharArr.data.healthSituation.avgHeart
                    this.infoData.huxi = mycharArr.data.healthSituation.avgBreath
                    this.infoData.zaichuang = mycharArr.data.healthSituation.onBedTime
                    this.infoData.guohuan = mycharArr.data.chartData.warningDetails.heartSlow.length
                    this.infoData.zanting = mycharArr.data.chartData.warningDetails.apneaCount.length
                    this.infoData.caoshi = mycharArr.data.chartData.warningDetails.leaveBed.length
                    this.times.time1 = mycharArr.data.baseInfo.monitorStartDate
                    this.times.time2 = mycharArr.data.baseInfo.monitorEndDate
                    var myChart1 = echarts.init(this.$refs.chart1);
                    var char1Data = mycharArr.data.chartData.onBedStatusDetails.map(item=>{
                        return setChar1(item)
                    })
                    mycharArr.data.chartData.xData.map((item,index)=>{
                        if(index%5==0){
                            xData2.push(item)
                        }
                    })
                    function setChar1(item) {
                        if(item==1) {
                            return '离床'
                        }else if(item==2) {
                            return '离线'
                        }else {
                            return '在床'
                        }
                    } 
                    myChart1.setOption({},true)
                    myChart1.setOption({
                        title: {
                            text: '状态分布'
                        },
                        color:['#33a6f9'],
                        tooltip: {
                            trigger: 'axis',
                            formatter: `{b}:{c}`
                        },
                        dataZoom: [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                xAxisIndex: [0],
                                filterMode: 'filter'
                            }
                        ],
                        xAxis: {
                            type: 'category',
                            data: mycharArr.data.chartData.xData
                        },
                        yAxis: {
                            type: 'category',
                            data: ['离床', '离线', '在床']
                        },
                        series: [{
                            data: char1Data,
                            type: 'line',
                            smooth: true
                        }]
                    });
                var myChart2 = echarts.init(this.$refs.chart2);
                    myChart2.setOption({},true)
                    myChart2.setOption({
                        xAxis: {
                            type: 'category',
                            data: mycharArr.data.chartData.xData
                        },
                        legend: {
                            data: ['离床超时', '心率过速', '心率过缓', '呼吸暂停','呼吸过速','呼吸过缓']
                        },
                        title: {
                            text: '异常状态分布'
                        },
                        color:['#33a6f9','#8066e1','#fa9020','#ff4946','#ffc100','#18d777'],
                        dataZoom: [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                xAxisIndex: [0],
                                filterMode: 'filter'
                            }
                        ],
                        tooltip: {
                            formatter:function(value){

                                return value.data[0]+' '+ value.seriesName
                            }
                        },
                        yAxis: {
                            type: 'value',
                            min: '0',
                            max: '6',
                            show:false,
                            splitLine: {
                                show: true
                            }
                        },
                        series: [
                            {
                                name: '离床超时',
                                type: 'scatter',
                                itemStyle: {
                                    normal: {
                                        opacity: 0.8
                                    }
                                },
                                symbolSize: function (val) {
                                    return  8;
                                },
                                data: mycharArr.data.chartData.warningDetails.leaveBed
                            },
                            {
                                name: '心率过速',

                                type: 'scatter',
                                data: mycharArr.data.chartData.warningDetails.heartFast
                            },
                            {
                                name: '心率过缓',
                                type: 'scatter',
                                itemStyle: {
                                    normal: {
                                        opacity: 0.8
                                    }
                                },
                                symbolSize: function (val) {
                                    return  8;
                                },
                                data: mycharArr.data.chartData.warningDetails.heartSlow
                            },
                            {
                                name: '呼吸暂停',
                                type: 'scatter',
                                itemStyle: {
                                    normal: {
                                        opacity: 0.8
                                    }
                                },
                                symbolSize: function (val) {
                                    return  8;
                                },
                                data: mycharArr.data.chartData.warningDetails.apneaCount
                            },
                            {
                                name: '呼吸过速',
                                type: 'scatter',
                                itemStyle: {
                                    normal: {
                                        opacity: 0.8
                                    }
                                },
                                symbolSize: function (val) {
                                    return  8;
                                },
                                data: mycharArr.data.chartData.warningDetails.breathFast
                            },
                            {
                                name: '呼吸过缓',
                                type: 'scatter',
                                itemStyle: {
                                    normal: {
                                        opacity: 0.8
                                    }
                                },
                                symbolSize: function (val) {
                                    return  8;
                                },
                                data: mycharArr.data.chartData.warningDetails.breathSlow
                            },
                        ]
                    });
                    var myChart3 = echarts.init(this.$refs.chart3);
                    myChart3.setOption({},true)
                    myChart3.setOption({
                        title: {
                            text: '体动分布'
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter:function(value){
                                return value[0].data[0]+'   '+ value[0].data[1]+'次'
                            }
                        },
                        color:['#18d777'],
                        dataZoom: [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                xAxisIndex: [0],
                                filterMode: 'filter'
                            }
                        ],
                        xAxis: {
                            type: 'category',
                            data: mycharArr.data.chartData.xData
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: mycharArr.data.chartData.bodyMoveDetails,
                            type: 'bar'
                        }]
                    });

                    var myChart4 = echarts.init(this.$refs.chart4);
                    var chart4Xdata=[],chart4Ydata=[]
                    mycharArr.data.chartData.heartDetails.forEach(item=>{
                        chart4Xdata.push(item[0])
                        chart4Ydata.push(item[1]==-1?null:item[1])
                    })
                    myChart4.setOption({},true)
                    myChart4.setOption({
                        title: {
                            text: '心率分布'
                        },
                        color:['#ffdc4c'],
                        dataZoom: [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                xAxisIndex: [0],
                                filterMode: 'filter'
                            }
                        ],
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params) {
                                var a
                                if(params.componentType==='markPoint') {
                                    a = params.name + ' ' + params.value +'bpm'
                                }else {
                                    a =  params.name + ' ' + params.value[1] +'bpm'
                                }
                                return a
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: xData2
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                type: 'line',
                                data: chart4Ydata,
                                markPoint: {
                                    data: [
                                        {
                                            type: 'max', 
                                            name: '最大值',
                                            symbolOffset:[0,-12],
                                            label:{
                                                color:'#ff4946',
                                                fontWeight:700
                                            }
                                        },
                                        {
                                            type: 'min', 
                                            name: '最小值',
                                            symbolOffset:[0,12],
                                            label:{
                                                color:'#33a6f9',
                                                fontWeight:700
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    });

                    var myChart5 = echarts.init(this.$refs.chart5);
                    var chart5Xdata=[],chart5Ydata=[]
                    mycharArr.data.chartData.breathDetails.forEach(item=>{
                        chart5Xdata.push(item[0])
                        chart5Ydata.push(item[1]==-1?null:item[1])
                    })
                    myChart5.setOption({},true)
                    myChart5.setOption({
                        dataZoom: [
                            {
                                id: 'dataZoomX',
                                type: 'slider',
                                xAxisIndex: [0],
                                filterMode: 'filter'
                            }
                        ],
                        color:['#fa9020'],
                        title: {
                            text: '速率分布'
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params) {
                                var a
                                if(params.componentType==='markPoint') {
                                    a = params.name + ' ' + params.value +'bpm'
                                }else {
                                    a =  params.name + ' ' + params.value[1] +'bpm'
                                }
                                return a
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data:chart5Xdata
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                type: 'line',
                                data: chart5Ydata,
                                markPoint: {
                                    data: [
                                        {
                                            type: 'max', 
                                            name: '最大值',
                                            label:{
                                                color:'#8066e1',
                                                fontWeight:700
                                            }
                                        },
                                        {
                                            type: 'min', 
                                            name: '最小值',
                                            label:{
                                                color:'#18d777',
                                                fontWeight:700
                                            }
                                        }
                                    ]
                                },
                            }
                        ]
                    });
                }else {
                    this.$Spin.hide()
                    var myChart1 = echarts.init(this.$refs.chart1);
                    var myChart2 = echarts.init(this.$refs.chart2);
                    var myChart3 = echarts.init(this.$refs.chart3);
                    var myChart4 = echarts.init(this.$refs.chart4);
                    var myChart5 = echarts.init(this.$refs.chart5);
                    myChart1.setOption({
                        xAxis: {
                            type: 'category',
                            data: ['暂无数据']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: [],
                            type: 'line',
                        }]
                    },true)
                    myChart2.setOption({
                        xAxis: {
                            type: 'category',
                            data: ['暂无数据']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: [],
                            type: 'line',
                        }]
                    },true)
                    myChart3.setOption({
                        xAxis: {
                            type: 'category',
                            data: ['暂无数据']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: [],
                            type: 'line',
                        }]
                    },true)
                    myChart4.setOption({
                        xAxis: {
                            type: 'category',
                            data: ['暂无数据']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: [],
                            type: 'line',
                        }]
                    },true)
                    myChart5.setOption({
                        xAxis: {
                            type: 'category',
                            data: ['暂无数据']
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [{
                            data: [],
                            type: 'line',
                        }]
                    },true)
                    this.infoData.xinlv = 0
                    this.infoData.huxi = 0
                    this.infoData.zaichuang = '0小时0分0秒'
                    this.infoData.guohuan = 0
                    this.infoData.zanting = 0
                    this.infoData.caoshi = 0
                    this.$Message.error(res.data.message);
                }
            })
        },
        calendarCahnge(){
        }
    },
    watch: {
        value(val,avl){
            let time = val.getFullYear()+'-'+(val.getMonth()+1)+'-'+val.getDate()
            this.dateTime = time
            this.getchartData()
        }
    }
}
</script>

<style lang="scss">
    #home2 {
        display: flex;
        padding: 0 0 0 20px;
        flex: 1;
        .account-box {
            height: 392px;
        }
        .surveUnusual {
            flex: 1;
            overflow-y: auto;
        }
        .homeLeft {
            // overflow: auto;
            display: flex;
            flex-direction: column;
            width: 350px;
            // background-color: #fff;
            // height: 750px;
            // border: 1px solid #e8e8e8;
            margin: 20px 20px 20px 0;
            border-radius: 10px;
            .ivu-card {
                overflow: auto;
            }
        }
        .survey {
            padding-left: 20px;
        }
        .survey h5 {
            font-size: 18px;
            color: #797979;
            margin-bottom: 20px;
            text-align: left;
        }
        .survey ul {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
        }
        .survey ul li {
            margin-bottom: 20px;
            margin-right: 15px;
        }
        .survey ul span:nth-of-type(1) {
            color: #ababab;
            margin-right: 10px;
        }
        .survey ul span:nth-of-type(2) {
            font-weight: 700;
            color: #333;
        }
        .unusual {
            display: flex;
            flex-direction: column;
            padding: 0 15px;
        }
        .unusual h5 {
            font-size: 16px;
            color: #797979;
            text-align: left;
        }
        .unusual ul {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
        }
        .unusual ul li {
            font-size: 16px;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            height: 40px;
            // background-color: #f8f8f8;
            align-items: center;
            padding: 0 20px 0 13px;
            margin-bottom: 10px;
            border-radius: 10px;
            /* box-shadow: 0px 0px 8px #ccc; */
        }
        .unusual ul li:nth-of-type(1) {
            color: #ffc100;
        }
        .unusual ul li:nth-of-type(2) {
            color: #ff4a43;
        }
        .unusual ul li:nth-of-type(3) {
            color: #3bafe0;
        }
        .homeRight h4 {
            font-size: 24px;
            color: #333;
            padding: 20px 0;
        }
        .homeRight {
            flex: 1;
            overflow-y: auto;
            padding-right: 20px;
            .ivu-card {
                margin-bottom: 20px;
            }
            .echarts {
                padding: 20px 0;
                padding-left: 20px;
            }
        }
        .echarts {
            height: 315px;
            // background-color: #fff;
            // margin-bottom: 20px;
            // padding: 20px;
            // border-radius: 10px;
            // box-shadow: 0px 0px 20px #ecedf4;
        }
        .el-calendar-table .el-calendar-day {
            height: 40px;
        }
        .ivu-menu {
            color: #666;
        }
        .titles {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 700;
            .title {
                margin-left: 5px;
            }
            .img {
                width: 20px;
                height: 20px;
            }
        }
        .ivu-card-body {
            padding: 0;
            height: 100%;
        }
        .textNone {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>