export const api = {
    GetFamilyBedStepState: '/FamilyBed/GetFamilyBedStepState', //获取床位实施进度
    GetFamilyBedDocByCardNo: '/FamilyBed/GetFamilyBedDocByCardNo', //获取家庭床位档案
    GetFamilyBedAnnex: '/FamilyBed/GetFamilyBedAnnex', //获取附件列表
    GetUserFamilyBedDevice: '/Equipment/GetUserFamilyBedDevice', //获取实施记录
    SumServiceCountGroupByType: '/Report/SumServiceCountGroupByType', //服务订单统计
    SumWorkOrderCountGroupByType: '/Report/SumWorkOrderCountGroupByType', //服务工单统计
    SumBuildCapital: '/Report/SumBuildCapital', //设备资金
    SumServiceCapitalGroupByType: '/Report/SumServiceCapitalGroupByType', //服务资金
    GetUserDeviceCheckWorkOrder: '/WorkOrder/GetUserDeviceCheckWorkOrder', //获取设备巡查工单
    GetFamilyBedAlarmWorkOrderPager: '/WorkOrder/GetFamilyBedAlarmWorkOrderPager', //获取设备应用列表
    GetOrderType: '/Order/GetOrderType', //获取服务分类
    SumUserOrderLengthOfTime: '/Order/SumUserOrderLengthOfTime', //总计服务时长
    GetOrderPager: '/Order/GetOrderPager', //分页搜索服务记录
    GetMatressReport: '/MatressReport/GetMatressReport', //获取床垫报告数据
    GetFamilyBedDataReport: '/FamilyBedDataReport/GetFamilyBedDataReport', //获取床位数据报告
    GetByCardNo: '/RoomCheck/GetByCardNo', //获取指定日期查房记录
    SubmitRoomCheck: '/RoomCheck/SubmitRoomCheck', //提交查房记录
    GetDaliyReport: 'MatressReport/GetDaliyReport'
}