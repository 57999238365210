import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        navState:'home',
        token:'',
        userId:'',
        tag:'',
        type:1,
        phone:'',
        vcCardNO:'',
        times:{
            startTime:null,
            endTime:null
        },
        stateIndex:0,
        serveInfo:{  //服务商信息
            name:'',
            addrInfo:'',
            phone:'',
            streetName:''
        },
        totas:{
            subsidyAmou: 0, //补贴金额
            totaAmou: 0 //总改造费用
        },
        baseInfo:{
            childs:[],
            faci:{},
            familyBed:{},
            spouse:{},
            stageType:0,
            userInfo:{}
        },
        stageType:0,
        setinter:'',
        videoState:1
    },
    
    mutations: {
        setVideoState(state,val){
            state.videoState = val
        },
        setSetinter (state,val) {
            state.setinter = val
        },
        setNavState (state,val) {
            state.navState = val
        },
        setToken (state,val){
            state.token = val
        },
        setUserId (state,val){
            state.userId = val
        },
        setType (state,val){
            state.type = val
        },
        setPhone (state,val){
            state.phone = val
        },
        setvcCardNO(state,val) {
            state.vcCardNO = val
        },
        setTag(state,val) {
            state.tag = val
        },
        setTimes(state,val){
            state.times = val
        },
        setState(state,val){
            state.stateIndex = val
        },
        setServeInfo(state,val) {
            state.serveInfo = val
        },
        setTotas(state,val) {
            state.totas = val
        },
        setBaseInfo(state,val){
            state.baseInfo = val
        },
        setStageType(state,val){
            state.stageType = val
        }
    },
    actions: {

    },
    modules: {

    }
})