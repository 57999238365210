<template>
    <div id="serveAdmin">
        <div class="view-left">
            <div class="top-view">
                <div class="title-view top-view-pos">
                    <span class="title-text">设备资金</span>
                    <div class="tabs-time">
                        <div style="width:120px">
                            <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" @change="tabsChange">
                                <el-radio-button label="year">年</el-radio-button>
                                <el-radio-button label="month">月</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div style="width:145px">
                            <el-date-picker
                                v-model="timeValue"
                                :type="tabPosition"
                                value-format="yyyy-MM"
                                :placeholder="tabPosition==='year'?'请选择年份':'请选择月份'"
                                @change="timeChange"
                                :picker-options="pickerOptions"
                            >
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <!-- <div class="tabs-view">
                    <ul class="tab-ul">
                        <li v-for="item in tabList" :key="item.value" class="tab-item" :class="item.value===tabActive.value?'tab-item-active':''" @click="tabClick(item)">{{item.label}}</li>
                    </ul>
                </div> -->
                <div class="top-list">
                    <div class="top-item">
                        <div class="item-texts">
                            <span class="title1">资金总额</span>
                            <span class="title2">{{'￥'+setContAmou+'.00'}}</span>
                        </div>
                        <i-circle :percent="100" :size="84" trail-color="#ffffff">
                            <span class="demo-Circle-inner" style="font-size:18px">100%</span>
                        </i-circle>
                    </div>
                    <div class="top-item top-itemto">
                        <div class="item-texts">
                            <span class="title1">政府购买</span>
                            <span class="title2">{{'￥'+goverAmou+'.00'}}</span>
                        </div>
                        <i-circle :percent="setTotaAmou" :size="84" stroke-color="#fff" trail-color="#2176FF">
                            <span class="demo-Circle-inner" style="font-size:18px;color:#fff">{{setTotaAmou+'%'}}</span>
                        </i-circle>
                    </div>
                    <div class="top-item">
                        <div class="item-texts">
                            <span class="title1">市场自费</span>
                            <span class="title2">{{'￥'+expenseAmou+'.00'}}</span>
                        </div>
                        <i-circle :percent="setTotaAmou==0?0:100-setTotaAmou" :size="84" trail-color="#ffffff">
                            <span class="demo-Circle-inner" style="font-size:18px">{{(setTotaAmou==0?0:100-setTotaAmou)+'%'}}</span>
                        </i-circle>
                    </div>
                    
                </div>
                <div class="title-view" style="marginTop:20px;">
                    <span class="title-text">服务资金</span>
                </div>
                <div class="echart-view">
                    <div class="left-view">
                        <div style="width:100%;height:100%" ref="chartLeft"></div>
                    </div>
                    <div class="right-view">
                        <div style="width:100%;height:100%" ref="chartRight"></div>
                    </div>
                </div>
            </div>
            <div class="title-view">
                <span class="title-text">服务统计</span>
            </div>
            <div class="tabs-view">
                <ul class="tab-ul">
                    <li style="width:260px;" v-for="item in tabList2" :key="item.value" class="tab-item" :class="item.value===tabActive2.value?'tab-item-active2':''" @click="tabClick2(item)">{{item.label}}</li>
                </ul>
                <div class="account-box">
                    <div class="info-item" style="marginRight:30px">
                        <div class="item-icons">
                            <img class="item-img" src="@/assets/image/server1.png" alt="">
                            <span class="item-label">服务订单统计总量</span>
                        </div>
                        <p class="item-content">{{serviceCount}}</p>
                    </div>
                    <div class="info-item">
                        <div class="item-icons">
                            <img class="item-img" src="@/assets/image/server2.png" alt="">
                            <span class="item-label">服务工单统计总量</span>
                        </div>
                        <p class="item-content">{{orderCount}}</p>
                    </div>
                </div>
            </div>
            <div class="echart-view" style="marginBottom:30px">
                <div class="chart2" ref="chart2"></div>
            </div>
        </div>
        <!-- <div class="view-right">
            <div class="top-info">
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve1.png" alt="">
                        <span class="item-label">服务商名称</span>
                    </div>
                    <p class="item-content">{{baseInfo.faci.name?baseInfo.faci.name:'--'}}</p>
                </div>
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve2.png" alt="">
                        <span class="item-label">联系电话</span>
                    </div>
                    <p class="item-content">{{baseInfo.faci.moblie?baseInfo.faci.moblie:'--'}}</p>
                </div>
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve3.png" alt="">
                        <span class="item-label">地址</span>
                    </div>
                    <p class="item-content">{{baseInfo.faci.addrInfo?baseInfo.faci.addrInfo:'--'}}</p>
                </div>
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve1.png" alt="">
                        <span class="item-label">所属街道</span>
                    </div>
                    <p class="item-content">{{serveInfo.streetName?serveInfo.streetName:'--'}}</p>
                </div>
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve1.png" alt="">
                        <span class="item-label">服务订单统计总量</span>
                    </div>
                    <p class="item-content">{{serviceCount}}</p>
                </div>
                <div class="info-item">
                    <div class="item-icons">
                        <img class="item-img" src="@/assets/image/home/serve1.png" alt="">
                        <span class="item-label">服务工单统计总量</span>
                    </div>
                    <p class="item-content">{{orderCount}}</p>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    var echarts = require('echarts/lib/echarts');
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    export default {
        computed:{
            ...mapState(['vcCardNO','serveInfo','totas','baseInfo']),
            placeholder(){
                return this.tabPosition==='year'
            },
            setTotaAmou(){
                if(this.capitalAmou.buildAmount!=0) {
                    return parseInt(this.capitalAmou.buildSubsidyAmount/this.capitalAmou.buildAmount*100)
                }else {
                    return 0
                }
            },
            setContAmou(){
                return this.capitalAmou.buildAmount
            },
            expenseAmou(){
                if(this.capitalAmou.buildAmount!=0) {
                    return this.capitalAmou.buildAmount - this.capitalAmou.buildSubsidyAmount
                }else {
                    return 0
                }
            },
            goverAmou(){
                return this.capitalAmou.buildSubsidyAmount
            }
        },   
        data() {
            return {
                        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
          },
                value1: new Date(2016, 9, 10, 18, 40),
                url:'',
                tabList:[
                    {
                        label:'设备资金',
                        value:1
                    },
                    {
                        label:'服务资金',
                        value:2
                    }
                ],
                tabList2:[
                    {
                        label:'服务订单统计',
                        value:1
                    },
                    {
                        label:'服务工单统计',
                        value:2
                    }
                ],
                tabActive:{
                    label:'设备资金',
                    value:1
                },
                tabActive2:{
                    label:'服务订单统计',
                    value:1
                },
                value:'',
                dateTime:this.setTime(),
                serviceData:{
                    xData:[],
                    yData:[]
                },
                workData:{
                    xData:[],
                    yData:[]
                },
                capitalAmou:{
                    "buildAmount": 0,  /*建设总金额*/
                    "buildSubsidyAmount": 0  /*建设补贴金额*/
                },
                serverAmou:{
                    "totalAmount": 0,
                    "subsidyAmount": 0,
                    data:[]
                },
                orderCount:0,
                serviceCount:0,
                tabPosition:'year',
                timeValue:'',
            }
        },
        mounted(){
            this.getChartBar()
        },
        methods:{
            timeChange(e){
                console.log(this.timeValue)
                this.getChartBar()
            },
            tabsChange(e){
                this.getChartBar()
            },
            tabClick(item){
                this.tabActive = item
            },
            tabClick2(item){
                this.tabActive2 = item
                this.initEchart()
            },
            setTime(){
                let time = new Date()
                let year = time.getFullYear()
                let month = time.getMonth()+1
                return {year,month}
            },
            getChartBar(){
                let year = '',month = ''
                if(this.timeValue&&this.timeValue!==null) {
                    year = this.timeValue.split('-')[0]
                    month = this.tabPosition==='month'?this.timeValue.split('-')[1]:''
                }
                console.log(this.timeValue)
                axios.get(this.$api.SumServiceCountGroupByType + `?cardNo=${this.vcCardNO}&year=${year}&month=${month}`).then(res=>{
                    let xData = [],yData = []
                    res.data.result.data.forEach(item=>{
                        xData.push(item.typeName)
                        yData.push(item.count)
                    })
                    this.serviceData = {
                        xData,
                        yData
                    }
                    this.serviceCount = res.data.result.totalCount
                }).then(success=>{
                    axios.get(this.$api.SumWorkOrderCountGroupByType + `?cardNo=${this.vcCardNO}&year=${year}&month=${month}`).then(res=>{
                        let xData = [],yData = []
                        res.data.result.data.forEach(item=>{
                            xData.push(item.typeName)
                            yData.push(item.count)
                        })
                        this.workData = {
                            xData,
                            yData
                        }
                        this.orderCount = res.data.result.totalCount
                        this.initEchart()
                    })
                })
                axios.get(this.$api.SumBuildCapital + `?cardNo=${this.vcCardNO}&year=${year}&month=${month}`).then(res=>{
                    this.capitalAmou = res.data.result
                })
                axios.get(this.$api.SumServiceCapitalGroupByType + `?cardNo=${this.vcCardNO}&year=${year}&month=${month}`).then(res=>{
                    console.log(res)
                    this.serverAmou = res.data.result

                    this.initEchart1()
                })
            },
            initEchart(){
                const state = this.tabActive2.value===1?'serviceData':'workData'
                var myChart2 = echarts.init(this.$refs.chart2);
                myChart2.setOption({},true)
                myChart2.setOption({
                    tooltip: {
                        trigger: 'axis',
                        formatter:(params)=>{
                            if(this.tabActive2.value===1) {
                                return `${params[0].name}: ${params[0].data} (${(params[0].data/this.serviceCount*100).toFixed(2)}%)`
                            }else {
                                return `${params[0].name}: ${params[0].data}`
                            }    
                        },
                        axisPointer: {
                            type: 'shadow',
                            textStyle: {
                                color: '#fff',
                            },
                        },
                    },
                    grid: {
                        borderWidth: 0,
                        y: '10%',
                        y2: '10%',
                        x:'5%',
                        x2:'5%'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            // show: false,
                            data: this[state].xData,
                            axisTick:{
                                show:false
                            },
                            axisLine:{
                                show:false
                            },
                            axisLabel:{
                                color:'#99aaba'
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            show: false
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            barMaxWidth:70,
                            itemStyle: {
                                normal: {
                                    color:'#2d7dff',
                                    label: {
                                        show: true,
                                        position: 'top',
                                        formatter: '{c}'
                                    }
                                }
                            },
                            data: this[state].yData,
                        }
                    ]
                });
            },
            initEchart1(){
                var myChartLeft = echarts.init(this.$refs.chartLeft);
                myChartLeft.setOption({},true)
                myChartLeft.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    series: [
                        {
                        type: 'pie',
                        radius: ['45%', '60%'],
                        center: ['50%','55%'],
                        avoidLabelOverlap: false,
                        label: {
                            formatter: '{c}.00元\n{d}%'
                        },
                        data: [
                            { 
                                value: this.serverAmou.subsidyAmount, 
                                name: '政府购买',
                                label:{color:'#2176FF'},
                                itemStyle:{color:'#2176FF'}
                            },
                            { 
                                value: this.serverAmou.totalAmount-this.serverAmou.subsidyAmount, 
                                name: '市场自费',
                                label:{color:'#00E5FF'},
                                itemStyle:{color:'#00E5FF'}
                            }
                        ]},
                        {
                        type: 'pie',
                        radius: ['0', '35%'],
                        center: ['50%','55%'],
                        avoidLabelOverlap: false,
                        label: {
                            formatter: '{c}.00元\n{d}%',
                            position: 'center',
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { 
                                value: this.serverAmou.totalAmount, 
                                name: '资金总额',
                                label:{color:'#fff'},
                                itemStyle:{color:'#5B6E96'}
                            }
                        ]}
                    ]
                });
                var myChartRight = echarts.init(this.$refs.chartRight);
                let xData = [],yData = []
                this.serverAmou.data.forEach(item=>{
                    xData.push(item.typeName)
                    yData.push(item.tradAmou)
                })
                myChartRight.setOption({},true)
                myChartRight.setOption({
                    tooltip: {
                        trigger: 'axis',
                        formatter:(params)=>{
                            return `${params[0].name}: ${params[0].data}`
                        },
                        axisPointer: {
                            type: 'shadow',
                            textStyle: {
                                color: '#fff',
                            },
                        },
                    },
                    grid: {
                        borderWidth: 0,
                        y: '15%',
                        y2: '25%',
                        x:'5%',
                        x2:'5%'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            // show: false,
                            data: xData,
                            axisTick:{
                                show:false
                            },
                            axisLine:{
                                show:false
                            },
                            axisLabel:{
                                color:'#99aaba',
                                interval:0,  
                                rotate:40 
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            show: false
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color:'#2d7dff',
                                    label: {
                                        show: true,
                                        position: 'top',
                                        formatter: '{c}'
                                    }
                                }
                            },
                            data: yData,
                        }
                    ]
                })
            }
        },
        watch: {
            value(val,avl){
                this.dateTime = {
                    year:val.getFullYear(),
                    month:val.getMonth()+1
                }
                this.getChartBar()
            }
        }
    }
</script>

<style lang="scss">
    #serveAdmin{
        padding: 20px 20px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .view-left {
            flex: 1;
        }
        .tabs-time {
            display: flex;
            align-items: center;
            .el-radio-group {
                margin-bottom: 0 !important;
            }
        }
        .top-view-pos {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-date-editor.el-input {
                width: 100%;
            }
        }
        .view-right {
            width: 270px;
            margin-left: 30px;
            .top-info {
                height: 340px;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                flex-shrink: 0;
                padding: 20px;
                color: #fff;
                margin-bottom: 26px;
            }
        }
        .account-box {
            // width: 400px;
            background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);          
            border-radius: 5px;
            box-shadow: 0px 5px 20px #C0C7D6;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
            color: #fff;
            .info-item {
                display: flex;
                align-items: center;
                .item-icons {
                    display: flex;
                    align-items: center;
                    .item-img {
                        width: 11px;
                        height: 11px;
                        margin-right: 4px;
                    }
                }
                .item-content {
                    text-align: left;
                    padding-left: 15px;
                    font-size: 35px;
                    color: #fff;
                    font-weight: 700;
                }
            }
        }
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 30px;
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .tabs-view {
            display: flex;
            margin-bottom: 20px;
            .tab-ul {
                width: 310px;
                display: flex;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0px 1px 4px #E5E9F2;
                height: 60px;
                align-items: center;
                .tab-item {
                    width: 50%;
                    height: 100%;
                    line-height: 60px;
                    font-size: 18px;
                    color: #333;
                    position: relative;
                    cursor: pointer;
                }
                .tab-item-active{
                    height: 100px;
                    background: url('../assets/image/home/tabbg.png') no-repeat;
                    background-size: 70% 80%;
                    background-position: 40% 15px;
                    line-height: 100px;
                    color: #fff;
                }
                .tab-item-active2 {
                    height: 100px;
                    background: url('../assets/image/home/tabbg.png') no-repeat;
                    background-size: 80% 80%;
                    background-position: 40% 15px;
                    line-height: 100px;
                    color: #fff;
                }
                .tab-item::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '';
                    width: 1px;
                    height: 31px;
                    background-color: #DBDBDB;
                }
                .tab-item:last-child::after{
                    content: normal;
                }
            }
        }
        .top-view {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            z-index: 0;
            margin-bottom: 30px;
            .top-list {
                display: flex;
                flex: 1;
                width: 100%;
                justify-content: space-between;
                .top-item {
                    width: 30%;
                    height: 132px;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    box-shadow: 0px 10px 40px #E5E9F2;
                    border-radius: 5px;
                    justify-content: space-between;
                    padding: 0 30px;
                    .item-texts {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .title1 {
                            font-size: 15px;
                            color: #666;
                            margin-bottom: 9px;
                        }
                        .title2 {
                            font-size: 30px;
                            color: #31394D;
                            font-weight: 700;
                        }
                    }
                }
                .top-item:last-child {
                    margin-right: 0;
                }
                .top-itemto {
                    background-color: #2176FF;
                    box-shadow: 0px 10px 40px #E5E9F2;
                    justify-content: space-between;
                    padding: 0 30px;
                    .item-texts {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .title1 {
                            font-size: 15px;
                            color: #fff;
                            margin-bottom: 9px;
                        }
                        .title2 {
                            font-size: 30px;
                            color: #fff;
                            font-weight: 700;
                        }
                    }
                }
                @media screen and (max-width: 1680px) {
                    .top-item{
                        width: 32%;
                        padding: 0 20px;
                        .item-texts {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .title1 {
                                font-size: 15px;
                                color: #666;
                                margin-bottom: 9px;
                            }
                            .title2 {
                                font-size: 20px;
                                color: #31394D;
                                font-weight: 700;
                            }
                        }
                    }
                    .top-itemto {
                        padding: 0 20px;
                        .item-texts {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .title1 {
                                font-size: 15px;
                                color: #fff;
                                margin-bottom: 9px;
                            }
                            .title2 {
                                font-size: 20px;
                                color: #fff;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .echart-view {
            height: 290px;
            width: 100%;
            background-color: #fff;
            display: flex;
            .chart2 {
                height: 100%;
                width: 100%;
            }
            .left-view {
                width: 40%;
            }
            .right-view {
                width: 60%;
            }
        }
        .el-calendar-table .el-calendar-day{
            height: 38px;
            line-height: 34px;
            padding: 0;
            span {
                display: inline-block;
                width: 28px;
                height: 35px;
                background-color: #E5E9F2;
                border-radius: 5px;
                color: #91a3b6;
            }
        }
        .el-calendar-table td {
            border: 0;
        }
        .el-calendar-table .el-calendar-day:hover {
            span {
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar-table .is-today{
            span{
                background-color: #fff;
                border: 1px solid #2176FF;
                color: #7b90a6;
            }
        }
        .el-calendar-table .is-selected {
            span{
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar__body {
            padding: 0 15px 10px;
        }
        .el-calendar__header {
            display: flex;
            flex-direction: column;
            border-bottom: 0;
            padding: 12px 20px 0;
        }
        .el-calendar-table .prev,
        .el-calendar-table .next {
            span {
                background-color: #F4F7FB;
            }
        }
    }
</style>