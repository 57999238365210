<template>
    <div id="fileSee">
        <div class="abnormal">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/qingdan.png" alt="">
                    <span class="title">投放清单</span>
                </div>
                <div class="abnormal_center" v-if="srcList3.length>0">
                    <div class="item" v-for="item in srcList3" :key="item.id">
                        <el-image 
                            style="width: 100px; height: 100px"
                            :src="item.url" 
                            :preview-src-list="urlList3">
                        </el-image>
                        <span class="item-name">{{item.name}}</span>
                    </div>
                </div>
                <div class="noneTile" v-else>暂无报告附件</div>
            </Card>            
        </div>
        <div class="abnormal">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/yanshou.png" alt="">
                    <span class="title">验收单</span>
                </div>
                <div class="abnormal_center" v-if="srcList5.length>0">
                    <div class="item" v-for="item in srcList5" :key="item.id">
                        <el-image 
                            style="width: 100px; height: 100px"
                            :src="item.url" 
                            :preview-src-list="urlList5">
                        </el-image>
                        <span class="item-name">{{item.name}}</span>
                    </div>
                </div>
                <div class="noneTile" v-else>暂无报告附件</div>
            </Card> 
        </div>
    </div>
</template>

<script>
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    export default {
        computed:{...mapState(['vcCardNO'])},
        data () {
            return {
                url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                srcList: [
                    'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                    'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
                ],
                srcList3:[],
                urlList3:[],
                srcList5:[],
                urlList5:[],
            }
        },
        mounted () {
            axios.get('FamilyBed/GetFamilyBedAnnex?cardNo='+this.vcCardNO).then(res=>{
                if(res.data.code===200) {
                    this.srcList3 = res.data.result.type3
                    this.srcList5 = res.data.result.type5
                    res.data.result.type3.forEach(item=>{
                        this.urlList3.push(item.url)
                    })
                    res.data.result.type5.forEach(item=>{
                        this.urlList5.push(item.url)
                    })
                }
            })
        }
    }
</script>

<style lang="scss">
    #fileSee{
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        .abnormal {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 0px 20px #ecedf4;
            margin: 20px 20px 0;
            .abnormal_title {
                display: flex;
                align-items: center;
                font-size: 20px;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #e6e6e6;
                .img {
                    margin:0 8px 0 24px;
                    width: 24px;
                    height: 24px;
                }
            }
            .abnormal_center {
                padding: 30px;
                display: flex;
                flex-wrap: wrap;
                .item {
                    display: flex;
                    flex-direction: column;
                    margin-right: 50px;
                    .item-name {
                        margin-top: 10px;
                    }
                }
            }
            .noneTile {
                height: 150px;
                line-height: 150px;
                color: #666;
            }
        }
        .titles {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 700;
            .title {
                margin-left: 5px;
            }
            .img {
                width: 20px;
                height: 20px;
            }
        }
        .ivu-card-body {
            padding: 0;
        }
    }
</style>