var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"equipment"}},[_c('div',{staticClass:"center-left"},[_c('div',{staticClass:"center-top"},[_c('div',{staticClass:"right-abnormal"},[_vm._m(0),_c('div',{staticClass:"right-list"},_vm._l((_vm.equipmentList),function(item){return _c('div',{key:item.equipmentID,staticClass:"eq-item"},[_c('div',{staticClass:"icon-view",class:item.isSetup?'':'icon-view-none'},[_c('span',{staticClass:"iconfont",class:_vm.setIcon(item.equipmentID)})]),_c('span',{staticStyle:{"height":"32px"}},[_vm._v(_vm._s(item.equipmentName))])])}),0)])]),_c('div',{staticClass:"oder-view"},[_c('div',{staticClass:"oder-center"},[_vm._m(1),_c('div',{staticClass:"tab-view"},[_c('Table',{attrs:{"border":"","columns":_vm.columns12,"data":_vm.data6},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.name))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.isSetup==1)?_c('span',[_vm._v("已安装")]):_c('div',[_vm._v("未安装")])]}},{key:"img",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Poptip',{attrs:{"placement":"left","width":"200"},on:{"on-popper-show":function($event){return _vm.onPopperShow(index)},"on-popper-hide":function($event){return _vm.onPopperHide(index)}}},[(row.imgShow)?_c('img',{staticClass:"btnImg",attrs:{"src":require("@/assets/image/show.png")}}):_c('img',{staticClass:"btnImg",attrs:{"src":require("@/assets/image/hide.png"),"alt":""}}),_c('div',{staticClass:"img-wrap",attrs:{"slot":"content"},slot:"content"},[(row.installUrl)?_c('img',{staticClass:"img-item",attrs:{"src":row.installUrl,"alt":""}}):_c('div',[_vm._v("暂无图片")])])])]}}])})],1)])]),_c('div',{staticClass:"oder-view",staticStyle:{"paddingTop":"30px"}},[_c('div',{staticClass:"oder-center"},[_vm._m(2),_c('div',{staticClass:"tab-view"},[_c('Table',{attrs:{"border":"","columns":_vm.columns1,"data":_vm.data1}})],1)])]),_c('div',{staticClass:"oder-view",staticStyle:{"paddingTop":"30px"}},[_c('div',{staticClass:"oder-center"},[_vm._m(3),_c('div',{staticClass:"tab-view"},[_c('Table',{attrs:{"border":"","columns":_vm.columns2,"data":_vm.data2}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-view"},[_c('span',{staticClass:"title-text"},[_vm._v("我的设备")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-view"},[_c('span',{staticClass:"title-text"},[_vm._v("设备列表")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-view"},[_c('span',{staticClass:"title-text"},[_vm._v("设备巡检")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-view"},[_c('span',{staticClass:"title-text"},[_vm._v("设备应用")])])}]

export { render, staticRenderFns }