<template>
    <div id="userArchives">
        <header class="header">
            <UserTab v-if="stateShow" :state="state" @change="tabChange" :active="active"></UserTab>
        </header>
        <div class="center">
            <UserInfo v-show="active===1" :srcList="srcList4" :urlList="urlList4"></UserInfo>
            <ContractView v-if="active===4"></ContractView>
            <ImgsView v-if="active===2" :srcList="srcList1" :num="1" label="服务合同"></ImgsView>
            <ImgsView v-if="active===3" :srcList="srcList2" :num="1" label="改造方案"></ImgsView>
            <ImgsView v-if="active===5" :srcList="srcList3" :srcListTo="srcList5" :num="2" :labels="['投放清单','验收单']"></ImgsView>
        </div>
        <footer class="footer">
            <div class="footer-view">
                <div class="footer-title">
                    <div class="titles">
                        <span class="iconfont icon-a-zu839"></span>
                        <span>共计天数</span>
                    </div>
                    <span class="title-num">{{getNum}}</span>
                    <div class="times">
                        <div class="state-time">
                            <span>开始时间：</span>
                            <span>{{times.startTime===null?'--':times.startTime.split(' ')[0]}}</span>
                        </div>
                        <div class="end-time">
                            <span>结束时间：</span>
                            <span>{{times.startTime===null?'--':times.endTime.split(' ')[0]}}</span>
                        </div>
                    </div>
                </div>
                <div class="footer-items">
                    <div class="item">
                        <img class="item-img" :src="setTabIcon1" alt="">
                        <span class="item-text">资格申请</span>
                    </div>
                    <span class="iconfont icon-a-zu860" :class="this.stageType<1?'colors':''"></span>
                     <div class="item">
                        <img class="item-img" :src="setTabIcon2" alt="">
                        <span class="item-text">设备评估</span>
                    </div>
                    <span class="iconfont icon-a-zu860" :class="this.stageType<2?'colors':''"></span>
                     <div class="item">
                        <img class="item-img" :src="setTabIcon3" alt="">
                        <span class="item-text">项目实施</span>
                    </div>
                    <span class="iconfont icon-a-zu860" :class="this.stageType<3?'colors':''"></span>
                     <div class="item">
                        <img class="item-img" :src="setTabIcon4" alt="">
                        <span class="item-text">补贴发放</span>
                    </div>
                    <span class="iconfont icon-a-zu860" :class="stageType<4?'colors':''"></span>
                     <div class="item">
                        <img class="item-img" :src="setTabIcon5" alt="">
                        <span class="item-text">流程完结</span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    import UserInfo from '@/components/userSpeed/user-info'
    import UserTab from '@/components/user-tab'
    import ContractView from '@/components/userSpeed/contract-view'
    import ImgsView from '@/components/userSpeed/imgs-view'
    export default {
        components:{
            UserInfo,
            UserTab,
            ContractView,
            ImgsView
        },
        computed:{
            ...mapState(['vcCardNO','times','baseInfo','stageType']),
            getNum(){
                if(this.times.startTime!==null) {
                    let start = new Date(this.times.startTime).getTime()
                    let end = new Date(this.times.endTime).getTime()
                    let numday = (end-start)/(1000*60*60*24)
                    return Math.round(numday*10)/10
                }else {
                    return 0
                }
            },
            setTabIcon1(){
                let icon = ''
                if(this.stageType>=1&&this.stageType!==6) {
                    icon = require('@/assets/image/buzou1-1.png')
                }else {
                    icon = require('@/assets/image/buzou1-2.png')
                }
                return icon
            },
            setTabIcon2(){
                let icon = ''
                if(this.stageType>=2&&this.stageType!==6) {
                    icon = require('@/assets/image/buzou2-1.png')
                }else {
                    icon = require('@/assets/image/buzou2-2.png')
                }
                return icon
            },
            setTabIcon3(){
                let icon = ''
                if(this.stageType>=3&&this.stageType!==6) {
                    icon = require('@/assets/image/buzou3-1.png')
                }else {
                    icon = require('@/assets/image/buzou3-2.png')
                }
                return icon
            },
            setTabIcon4(){
                let icon = ''
                if(this.stageType>=4&&this.stageType!==6) {
                    icon = require('@/assets/image/buzou4-1.png')
                }else {
                    icon = require('@/assets/image/buzou4-2.png')
                }
                return icon
            },
            setTabIcon5(){
                let icon = ''
                if(this.stageType===5){
                    icon = require('@/assets/image/buzou5-1.png')
                }else if(this.stageType===6){
                    icon = require('@/assets/image/buzou6.png')
                }else {
                    icon = require('@/assets/image/buzou5-2.png')
                }
                return icon
            }
        },
        data () {
            return {
                srcList4:[],
                urlList4:[],
                pilotType:null,
                active:1,
                srcList1:[],
                srcList2:[],
                srcList3:[],
                srcList5:[],
                state:{
                    step1: 0,
                    step2: 0,
                    step3: 0,
                    step4: 0,
                    step5: 0
                },
                stateShow:false,
            }
        },
        created () {
            // if(this.baseInfo.faci!==null) {
            //     let {addrInfo,name,streetName,phone} = this.baseInfo.faci
            //     this.$store.commit('setServeInfo',{
            //         name:name===null?'--':name,
            //         addrInfo:addrInfo===null?'--':addrInfo,
            //         phone:phone===null?'--':phone,
            //         streetName:streetName===null?'--':streetName
            //     })
            // }
            let {subsidyAmou,totaAmou} = this.baseInfo.familyBed
            // this.userInfo = this.baseInfo.userInfo
            // this.familyBed.annualIncome = this.baseInfo.familyBed.annualIncome
            // this.familyBed.contact = this.baseInfo.familyBed.contact
            // this.familyBed.dsabilityScore = this.baseInfo.familyBed.dsabilityScore
            // this.familyBed.moblie = this.baseInfo.familyBed.moblie
            // this.childs = this.baseInfo.childs
            // this.spouse = this.baseInfo.spouse===null||this.baseInfo.spouse===''?this.spouse:this.baseInfo.spouse
            // this.pilotType = this.baseInfo.familyBed.pilotType
            // if(this.baseInfo.familyBed.state===2||this.baseInfo.familyBed.state===3) {
            //     this.$store.commit('setState',1)
            // }else if(this.baseInfo.familyBed.state===4||this.baseInfo.familyBed.state===5) {
            //     this.$store.commit('setState',2)
            // }else if(this.baseInfo.familyBed.state===7||this.baseInfo.familyBed.state===6||this.baseInfo.familyBed.state===8){
            //     this.$store.commit('setState',3)
            // }else {
            //     this.$store.commit('setState',0)
            // }

            this.$store.commit('setTotas',{
                subsidyAmou: subsidyAmou===null?0:subsidyAmou, //补贴金额
                totaAmou: totaAmou===null?0:totaAmou //总改造费用
            })
            axios.get(this.$api.GetFamilyBedStepState + `?cardNo=${this.vcCardNO}`).then(res=>{
                this.state.step1 = Number(res.data.result.step1&&res.data.result.step1[1])
                this.state.step2 = Number(res.data.result.step2&&res.data.result.step2[1])
                this.state.step3 = Number(res.data.result.step3&&res.data.result.step3[1])
                this.state.step4 = Number(res.data.result.step4&&res.data.result.step4[1])
                this.state.step5 = Number(res.data.result.step5&&res.data.result.step5[1])
                this.stateShow = true
            })
            axios.get(this.$api.GetFamilyBedAnnex + `?cardNo=${this.vcCardNO}`).then(res=>{
                this.srcList1 = res.data.result.type1
                this.srcList2 = res.data.result.type2
                this.srcList3 = res.data.result.type3
                this.srcList5 = res.data.result.type5
                this.srcList4 = res.data.result.type4
                res.data.result.type4.forEach(item=>{
                    this.urlList4.push(item.url)
                })
            })
        },
        mounted () {
       
        },
        methods: {
            setCanvas(canvas){
                let ctx = canvas.getContext('2d');
                let radians = (Math.PI / 180) * 180;
                let startTime = Date.now();
                let time = 1000;
                let clockwise = 2;
                let cp1x, cp1y, cp2x, cp2y;
                // 初始状态
                // ctx.bezierCurveTo(90, 28, 92, 179, 200, 100);
                // 末尾状态
                // ctx.bezierCurveTo(145, 100, 41, 100, 200, 100);
                requestAnimationFrame(function waveDraw() {  
                    let t = Math.min(1.0, (Date.now() - startTime) / time);
                    if(clockwise===1) {
                        cp1x = 30 + (11 * t);
                        cp1y = 30 + (15 * t);
                        cp2x = 30 - (5 * t);
                        cp2y = 30 - (9 * t);
                    }else if(clockwise===2) {
                        cp1x = 41  - (11 * t);
                        cp1y = 45  - (15 * t);
                        cp2x = 25 + (5 * t);
                        cp2y = 21  + (9 * t);
                    }else if(clockwise===3) {
                        cp1x = 30 - (10 * t);
                        cp1y = 30 - (15 * t);
                        cp2x = 30 + (3 * t);
                        cp2y = 30 + (10 * t);
                    }else{
                        cp1x = 20  + (10 * t);
                        cp1y = 15  + (15 * t);
                        cp2x = 33 - (3 * t);
                        cp2y = 40  - (10 * t);
                    }
                    ctx.clearRect(0, 0, 200, 200);  // 	起始一条路径，或重置当前路径
                    ctx.beginPath(); //	起始一条路径，或重置当前路径
                    ctx.moveTo(0, 30); //开始坐标
                    // 绘制三次贝塞尔曲线
                    ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, 90, 30);
                    // 绘制圆弧
                    ctx.arc(100, 200, 200, 0, radians, 0);
                    ctx.fillStyle = "#2176FF";
                    ctx.fill();
                    ctx.save();  
                    
                    if( t == 1 ) {
                        startTime = Date.now();
                        clockwise = clockwise +1
                        if(clockwise > 4){
                            clockwise = 1
                        }
                    } 
                    requestAnimationFrame(waveDraw);
                });
            },
            tabChange(index){
                this.active = index
            }
        }
    }
</script>

<style lang="scss">
    #userArchives{
        width: 100%;
        // min-height: 700px;
        padding: 0 150px 20px 30px;
        display: flex;
        flex-direction: column;
        position: relative;
                    overflow-y: auto;
        // .footer-view {
        //     min-height: 100%;
        // }
        .center {
            flex: 1;
            overflow: hidden;
            display: flex;
        }
        .header{
            margin-top: 20px;
            flex-shrink: 1;
        }
        .contractt-bottom {
            // .ivu-table-wrapper{
            //     height: 100% !important;
            // }
            .ivu-table-header thead tr th {
                padding: 14px 0 !important;
            }
            // .ivu-table {
            //     .ivu-table-header {
            //         height: 50px;
            //         overflow: visible;
            //     }
            // }
        }
        .footer {
            position: absolute;
            right: 0;
            min-height: 700px;
        }
    }
</style>