<template>
    <div class="contractt">
        <div class="contractt-bottom">
            <div class="title-view">
                <span class="title-text">实施记录</span>
                <!-- <div class="states">安装人:湖北安</div> -->
            </div>
            <div class="bottom-center">
                <div class="center-item" v-for="item in data1" :key="item.equipmentID">
                    <div class="item-img"><img class="img" :src="item.equipmentUrl" alt=""></div>
                    <div class="item-content">
                        <div class="info-view">
                            <span>安装前</span>
                            <!-- <span>{{item.equipmentNo}}</span> -->
                        </div>
                        <div class="info-img">
                            <el-image 
                                style="width: 100%; height: 100%;"
                                :src="item.installingUrl" 
                                :preview-src-list="[item.installingUrl]">
                                <span slot="error" class="el-image__error">暂无图片</span>
                            </el-image>
                        </div>
                        <!-- <img class="info-img" :src="item.installingUrl" alt=""> -->
                    </div>
                    <div class="item-content">
                        <div class="info-view">
                            <span>安装中</span>
                            <!-- <span>2020-01-01 11:00:00</span> -->
                        </div>
                        <div class="info-img">
                            <el-image 
                                style="width: 100%; height: 100%;"
                                :src="item.installBeforeUrl" 
                                :preview-src-list="[item.installBeforeUrl]">
                                <span slot="error" class="el-image__error">暂无图片</span>
                            </el-image>
                        </div>
                        <!-- <img class="info-img" :src="item.installBeforeUrl" alt=""> -->
                    </div>
                    <div class="item-content">
                        <div class="info-view">
                            <span>安装后</span>
                            <!-- <span>2020-01-01 11:00:00</span> -->
                        </div>
                        <div class="info-img">
                            <el-image 
                                style="width: 100%; height: 100%;"
                                :src="item.installUrl" 
                                :preview-src-list="[item.installUrl]">
                                <span slot="error" class="el-image__error">暂无图片</span>
                            </el-image>
                        </div>
                            
                        <!-- <img  :src="item.installUrl" alt=""> -->
                    </div>
                    <div class="item-title yes">
                        {{item.equipmentName}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="contractt-top">
            <div class="title-view">
                <span class="title-text">实施记录</span>
            </div>
            <div class="top-center">
                <div class="top-item" v-for="(item,index) in dataList" :key="index">
                    <img class="item-img" :src="item.signInUrl" alt="">
                    <div class="item-info">
                        <span class="title1">{{item.createUserName}}</span>
                        <span class="title2">{{item.createTime}}</span>
                        <span class="title3">{{item.remarks}}</span>
                    </div>
                    <div class="item-state">
                        <span class="iconfont icon-a-zu885 stateColor1" v-if="item.state===1"></span>
                        <span class="iconfont icon-a-zu887 stateColor2" v-else-if="item.state===2"></span>
                        <span class="iconfont icon-a-zu885 stateColor3" v-else></span>
                        <span class="state-title stateColor1" v-if="item.state===1">实施开始</span>
                        <span class="state-title stateColor2" v-else-if="item.state===2">实施中</span>
                        <span class="state-title stateColor3" v-else>实施完成</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
import axios from '@/utils/http.js'
import {mapState} from 'vuex'
export default {
    computed:{...mapState(['userId','vcCardNO'])},
    data () {
        return {
            dataList:[],
            columns1: [
                {
                    title: '设备ID',
                    key: 'equipmentNo'
                },
                {
                    title: '设备型号',
                    key: 'equipmentModel'
                },
                {
                    title: '设备名称',
                    key: 'equipmentName'
                },
                {
                    title: '设备状态',
                    slot: 'action',
                    align: 'center'
                },
                {
                    title: '安装图片',
                    slot: 'img',
                    align: 'center'
                }
            ],
            data1: []
        }
    },
    created () {
        // axios.get('AFXFamilyBed/Sign/GetSignInList?familyBedID='+this.userId+'&type=0').then(res=>{
        //     this.dataList = res.data.result.signInList
        // })
        axios.get(this.$api.GetUserFamilyBedDevice + `?cardNo=${this.vcCardNO}`).then(res=>{
            res.data.result.forEach(item=>{
                item.imgShow = false
            })
            let a = res.data.result.filter(item=>{
                return item.isSetup
            })
            let b = res.data.result.filter(item=>{
                return !item.isSetup
            })
            this.data1 = [...a]
        })
    }
}
</script>
<style lang="scss" scoped>
    .contractt {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        // overflow: auto;
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-top: 30px;
            justify-content: space-between;
            padding-right: 20px;
            position: relative;
            .states {
                width: 275px;
                height: 25px;
                background: url('../../assets/image/contra-top.png') no-repeat;
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: -15px;
                font-size: 12px;
                color: #fff;
                line-height: 25px;
                // .state-none {
                //     font-size: 14px;
                //     position: relative;
                //     padding-left: 15px;
                // }
                // .state-yes {
                //     font-size: 14px;
                //     position: relative;
                //     padding-left: 15px;
                //     margin-left: 10px;
                // }
                // .state-none::after {
                //     content: '';
                //     position: absolute;
                //     left: 0;
                //     width: 12px;
                //     height: 12px;
                //     background-color: #FFB000;
                //     border-radius: 50%;
                //     top: 50%;
                //     transform: translateY(-50%);
                // }
                // .state-yes::after {
                //     content: '';
                //     position: absolute;
                //     left: 0;
                //     width: 12px;
                //     height: 12px;
                //     background-color: #35C196;
                //     border-radius: 50%;
                //     top: 50%;
                //     transform: translateY(-50%);
                // }
            }
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .contractt-top {
            min-height: 200px;
            height: 40%;
            display: flex;
            flex-direction: column;
            .top-center {
                margin-top: 15px;
                padding-top: 20px;
                padding-left: 30px;
                display: flex;
                flex: 1;
                flex-wrap: wrap;
                overflow: auto;   
                background-color: #fff; 
                .top-item {
                    width: 19%;
                    height: 94px;
                    border-radius: 5px;
                    box-shadow: 0px 2px 10px #E5E9F2;
                    background-color: #fff;
                    display: flex;
                    position: relative;
                    padding: 10px;
                    margin-right: 1%;
                    margin-bottom: 20px;
                    .item-img {
                        width: 70px;
                        height: 70px;
                        background-color: #ccc;
                        flex-shrink: 0;
                        margin-right: 10px;
                    }
                    .item-state {
                        position: absolute;
                        bottom: 5px;
                        right: 10px;
                        color: #FFB000;
                        .state-title {
                            font-size: 16px;
                            font-weight: 700;
                            margin-left: 5px;
                        }
                        .stateColor1 {
                            color: #FFB000;
                        }
                        .stateColor2 {
                            color: #35C196;
                        }
                        .stateColor3 {
                            color: #2176FF;
                        }
                        // .iconfont {
                        //     color: #FFB000;
                        // }
                    }
                    .item-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        color: #333;
                        .title1 {
                            text-align: left;
                        }
                        .title2,
                        .title3 {
                            font-size: 12px;
                            color: #666;
                        }
                    }
                }
                @media only screen and (max-width: 1650px) {
                    .top-item {
                        width: 24%;
                    }
                }
                @media only screen and (max-width: 1400px) {
                    .top-item {
                        width: 32%;
                    }
                }
            }
        }
        .contractt-bottom {
            height: 100%;
            display: flex;
            flex-direction: column;
            .bottom-center {
                flex: 1;
                // box-shadow: 0px 1px 4px #E5E9F2;
                // background: #FFFFFF;
                box-shadow: 0px 1px 4px #e5e9f2;
                overflow: hidden;
                margin-top: 15px;
                padding-bottom: 15px;
                overflow: auto;
                padding-left: 30px;
                background-color: #fff;
                border-radius: 5px;
                .bottom-table-state {
                    font-size: 13px;
                    color: #333;
                    padding-left: 15px;
                    position: relative;
                }
                .bottom-table-state::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                }
                .state1::after {
                    background-color: #35C196;
                }
                .state2::after {
                    background-color: #F85F3C;
                }
                .center-item {
                    display: flex;
                    margin-top: 30px;
                    position:relative;
                    .item-img {
                        width: 80px;
                        height: 80px;
                        // background-color: #fff;
                        box-shadow: 0px 1px 4px #e5e9f2;
                        margin-right: 50px;
                        border-radius:10px;
                        // padding: 10px;
                        flex-shrink: 0;
                        .img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item-content {
                        width: 30%;
                        height: 80px;
                        box-shadow: 0px 2px 10px #E5E9F2;
                        background-color: #fff;
                        margin-right: 30px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .info-view {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .info-img {
                            width: 70px;
                            height: 70px;
                            // background-color: #ccc;
                            margin-left: 100px;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                    }
                    .item-title {
                        position: absolute;
                        left: 0;
                        top: -20px;
                        height: 20px;
                        padding: 0 20px 0 10px;
                        background: #1F6EEF;
                        background-size: 100% 100%;
                        color: #fff;
                        overflow: hidden;
                    }
                    .item-title::after {
                        content: '';
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        width: 15px;
                        height: 40px;
                        background-color: #fff;
                        transform: rotate(-30deg)
                    }
                    .none {
                        background-color: #FFB000;
                    }
                    .yes {
                        background-color: #1F6EEF;
                    }
                }
            }
        }
    }
</style>    