import Vue from 'vue';
import App from './App.vue';
import store from './store';
import ViewUI from 'view-design';
import router from './router';
import ElementUI from 'element-ui';
import axios from 'axios'
// import VideoPlayer from 'vue-video-player'
import 'element-ui/lib/theme-chalk/index.css';
import 'view-design/dist/styles/iview.css'; //iview插件样式
import '@/assets/css/index.css';  //引入全局自定义样式
// import '@/assets/iconFont/iconfont.css'
import '@/assets/font/iconfont.css'
import echarts from 'echarts'
import audio from 'vue-mobile-audio'
import {api} from '@/utils/api'
// require('video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
import vDebounceThrottle from 'v-debounce-throttle'
Vue.use(vDebounceThrottle)
Vue.use(audio)
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api
Vue.config.productionTip = false
// Vue.use(VideoPlayer)
Vue.use(ViewUI)
Vue.use(ElementUI);
window.$vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
