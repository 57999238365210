<template>
    <div id="implementView">
        <!-- <Card style="width:100%;height:100%">
            <div slot="title" class="titles">
                <img class="img" src="@/assets/image/003.png" alt="">
                <span class="title">设备列表</span>
            </div>
            <div class="table-warp">
                <serve-table :tableData="tableData" type="0" maxHeight="100%"></serve-table>
            </div>
        </Card>  -->
        <div class="lists">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/007.png" alt="">
                    <span class="title">实施记录</span>
                </div>
                <div class="table-warp">
                    <serve-table :tableData="tableData" type="0" maxHeight="100%"></serve-table>
                </div>
            </Card>
        </div>
        <!-- <div class="abnormal">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/fangan.png" alt="">
                    <span class="title">改造方案</span>
                </div>
                <serve-table :tableData="tableData" type="0" maxHeight="100%"></serve-table>
            </Card>
        </div> -->
        <div class="lists">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/008.png" alt="">
                    <span class="title">设备列表</span>
                </div>
                <div class="table-warp">
                    <Table border :columns="columns12" :data="data6" :height="411">
                        <template slot-scope="{ row }" slot="name">
                            <strong>{{ row.name }}</strong>
                        </template>
                        <template slot-scope="{ row }" slot="action">
                            <span v-if="row.isSetup==1">已安装</span>
                            <div v-else>未安装</div>
                        </template>
                        <template slot-scope="{ row,index }" slot="img">
                            <Poptip placement="right" width="200" @on-popper-show="onPopperShow(index)" @on-popper-hide="onPopperHide(index)">
                                <img v-if="row.imgShow" class="btnImg" src="@/assets/image/show.png">
                                <img v-else class="btnImg" src="@/assets/image/hide.png" alt="">
                                <div class="img-wrap" slot="content">
                                    <img class="img-item" v-if="row.installUrl" :src="row.installUrl" alt=""> 
                                    <div v-else>暂无图片</div>
                                </div>
                            </Poptip>
                        </template>
                    </Table>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    import ServeTable from '@/components/serve-table.vue'
    export default {
        computed:{...mapState(['userId','vcCardNO'])},
        components:{
            ServeTable     
        },
        data () {
            return {
                tableData:[],
                data6:[],
                columns12: [
                    {
                        title: '设备ID',
                        key: 'equipmentNo'
                    },
                    {
                        title: '设备型号',
                        key: 'equipmentModel'
                    },
                    {
                        title: '设备名称',
                        key: 'equipmentName'
                    },
                    {
                        title: '设备状态',
                        slot: 'action',
                        align: 'center'
                    },
                    {
                        title: '安装图片',
                        slot: 'img',
                        align: 'center'
                    }
                ],
            }
        },
        mounted () {
            axios.get('Sign/GetSignInList?familyBedID='+this.userId+'&type=0').then(res=>{
                this.tableData = res.data.result.signInList
            })
            axios.get('Equipment/GetUserFamilyBedDevice?cardNo='+this.vcCardNO).then(res=>{
                if(res.data.code===200) {
                    res.data.result.forEach(item=>{
                        item.imgShow = false
                    })
                    let a = res.data.result.filter(item=>{
                        return item.isSetup
                    })
                    let b = res.data.result.filter(item=>{
                        return !item.isSetup
                    })
                    this.data6 = [...a]
                }
            })
        },
        methods: {
            onPopperShow(index){
                this.data6[index].imgShow = true
            },
            onPopperHide(index){
                this.data6[index].imgShow = false
            }
        }
    }
</script>

<style lang="scss">
    #implementView{
        flex: 1;
        overflow: auto;
        // padding-bottom: 20px;
        // display: flex;
        // padding: 20px;
        .img-wrap {
            .img-item {
                width: 150px;
                height: 150px;
            }
        }
        .abnormal {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 0px 20px #ecedf4;
            margin: 20px 20px 0;
        }
        .table-warp {
            margin: 20px;
            flex: 1;
        }
        .el-table__body-wrapper {
            min-height: 411px !important;
        }
        .ivu-card-body {
            // height: 100%;
            // display: flex;
            padding: 0;
            .serveTable {
                width: 100%;
            }
        }
        .ivu-card {
            // margin: 20px;
        }
        .titles {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 700;
            .title {
                margin-left: 5px;
            }
            .img {
                width: 20px;
                height: 20px;
            }
        }
        .lists {
            // background-color: #fff;
            border-radius: 10px;
            // box-shadow: 0px 0px 20px #ecedf4;
            margin: 20px; 
            // overflow: hidden;
            // padding: 0 20px;
            .ivu-table:before {
                height: 0;
            }
            .lists_title {
                font-size: 20px;
                height: 60px;
                line-height: 60px;
                // border-bottom: 1px solid #e6e6e6;
                display: flex;
                align-items: center;
                .img {
                    margin:0 8px 0 24px;
                }
            }
            // .ivu-table {
            //     border-bottom: 1px solid #e8eaec;
            // }
            .ivu-table-wrapper {
                // padding: 0 20px;
                // margin: 20px;
            }
            .ivu-table-header thead tr th {
                border-top: 1px solid #e8eaec;
            }
            .ivu-table table {
                border-left: 1px solid #e8eaec;
            }
            .ivu-table-wrapper-with-border {
                border: 0;
            }
            .ivu-table-border:after {
                width: 0;
            }
            .ivu-table-cell-slot {
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    width: 60px;
                    height: 24px;
                    background-color:#18d777;
                    line-height: 24px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 14px;
                }
            }
            .btnImg {
                width: 30px;
                height: 30px;
            }
        }
    }
</style>