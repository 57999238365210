import Vue from 'vue'
import store from '../store'
import ViewUI from 'view-design';
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import homeAll from '../views/homeAll.vue'  //首页
import Video from '../views/video.vue'
import Home2 from '../views/home2.vue'
import Server from '../views/server.vue'
import UserArchives from '../views/userArchives'
import FileSee from '../views/fileSee'
import SchemeView from '../views/schemeView'  //合同
import ReformView from '../views/reformView'  //定制方案
import ImplementView from '../views/implementView' //项目实施
import ServeAdmin from '../views/serveAdmin' //服务管理
import video2 from '../views/video2'
import WorkOrder from '../views/workOrder'
import HomeProject from '../views/homeProject'
import Equipment from '../views/equipment'
Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: 'userArchives' },
    { path: '/index', redirect: '/userArchives' },
    {
        path: '/index',
        name: 'Index',
        component: Index,
        children: [
            {
                path:'/userArchives',
                component: UserArchives
            },
            {
                path: '/home2',
                component: Home2,
            },
            {
                path: '/homeAll',
                component: homeAll,
            },
            {
                path:'/video',
                component: Video
            },
            {
                path:'/server',
                component: Server
            },
            {
                path:'/fileSee',
                component: FileSee
            },
            {
                path:'/schemeView',
                component: SchemeView
            },
            {
                path:'/reformView',
                component: ReformView
            },
            {
                path:'/implementView',
                component: ImplementView 
            },
            {
                path:'/serveAdmin',
                component: ServeAdmin
            },
            {
                path:'/video2',
                component: video2
            },
            {
                path:'/workOrder',
                component:WorkOrder
            },
            {
                path:'/homeProject',
                component:HomeProject
            },
            {
                path:'/equipment',
                component:Equipment
            }
        ]
    },
    { path: '*', redirect: '/index' }
]
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    next()
});
router.afterEach(route => {
    // ViewUI.LoadingBar.finish();
});

export default router
