<template>
    <div id="serveTable">
        <el-table :data="tableData" border style="width: 100%" :height="type==1?height:'100%'" :max-height="maxHeight">
            <el-table-column align="center" prop="createTime" :label="type==0?'实施时间':'服务时间'" min-width="100"></el-table-column>
            <el-table-column align="center" prop="state" :label="type==0?'实施状态':'服务状态'" min-width="60">
                <template slot-scope="scope">
                    <span v-if="scope.row.state==1">服务开始</span>
                    <span v-else-if="scope.row.state==2">服务中</span>
                    <span v-else>服务完成</span>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="安装人/公司名称" min-width="120"></el-table-column>
            <el-table-column align="center" prop="signInUrl" :label="type==0?'实施图片':'服务图片'" min-width="100">
                    <template slot-scope="scope">
                        <!-- <span v-if="scope.row.state==1">服务开始</span> -->
                        <!-- <img class="signImg" :src="scope.row.signInUrl" alt=""> -->
                        <el-image 
                            style="width: 100px; height: 100px"
                            :src="scope.row.signInUrl" 
                            :preview-src-list="[scope.row.signInUrl]">
                        </el-image>
                    </template>
            </el-table-column>
            <el-table-column align="center" prop="remarks" label="备注" min-width="200"></el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        props:{
            tableData:{
				type:Array,
				default:()=>[]
			},
            height:{
                type:String,
                default:'none'
            },
            type:{
                type:String,
                default:'0'
            },
            maxHeight:{
                type:String,
                default:'none'
            }
        },
        data () {
            return {
                
            }
        }
    }
</script>

<style lang="scss">
    #serveTable {
        height: 100%;
        .signImg {
            width: 100px;
            height: 100px;
        }
    }
</style>