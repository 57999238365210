<template>
    <div class="imgsView">
        <div class="title-view">
            <span class="title-text">{{num===2?labels[0]:label}}</span>
        </div>
        <div class="imgs-center">
            <div class="item" v-for="(item,index) in srcList" :key="item.id" @click="tabIndex(index)">
                <div class="item-title">
                    <img class="img" src="@/assets/image/img-icon.png" alt="">
                    <span class="item-name">{{item.name}}</span>
                </div>
                <el-image 
                    style="width: 150px; height:215px"
                    :src="item.url" 
                    :z-index="2"
                    :preview-src-list="urlList">
                </el-image>
            </div>
        </div>
        <div class="title-view" v-if="num===2">
            <span class="title-text">{{labels[1]}}</span>
        </div>
        <div class="imgs-center" v-if="num===2">
            <div class="item" v-for="(item,index) in srcListTo" :key="item.id" @click="tabIndex(index)">
                <div class="item-title">
                    <img class="img" src="@/assets/image/img-icon.png" alt="">
                    <span class="item-name">{{item.name}}</span>
                </div>
                <el-image 
                    style="width: 150px; height:215px"
                    :src="item.url" 
                    :z-index="2"
                    :preview-src-list="urlListTo">
                </el-image>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    computed:{...mapState(['vcCardNO'])},
    props:{
        srcList:{
            type:Array,
            default:()=>[]
        },
        num:{
            type:Number,
            default:1
        },
        srcListTo:{
            type:Array,
            default:()=>[]
        },
        label:{
            type:String,
            default:''
        },
        labels:{
            type:Array,
            default:()=>[]
        }
    },
    data () {
        return {
            dataList:[],
            urlList:[],
            urlListTo:[],
            index:0
        }
    },
    created () {
        console.log('1111')
        this.dataList = this.srcList
        this.srcList.forEach(item=>{
            this.urlList.push(item.url)
        })
        this.srcListTo.forEach(item=>{
            this.urlListTo.push(item.url)
        })
    },
    methods: {
        tabIndex(index){
            console.log(index)
            this.index = index
        }
    },
    destroyed(){
        console.log(222)
    },
    beforeUpdate(){
        this.urlListTo = []
        this.urlList = []
        this.dataList = this.srcList
        this.srcList.forEach(item=>{
            this.urlList.push(item.url)
        })
        this.srcListTo.forEach(item=>{
            this.urlListTo.push(item.url)
        })
    }
}
</script>
<style lang="scss" scoped>
    .imgsView {
        // display: flex;
        // flex-direction: column;
        height: 100%;
        overflow: auto;
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-top: 30px;
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .imgs-center {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            .item {
                display: flex;
                flex-direction: column;
                width: 180px;
                height: 260px;
                background-color: #fff;
                margin-right: 40px;
                padding: 0 15px 15px;
                box-shadow: 0px 1px 4px #E5E9F2;
                margin-bottom: 20px;
                border-radius: 5px;
                .item-title {
                    display: flex;
                    align-items: center;
                    margin: 8px 0;
                    .img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
</style>