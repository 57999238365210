<template>
    <div id="server">
        <div class="server-center">
            <div class="title-view">
                <span class="title-text">预警服务</span>
            </div>
            <div class="center-top">
                <div class="top-btn">{{setSeleteVal}}</div>
                <Select v-model="model1" style="width:200px" @on-change="selectChange">
                    <Option v-for="item in cityList" :value="item.typeID" :key="item.typeID">{{item.typeName}}</Option>
                </Select>
                <!-- <Input @on-change="throttle(inputChange,500)" :style="{marginLeft:'30px'}" prefix="ios-search" v-model="serchValue" placeholder="订单号/服务商/服务名称" style="width: 300px" /> -->
            </div>
            <div class="server-right">
                <Table stripe :columns="columns" :data="dataList">
                    <template slot-scope="{ row }" slot="closeTime">
                        <span v-if="row.closeTime===null">--</span>
                        <span v-else>{{row.closeTime}}</span>
                    </template>
                </Table>
                <div class="center-footer">
                    <Page 
                        :total="total" 
                        show-elevator 
                        :current="current"
                        :page-size="pageSize"
                        @on-change="pageChange"
                    />
                </div>
            </div>
        </div>
        <div class="center-info">
            <div class="info-view">
                <div class="users">
                    <!-- <div class="img"></div> -->
                    <span class="user-title">{{baseInfo.userName}}</span>
                    <!-- <span class="user-num">男 94岁</span> -->
                </div>
                <div class="info-list">
                    <div class="infoss"> 
                        <span class="info-labe">住址</span>
                        <p class="info-text">{{baseInfo.address}}</p>
                    </div>
                    <div class="infos">
                        <span class="info-labe">总服务时长</span>
                        <p class="info-text">{{timeAll.totalTime+'小时'}}</p>
                    </div>
                    <div class="infos">
                        <span class="info-labe info-labes">当月服务时长</span>
                        <p class="info-text">{{timeAll.currentMonthTime+'小时'}}</p>
                    </div>
                </div>
            </div>
            <div class="img-list">
                <span class="list-title">异常类别与数量</span>
                <div class="woeklist-center">
                    <div class="list-item" v-for="item in oaderData" :key="item.typeID">
                        <div class="icon-view">
                            <span class="iconfont" :class="item.iconName"></span>
                        </div>
                        <span class="title1">{{item.typeName}}</span>
                        <span class="title2">{{item.count}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import wodrHttp from '@/utils/wodrHttp'
    import {mapState} from 'vuex'
    export default {
        data () {
            return {
                radeoVal:'order',
                columns: [
                    {
                        title: '报警名称',
                        key: 'typeName',
                        minWidth:100,
                        align:'center'
                    },
                    {
                        title: '开始时间',
                        key: 'createTime',
                        minWidth:150,
                        align:'center'
                    },
                    {
                        title: '结束时间',
                        // key: 'closeTime',
                        minWidth:150,
                        align:'center',
                        slot:'closeTime'
                    },
                    {
                        title: '备注说明',
                        key: 'content',
                        align:'center',
                        minWidth:150,
                        ellipsis:true,
                        tooltip:true
                    },
                ],
                dataList: [],
                current:1,
                total:0,
                pageSize:20,
                serchValue:'',
                startVal: 0,
                cityList: [
                    {
                        typeID: "0",
                        typeName: "全部",
                        state: 1
                    }
                ],
                model1: '0',
                num1:0,
                num2:0,
                num3:0,
                num4:0,
                num5:0,
                num6:0,
                num7:0,
                icons:[],
                abnormaChuli:[],
                timer:null,
                oaderData:[],
                timeAll:{
                    currentMonthTime: 0,
                    totalTime: 0
                }
            }
        },
        mounted () {
            wodrHttp.get('AFXFamilyBed/WorkOrder/GetUserWorkOrderSum?cardNo='+this.vcCardNO).then(res=>{
                this.cityList = [...this.cityList,...res.data.result]
                res.data.result.forEach(item=>{
                    this.oaderData.push({
                        count: item.count,
                        typeID: item.typeID,
                        typeName: item.typeName,
                        iconName : this.setOrderIcon(item.typeID)
                    })
                })
            }).then(res=>{
                this.getWorkData()
            })
            wodrHttp.get('AFXFamilyBed/Order/SumUserOrderLengthOfTime?cardNo='+this.vcCardNO).then(res=>{
                this.timeAll = res.data.result
            })
        },
        computed:{
            ...mapState(['vcCardNO','tag','baseInfo']),
            setSeleteVal(){
               const vals = this.cityList.find(item=>{return item.typeID===this.model1})
               return vals.typeName
            }
        },
        methods: {
            pageChange(e){
                this.current = e
                this.dataList = []
                this.getWorkData()
            },
            inputChange(){
                this.current = 1
                this.dataList = []
                this.getWorkData()
            },
            radioChange(){
                this.model1 = '0'
                this.current = 1
                this.cityList = [...[{id: "0",name: "全部",state: 1}],...this.icons2]
                this.getWorkData()
            },
            selectChange(){
                this.current = 1
                this.getWorkData()
            },
            getWorkData(){
                let subTypeID = this.model1==='0'?'':this.model1
                wodrHttp.get('AFXFamilyBed/WorkOrder/GetUserWorkOrderPager?cardNo='+this.vcCardNO+'&pageIndex='+(this.current-1)+'&pageSize=20'+'&typeID='+subTypeID+'&tag=HY').then(res=>{
                    this.dataList = res.data.result.dataList
                    this.total = res.data.result.totalRecordCount
                })
            },
            throttle(fn, delay) { //节流
                let that = this
                if(that.timer===null){
                    return function () {
                        that.timer = setTimeout(() => {
                            fn.apply(this, arguments);
                            that.timer = null
                        }, delay)
                    }()
                }
            },
            setOrderIcon(id){
                let result = ''
                switch(id) {
                    case '12f39777-68d6-4412-88c8-ef06579f60ae':  //设备巡检
                        result = 'iconfont icon-a-zu1404'
                        break;
                    case '617ef70c-e907-4e21-8807-eaa520609abf':  //燃气报警
                        result = 'iconfont icon-a-zu1406'
                        break;
                    case '62737fd2-c3b7-4577-8b12-0bbcee468cd3':  //电话回访
                        result = 'iconfont icon-a-zu1414'
                        break;
                    case '95526919-f5ba-482a-ae00-57f889d4fe9b':  //上门服务
                        result = 'iconfont icon-a-zu1388'
                        break;
                    case '9807fd8e-82b1-4751-ab10-363c6b889c22':  //烟感报警
                        result = 'iconfont icon-a-zu1412'
                        break;
                    case 'c530db59-713b-4aa7-826f-e050208178ee':  //紧急呼叫
                        result = 'iconfont icon-a-zu1407'
                        break;                                             
                    default:
                        result = 'iconfont icon-jingshenke'
                }
                return result 
            },
            setOrderColor(id){
                let result = ''
                switch(id) {    
                    case '设备巡检':  //设备巡检
                        result = 'color2'
                        break;
                    case '燃气报警':  //燃气报警
                        result = 'color4'
                        break;
                    case '电话回访':  //电话回访
                        result = 'color3'
                        break;
                    case '上门服务':  //上门服务
                        result = 'color1'
                        break;
                    case '烟感报警':  //烟感报警
                        result = 'color5'
                        break;
                    case '紧急呼叫':  //紧急呼叫
                        result = 'color6'
                        break;        
                    default:
                        result = 'color7'
                }
                return result 
            },
            setOrderState(id){
                let result = ''
                switch(id) {
                    case 1:  //未支付
                        result = '未支付' 
                        break;
                    case 2:  //已支付
                        result = '已支付'
                        break;
                    case 3:  //已接单
                        result = '已接单'
                        break;
                    case 4:  //已完成
                        result = '已完成'
                        break;  
                    default: //已取消
                        result = '已取消'
                }
                return result
            },

        }
    }
</script>

<style lang="scss">
    #server {
        width: 100%;
        padding: 20px 30px 10px;
        display: flex;
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 30px;
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .server-center {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;
            margin-right: 30px;
            .center-top {
                height: 50px;
                background-color: #fff;
                padding-top: 9px;
                display: flex;
                border-radius: 5px;
                margin-bottom: 14px;
                .top-btn {
                    width: 100px;
                    height: 38px;
                    background: url('../assets/image/btn-img.png') no-repeat;
                    background-size: 100% 100%;
                    padding-top: 5px;
                    color: #fff;
                    transform: translateX(-6px);
                    margin-right: 30px;
                }
                .ivu-select-selected-value {
                    text-align: left;
                }
            }
            .server-right {
                flex: 1;
                overflow-y: auto;
                .label-header {
                    padding: 20px 0;
                    position: relative;
                }
                th.label-header::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 1px;
                    height: 26px;
                    background-color: #ddd;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .ivu-table-header {
                    background-color: #fff;
                    box-shadow: 0px 10px 40px #D6DCEA;
                }
                .ivu-table th {
                    background-color: #fff;
                }
            }
            .center-footer {
                height: 110px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 20px;
            }
        }
        .center-info {
            .info-view {
                width: 270px;
                height: 180px;
                background-color: #fff;
                padding: 20px;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                color: #fff;
                .users {
                    display: flex;
                    align-items: flex-end;
                    .img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #ccc;
                        margin-right: 10px;
                    }
                    .user-title {
                        font-size: 24px;
                        margin-right: 10px;
                    }
                    .user-num {
                        font-size: 12px;
                    }
                }
                .info-list {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: column;
                    .infoss {
                        display: flex;
                        margin-bottom: 13px;
                        .info-labe {
                            font-weight: 700;
                            margin-right: 4px;
                            flex-shrink: 0;
                            padding-left: 15px;
                            position: relative;
                            display: inline-block;
                        }
                        .info-labe::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 11px;
                            height: 11px;
                            background: url('../assets/image/user1.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        .info-text {
                            font-size: 12px;
                            text-align: left;
                        }
                    }
                    .infos {
                        display: flex;
                        align-items: center;
                        margin-bottom: 13px;
                        .info-labe {
                            font-weight: 700;
                            margin-right: 4px;
                            flex-shrink: 0;
                            padding-left: 15px;
                            position: relative;
                            display: inline-block;
                        }
                        .info-labe::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 5px;
                            width: 11px;
                            height: 11px;
                            background: url('../assets/image/user2.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        .info-labes::after {
                            background: url('../assets/image/user3.png') no-repeat;
                        }
                        .info-text {
                            font-size: 12px;
                            text-align: left;
                        }
                    }
                }
            }
            .img-list {
                width:270px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                .list-title {
                    text-align: left;
                    margin-bottom: 11px;
                    color: #333;
                }
                .woeklist-center {
                    background-color: #fff;
                    padding: 20px;
                    box-shadow: 0px 1px 10px #D6DCEA;
                    border-radius: 5px;
                    height: 250px;
                    display: flex;
                    flex-wrap: wrap;
                    .list-item {
                        display: flex;
                        flex-direction: column;
                        width: 33.33%;
                        align-items: center;
                        margin-bottom: 15px;
                        .icon-view {
                            width: 63px;
                            height: 60px;
                            border: 1px solid #2176FF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            .iconfont {
                                font-size: 38px;
                                color: #2176FF;
                            }
                        }
                        .title1 {
                            font-size: 12px;
                            color: #2176FF;
                            margin-bottom: 4px;
                            margin-top: 4px;
                        }
                        .title2 {
                            font-size: 12px;
                            color: #2176FF;
                        }
                    }
                    .list-item:hover {
                        .icon-view {
                            background-color: #2176FF;
                            .iconfont {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
</style>