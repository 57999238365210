<template>
	<div class="about">
        <iframe	
			v-if="video"
			src=""
			width="100%"
			height="100%"
			id="ysOpenDevice"
			allowfullscreen
			ref="iframe"
			frameborder="0"
		>
		</iframe>
		<!-- <iframe
			v-if="video"
			src=""
			width="100%"
			height="100%"
			id="ysOpenDevice"
			allowfullscreen
			ref="iframe"
			frameborder="0"
		>
		</iframe> -->
		<div v-else class="title">
			<span>暂无监控</span>
		</div>
	</div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import {mapState} from 'vuex'
import axios from '@/utils/http.js'
	export default {
		data () {
			return {
				video:true
			}
		},
		computed:{...mapState(['userId','vcCardNO'])},
		mounted () {
			if(!this.vcCardNO) return this.video = false
			axios.get('Equipment/GetByUserID?cardNo='+this.vcCardNO+'&typeCode=SXT01').then(res=>{
				if(res.data.code===200) {
					return res.data.result[0]
				}
			}).then(val=>{
				if(!val) return this.video = false
				if(val.parameter==1){
					axios.post('https://open.ys7.com/api/lapp/token/get?appKey=a9cfafd9e40747b892a46c35bd72abeb&appSecret=6ed17e983d8f3838f8dd686f3bdb2421').then(res=>{
						document.getElementById('ysOpenDevice').src = '/static/ui_voice.html?accessToken='+res.data.data.accessToken+'&ezopen='+val.code
					})
					// axios.post('https://open.ys7.com/api/lapp/token/get?appKey=a9cfafd9e40747b892a46c35bd72abeb&appSecret=6ed17e983d8f3838f8dd686f3bdb2421').then(res=>{
					// 	document.getElementById('ysOpenDevice').src = 'https://open.ys7.com/ezopen/h5/iframe?url='+val.code+'&autoplay=1&accessToken='+res.data.data.accessToken
					// })
				}else {
					axios.post('https://open.ys7.com/api/lapp/token/get?appKey=7854748d554c4759b4b61b26ca2eeb7c&appSecret=d897e66fb144b76277a16831f2104406').then(res=>{
						document.getElementById('ysOpenDevice').src = '/static/ui_voice.html?accessToken='+res.data.data.accessToken+'&ezopen='+val.code
					})
				}
				// axios.post('https://open.ys7.com/api/lapp/token/get?appKey=a9cfafd9e40747b892a46c35bd72abeb&appSecret=6ed17e983d8f3838f8dd686f3bdb2421').then(res=>{
				// 		document.getElementById('ysOpenDevice').src = 'https://open.ys7.com/ezopen/h5/iframe?url='+'ezopen://open.ys7.com/136359427/1.hd.live'+'&autoplay=1&accessToken='+res.data.data.accessToken
				// 	})
			})
			
			// var player =  new EZUIKit.EZUIKitPlayer({ 
			// 	autoplay: true,
			// 	id: "video-container",
			// 	accessToken:res.data.data.accessToken,
			// 	url: "ezopen://open.ys7.com/D35380478/1.hd.live",
			// 	template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
			// 	// 视频上方头部控件
			// 	//header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
			// 	//plugin: ['talk'],                       // 加载插件，talk-对讲
			// 	// 视频下方底部控件
			// 	footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
			// 	// audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
			// 	// openSoundCallBack: data => console.log("开启声音回调", data),
			// 	// closeSoundCallBack: data => console.log("关闭声音回调", data),
			// 	// startSaveCallBack: data => console.log("开始录像回调", data),
			// 	// stopSaveCallBack: data => console.log("录像回调", data),
			// 	// capturePictureCallBack: data => console.log("截图成功回调", data),
			// 	// fullScreenCallBack: data => console.log("全屏回调", data),
			// 	// getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
			// });
		},
		methods: {

		}
	}
</script>
<style lang="scss">
	.about {
		flex: 1;
		.title {
			font-size: 16px;
			color: #666;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.input_video{
        width: 400px;
        height: 400px;
        margin: 0 auto;
    }
	.video-js{
		width:100%;
		height:100%;
		.no-video{
		display:flex;
		height:100%;
		font-size:14px;
		text-align:center;
		justify-content: center;
		align-items:center;
		}
	}
	#video-container {
		width: 100%;
		height: 100%;
	}
</style>