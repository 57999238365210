<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
  export default {
    created () {
      if(location.href.split('?').length>1){
        var a = location.href.split('?')
        // var a = 'http://10.26.0.211:8080/#/home2?id=2237481a-f95c-4af6-80b0-b2ffed04b0f7&type=1'
        var b = a[1].split('?')
        var c = b[0].split('&')
        this.$store.commit('setvcCardNO',c[0].split('=')[1])
        this.$store.commit('setType',c[1].split('=')[1])
        this.$store.commit('setTag',c[2].split('=')[1])
        // 421654563143545634
      }else {
        // var a = 'http://10.26.0.211:8080/#/home2?vcCardNO=420105193808213615&type=1&tag=hy'
        // var a = 'http://10.26.0.211:8080/#/home2?vcCardNO=420105195407221218&type=1&tag=hy'
        var b = a.split('?')[1]
        var c = b.split('&')
        this.$store.commit('setvcCardNO',c[0].split('=')[1])
        this.$store.commit('setType',c[1].split('=')[1])
        this.$store.commit('setTag',c[2].split('=')[1])
      }
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f5f6fa;
  min-width: 1315px;
  height: 100%;
}
.ivu-modal-header-inner {
    font-size: 20px !important;
}
.weichuli {
  background-color: #ff481e;
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 24px;
  color: #fff;
}
.yichuli {
  background-color: #18d777;
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 24px;
  color: #fff;
}
.zaichuli {
  background-color: #e9e631;
  display: inline-block;
  width: 60px;
  height: 24px;
  line-height: 24px;
  color: #fff;
}
.ivu-table-cell-slot strong{
  font-weight: 400;
}
::-webkit-scrollbar
{
    width:8px;
    height:8px;
    background-color:#fff;
}
::-webkit-scrollbar-track
{
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
    background-color:#fff;
}
::-webkit-scrollbar-thumb
{
    /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3); */
    background-color:#48c9ff;
}
.ivu-table-overflowX {
  overflow-x: hidden !important;
}
.ivu-table-tip {
  overflow: hidden !important;
}
.ivu-table-border:after {
  width: 0.5px !important;
}
.serverAdmin {
  border-radius:5px;
  .el-calendar__body {
    display: none;
  }
  .el-calendar__header {
    padding: 15px !important;
    flex-direction: initial !important;
    .el-calendar__title {
      width: 110px;
    }
    .el-button-group {
      display: flex;
    }
  }
}
.el-image-viewer__wrapper {
  z-index: 999 !important;
}
</style>
