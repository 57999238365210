<template>
    <div class="userInfo">
        <!-- <div class="title-view">
            <span class="title-text">用户档案</span>
        </div> -->
        <div class="center-view">
            <div class="user-view">
                <div class="info-view">
                    <div class="info-view-box">
                        <div class="item-titles">
                            <span class="iconfont icon-a-lujing547"></span>
                            <span>基本信息</span>
                        </div>
                        <div class="info-item">
                            <div class="item-center" :style="{paddingTop:0}">
                                <div class="user-imgs">
                                    <!-- <div class="img"></div> -->
                                    <img class="img" v-if="baseInfo.userInfo.headImgUrl" :src="baseInfo.userInfo.headImgUrl" alt="">
                                    <img v-else class="img" :src="baseInfo.userInfo.sex===1?require('@/assets/image/touxiang1.png'):require('@/assets/image/touxiang2.png')" alt="">
                                    <div class="img-titles">
                                        <span class="name">{{baseInfo.userInfo.userName}}</span>
                                        <div class="name-info">
                                            <span class="sex">{{sex}}</span>
                                            <span>{{nationID}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-infos">
                                    <span class="title">身份证号码</span>
                                    <span class="title-center">{{baseInfo.userInfo.cardNO===null||baseInfo.userInfo.cardNO===''?'--':baseInfo.userInfo.cardNO}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">联系方式</span>
                                    <span class="title-center">{{baseInfo.userInfo.mobile ===null||baseInfo.userInfo.mobile ===''?'--':baseInfo.userInfo.mobile}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">居住地址</span>
                                    <span class="title-center isovhide">{{baseInfo.userInfo.address===null||baseInfo.userInfo.address===''?'--':baseInfo.userInfo.address}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">年收入</span>
                                    <span class="title-center" v-if="baseInfo.familyBed.annualIncome&&baseInfo.familyBed.annualIncome!==null">{{baseInfo.familyBed.annualIncome+'.00'}}</span>
                                    <span class="title-center" v-else>--</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">评估分数</span>
                                    <span class="title-center" v-if="baseInfo.familyBed.dsabilityScore&&baseInfo.familyBed.dsabilityScore!==null">{{baseInfo.familyBed.dsabilityScore}}</span>
                                    <span class="title-center" v-else>--</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">文化程度</span>
                                    <span class="title-center">{{baseInfo.userInfo.diploma===null||baseInfo.userInfo.diploma===''?'--':baseInfo.userInfo.diploma}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">户籍所在地</span>
                                    <span class="title-center isovhide">{{baseInfo.userInfo.householdRegister===null||baseInfo.userInfo.householdRegister===''?'--':baseInfo.userInfo.householdRegister}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">联系人</span>
                                    <span class="title-center">{{baseInfo.familyBed.contact===null?'--':baseInfo.familyBed.contact}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">联系人电话</span>
                                    <span class="title-center">{{baseInfo.familyBed.moblie===null?'--':baseInfo.familyBed.moblie}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">身体状况</span>
                                    <span class="title-center">{{healthStatus}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">婚姻状况</span>
                                    <span class="title-center">{{marriageType}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">血型</span>
                                    <span class="title-center">{{bloodType}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div class="info-view-box">
                        <div class="info-item-to">
                            <div class="item-titles">
                                <span class="iconfont icon-a-zu869" style="color:#2176FF"></span>
                                <span>居住情况</span>
                            </div>
                            <div class="item-center">
                                <div class="item-infos">
                                    <span class="title">住房面积(m²)</span>
                                    <span class="title-center">{{baseInfo.userInfo.housingArea===null||baseInfo.userInfo.housingArea===''?'--':baseInfo.userInfo.housingArea}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">住房人口(人)</span>
                                    <span class="title-center">{{baseInfo.userInfo.residentPopulation===null||baseInfo.userInfo.residentPopulation===''?'--':baseInfo.userInfo.residentPopulation}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">居住状态</span>
                                    <span class="title-center">{{liveStatus}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">住房类型</span>
                                    <span class="title-center">{{housingType}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">住房性质</span>
                                    <span class="title-center">{{housingNature}}</span>
                                </div>
                            </div>
                            <div class="item-titles" style="marginTop:45px">
                                <span class="iconfont icon-a-zu870" style="color:#FFA941"></span>
                                <span>配偶信息</span>
                            </div>
                            <div class="item-center">
                                <div class="item-infos">
                                    <span class="title">配偶姓名</span>
                                    <span class="title-center">{{baseInfo.spouse===null||baseInfo.spouse.name===''?'--':baseInfo.spouse.name}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">身份证号码</span>
                                    <span class="title-center">{{baseInfo.spouse===null||baseInfo.spouse.cardNo===''?'--':baseInfo.spouse.cardNo}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">年龄</span>
                                    <span class="title-center">{{baseInfo.spouse===null||baseInfo.spouse.bornDate===''?'--':baseInfo.spouse.bornDate}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">是否享受低保</span>
                                    <span class="title-center">{{spouseSubsistence}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">原工作单位</span>
                                    <span class="title-center">{{baseInfo.spouse===null||baseInfo.spouse.company===''?'--':baseInfo.spouse.company}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">身体状况</span>
                                    <span class="title-center">{{spouseHealthStatus}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="info-view-box">
                        <div class="item-titles">
                            <span class="iconfont icon-a-lujing547"></span>
                            <span>健康状态</span>
                        </div>
                        <div class="info-item">
                            <div class="item-center">
                                <div class="item-infos">
                                    <span class="title">身高(cm)</span>
                                    <span class="title-center">{{baseInfo.userInfo.height===null||baseInfo.userInfo.height===''?'--':baseInfo.userInfo.height}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">体重(kg)</span>
                                    <span class="title-center">{{baseInfo.userInfo.weight===null||baseInfo.userInfo.weight===''?'--':baseInfo.userInfo.weight}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">常态血压</span>
                                    <span class="title-center">{{baseInfo.userInfo.bloodPressure===null||baseInfo.userInfo.bloodPressure===''?'--':baseInfo.userInfo.bloodPressure}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">常态心率(bpm)</span>
                                    <span class="title-center">{{baseInfo.userInfo.heartRate===null||baseInfo.userInfo.heartRate===''?'--':baseInfo.userInfo.heartRate}}</span>
                                </div>
                                <!-- <div class="item-infos">
                                    <span class="title">行动能力</span>
                                    <span class="title-center">{{actionCapability}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">定期体检</span>
                                    <span class="title-center">{{physical}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">定期随访</span>
                                    <span class="title-center">{{followUp}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">医疗保障</span>
                                    <span class="title-center">{{medicalType}}</span>
                                </div> -->
                                <div class="item-infos">
                                    <span class="title">睡眠质量</span>
                                    <span class="title-center">{{sleepQuality}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">可自理项目</span>
                                    <span class="title-center center-max">{{selfCareProject}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">以往病史</span>
                                    <span class="title-center center-max">{{pastHistory}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">就医情况</span>
                                    <span class="title-center center-max">{{baseInfo.userInfo.medicalTreatment===null||baseInfo.userInfo.medicalTreatment===''?'--':baseInfo.userInfo.medicalTreatment}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">用药情况</span>
                                    <span class="title-center center-max isovhide1">{{baseInfo.userInfo.medication===null||baseInfo.userInfo.medication===''?'--':baseInfo.userInfo.medication}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">备注</span>
                                    <span class="title-center center-max">{{baseInfo.userInfo.remark===null||baseInfo.userInfo.remark===''?'--':baseInfo.userInfo.remark}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="info-view">
                    <div class="item-infos">
                        <div class="item-titles">
                            <span class="iconfont icon-a-lujing447" style="color:#FFB000"></span>
                            <span>子女信息</span>
                        </div>
                        <div class="info-item-childs" v-for="(item,index) in baseInfo.childs" :key="index">
                            <div class="item-center" v-if="baseInfo.childs.length>0">
                                <div class="item-infos">
                                    <span class="title">子女姓名</span>
                                    <span class="title-center">{{baseInfo.childs[index].name}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">联系电话</span>
                                    <span class="title-center">{{baseInfo.childs[index].phone}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">是否同住</span>
                                    <span class="title-center">{{baseInfo.childs[index].isCohabit===1?'是':'否'}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">居住区域</span>
                                    <span class="title-center" v-if="baseInfo.childs[index].residentialArea===1">同市</span>
                                    <span class="title-center" v-if="baseInfo.childs[index].residentialArea===2">外市</span>
                                    <span class="title-center" v-if="baseInfo.childs[index].residentialArea===3">国外</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">享受低保</span>
                                    <span class="title-center">{{baseInfo.childs[index].subsistence===1?'是':'否'}}</span>
                                </div>
                                <div class="item-infos">
                                    <span class="title">重度残疾</span>
                                    <span class="title-center">{{baseInfo.childs[index].physicalDisability===1?'是':'否'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-view">
                    <div class="item-infos">
                        <div class="item-titles">
                            <span class="iconfont icon-a-lujing547" style="color:#2176FF"></span>
                            <span>评估档案</span>
                        </div>
                        <div class="item-imgs">
                            <div class="item" v-for="item in srcList" :key="item.id" style="marginRight:20px">
                                <el-image 
                                    style="width: 100px; height: 100px;"
                                    :src="item.url" 
                                    :preview-src-list="urlList">
                                </el-image>
                                <span class="item-name">{{item.name}}</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- <div class="user-view">
                <div class="item-titles">
                    <span class="iconfont icon-a-zu870" :style="{color:'#dfa25d'}"></span>
                    <span>配偶及子女信息</span>
                </div>
                <div class="info-view">
                    <div class="info-item">
                        <div class="item-center">

                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="user-view">
                <div class="item-titles">
                    <span class="iconfont icon-a-lujing447" :style="{color:'#2276ff'}"></span>
                    <span>健康状态</span>
                </div>
                <div class="info-view">
                    <div class="info-item">
                        <div class="item-center">
                            
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="item-center">
                            
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="item-center">

                        </div>
                    </div>
                    <div class="info-item">
                        <div class="item-center">
  
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data () {
        return {
            childsData:[]
        }
    },
    computed:{
        ...mapState(['baseInfo']),
        sex(){
            if(this.baseInfo.userInfo.sex===null) return '--'
            let arr = ['男','女']
            return arr[this.baseInfo.userInfo.sex]
        },
        nationID(){
            if(this.baseInfo.userInfo.nationID===null||!this.baseInfo.userInfo.nationID) return '--'
            let arr = ['汉族']
            return arr[this.baseInfo.userInfo.nationID-1]
        },
        healthStatus(){
            if(this.baseInfo.userInfo.healthStatus===null||!this.baseInfo.userInfo.healthStatus) return '--'
            let arr = ['生活能自理','生活半自理','生活不能自理']
            return arr[this.baseInfo.userInfo.healthStatus-1]
        },
        marriageType(){
            if(this.baseInfo.userInfo.marriageType===null||!this.baseInfo.userInfo.marriageType) return '--'
            let arr = ['未婚','已婚','丧偶','离婚']
            return arr[this.baseInfo.userInfo.marriageType-1]
        },
        bloodType(){
            if(this.baseInfo.userInfo.bloodType===null||!this.baseInfo.userInfo.bloodType) return '--'
            let arr = ['A型','B型','AB型','O型','其他']
            return arr[this.baseInfo.userInfo.bloodType-1]
        },
        liveStatus(){
            if(this.baseInfo.userInfo.liveStatus===null||!this.baseInfo.userInfo.liveStatus) return '--'
            let arr = ['与子女生活','与配偶生活','与亲属生活','独自生活','孤寡','其他']
            return arr[this.baseInfo.userInfo.liveStatus-1]
        },
        housingType(){
            if(this.baseInfo.userInfo.housingType===null||!this.baseInfo.userInfo.housingType) return '--'
            let arr = ['底层住宅','多层住宅','中高层住宅','高层住宅','其他']
            return arr[this.baseInfo.userInfo.housingType-1]
        },
        housingNature(){
            if(this.baseInfo.userInfo.housingNature===null||!this.baseInfo.userInfo.housingNature) return '--'
            let arr = ['自有住房','廉租/公租房','经济适用房','租赁房']
            return arr[this.baseInfo.userInfo.housingNature-1]
        },
        spouseSubsistence(){
            if(this.baseInfo.spouse===null||!this.baseInfo.spouse.subsistence) return '--'
            let arr =['不享受','享受']
            return arr[this.baseInfo.spouse.subsistence]
        },
        spouseHealthStatus(){
            if(this.baseInfo.spouse===null||!this.baseInfo.spouse.healthStatus) return '--'
            let arr = ['生活能自理','生活半自理','生活不能自理']
            return arr[this.baseInfo.spouse.healthStatus-1]
        },
        actionCapability(){
            if(this.baseInfo.userInfo.actionCapability===null||!this.baseInfo.userInfo.actionCapability) return '--'
            let arr = ['行走无碍','行动不便','依靠器械辅助行动','不能行走']
            return arr[this.baseInfo.userInfo.actionCapability-1]
        },
        physical(){
            if(this.baseInfo.userInfo.physical===null) return '--'
            let arr = ['不需要','需要']
            return arr[this.baseInfo.userInfo.physical]
        },
        followUp(){
            if(this.baseInfo.userInfo.followUp===null) return '--'
            let arr = ['不需要','需要']
            return arr[this.baseInfo.userInfo.followUp]
        },
        medicalType(){
            if(this.baseInfo.userInfo.medicalType===null||!this.baseInfo.userInfo.medicalType) return '--'
            let arr = ['城镇职工医保','城镇居民医保','新型农村合作医疗','贫困救助','商业医疗保险','全公费','全自费','其他社会保险','其他']
            return arr[this.baseInfo.userInfo.medicalType]
        },
        sleepQuality(){
            if(this.baseInfo.userInfo.sleepQuality===null||!this.baseInfo.userInfo.sleepQuality) return '--'
            let arr = ['很好','较好','一般','偶尔失眠','经常失眠']
            return arr[this.baseInfo.userInfo.sleepQuality-1]
        },
        selfCareProject(){
            if(this.baseInfo.userInfo.selfCareProject===null||!this.baseInfo.userInfo.selfCareProject) return '--'
            let arr = ['穿衣','行走','吃饭','服药','如厕','洗澡','打电话','做饭菜','洗衣','简单家务','买菜购物','室外活动',]
            let value = ''
            if(this.baseInfo.userInfo.selfCareProject) {
                let valArr = this.baseInfo.userInfo.selfCareProject.split(',')
                let length = valArr.length
                valArr.forEach((item,index)=>{
                    if(index+1===length) {
                            value += arr[parseInt(item)-1]
                    }else {
                        value += arr[parseInt(item)-1]+'、'
                    }
                })
            }
            return value
        },
        pastHistory(){
            if(this.baseInfo.userInfo.pastHistory===null||!this.baseInfo.userInfo.pastHistory) return '--'
            let arr = ['高血压','高血脂','糖尿病','冠心病','肝炎','肝硬化','气管炎','肺炎','肺结核','胃炎','癫痫','甲状腺疾病','精神疾病','其他']
            let value = ''
            if(this.baseInfo.userInfo.pastHistory) {
                let valArr = this.baseInfo.userInfo.pastHistory.split(',')
                let length = valArr.length
                valArr.forEach((item,index)=>{
                    if(index+1===length) {
                            value += arr[parseInt(item)-1]
                    }else {
                        value += arr[parseInt(item)-1]+'、'
                    }
                })
            }
            return value
        },
    },
    props:{
        srcList:{
            type:Array,
            default:()=>[]
        },
        urlList:{
            type:Array,
            default:()=>[]
        }
    },
    mounted () {
        
    }
}
</script>
<style scoped lang="scss">
    .userInfo {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-top: 30px;
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .center-view {
            flex: 1;
            margin-top: 15px;
            display: flex;
            flex-shrink: 1;
            flex-direction: column;
            overflow-y: auto;
            border-radius: 5px;
            .item-imgs {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .item {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                }
            }
            .info-view-box {
                display: flex;
                flex-direction: column;
                width: 32.33%;
            }
            .user-view {
                flex: 1;
                background-color: #fff;
                .item-titles {
                    margin: 20px 0;
                    // padding-left: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #333;
                    .iconfont {
                        margin-right: 5px;
                        color: #33b6a6;
                        font-size: 18px;
                    }
                }
                .info-item-childs {
                    display: flex;
                    .item-center {
                        padding: 0 0 15px;
                        box-shadow: 0px 1px 4px #E5E9F2;
                    }
                    .user-imgs {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #EBEDF4;
                        padding:17px 30px;
                        .img {
                            width: 60px;
                            height: 60px;
                            background-color: #ccc;
                            border-radius: 50%;
                            margin-right: 18px;
                        }
                        .img-titles {
                            .name {
                                color: #333;
                            }
                            .name-info {
                                font-size: 12px;
                                color: #666;
                                margin-top: 6px;
                                .sex {
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                    .item-infos {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 20px;
                        margin-top: 15px;
                        .title {
                            color: #333;
                            flex-shrink: 0;
                            width: 100px;
                            text-align: left;
                        }
                        .title-center {
                            color: #999; 
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: break-all;
                        }
                        .center-max {
                            text-align: left;
                        }
                    }
                }
                .info-item-to {
                    .item-center {
                        box-shadow: 0px 1px 4px #E5E9F2;
                        padding: 15px 0;
                            .user-imgs {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EBEDF4;
                                padding:17px 30px;
                                .img {
                                    width: 60px;
                                    height: 60px;
                                    background-color: #ccc;
                                    border-radius: 50%;
                                    margin-right: 18px;
                                }
                                .img-titles {
                                    .name {
                                        color: #333;
                                    }
                                    .name-info {
                                        font-size: 12px;
                                        color: #666;
                                        margin-top: 6px;
                                        .sex {
                                            margin-right: 6px;
                                        }
                                    }
                                }
                            }
                            .item-infos {
                                display: flex;
                                justify-content: space-between;
                                padding: 0 20px;
                                margin-top: 15px;
                                .title {
                                    color: #333;
                                    flex-shrink: 0;
                                    width: 100px;
                                    text-align: left;
                                }
                                .title-center {
                                    color: #999; 
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    word-break: break-all;
                                }
                                .center-max {
                                    text-align: left;
                                }
                            }
                    }
                }
                .info-view {
                    border-radius: 5px;
                    padding: 0px 20px 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .info-item {
                        width: 100%;
                        height: 520px;
                        box-shadow: 0px 1px 4px #E5E9F2;
                        flex-shrink: 1;
                        // margin-bottom: 30px;
                        margin-right: 1.3%;
                        overflow: auto;
                        .item-center {
                            // padding-top: 20px;
                            .user-imgs {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EBEDF4;
                                padding:17px 30px;
                                // margin-bottom: 20px;
                                .img {
                                    width: 60px;
                                    height: 60px;
                                    background-color: #ccc;
                                    border-radius: 50%;
                                    margin-right: 18px;
                                }
                                .img-titles {
                                    .name {
                                        color: #333;
                                    }
                                    .name-info {
                                        font-size: 12px;
                                        color: #666;
                                        margin-top: 6px;
                                        .sex {
                                            margin-right: 6px;
                                        }
                                    }
                                }
                            }
                            .item-infos {
                                display: flex;
                                justify-content: space-between;
                                padding: 0 20px;
                                margin-top: 15px;
                                .title {
                                    color: #333;
                                    flex-shrink: 0;
                                    width: 100px;
                                    text-align: left;
                                }
                                .title-center {
                                    color: #999; 
                                    // white-space: nowrap;
                                    // text-overflow: ellipsis;
                                    // overflow: hidden;
                                    // word-break: break-all;
                                }
                                .isovhide{
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    word-break: break-all;
                                }
                                .isovhide1 {
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 7;
                                    line-clamp: 7;
                                    -webkit-box-orient: vertical;
                                }
                                .center-max {
                                    text-align: left;
                                }
                            }
                        }
                    }
                    // @media only screen and (min-width: 1700px) {
                    //     .info-item {
                    //         width: 24%;
                    //         margin-right: 1.3%;
                    //     }
                    //     .info-item:nth-of-type(4) {
                    //         margin-right: 0;
                    //     }
                    // }
                    // @media only screen and (max-width: 1700px) {
                    //     .info-item {
                    //         width: 31.33%;
                    //         margin-right: 2.8%;
                    //     }
                    //     .info-item:nth-of-type(3) {
                    //         margin-right: 0;
                    //     }
                    // }
                    // @media only screen and (max-width: 1350px) {
                    //     .info-item {
                    //         width: 48%;
                    //         margin-right: 3%;
                    //     }
                    //     .info-item:nth-of-type(2),
                    //     .info-item:nth-of-type(4) {
                    //         margin-right: 0;
                    //     }
                    //     .info-item:nth-of-type(3) {
                    //         margin-right: 3%;
                    //     }
                    // }
                }
            }

        }
    }
</style>