import axios from 'axios'
import { Message } from 'view-design'
const instance = axios.create({
    baseURL: 'http://afxjtcwapplethydata.afxzhky.com/api/',  //线上
    // baseURL: 'http://bigdataapi2.afxzhky.com/api/',  //线上
    // baseURL:'http://192.168.10.6:8086/api/',   //线下
    timeout: 5000,
    // headers: {
    //     'content-type': 'application/x-www-form-urlencoded',
    // }
})

    instance.interceptors.request.use(function (config) {

            return config;   
        // 在发送请求之前做些什么
    }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    })
    
    instance.interceptors.response.use(function (response) {
        if (response.data.code !== 200) {
            // Message.error(response.data.message)
        }
        $vm.$Spin.hide()
        return response;
    }, function (error) {
        // 对响应错误做点什么
        let config = error.config
        $vm.$Spin.hide()
        Message.warning((error && error.data && error.data.msg) || '加载失败,请重试！')
        return Promise.reject(error);
    });

export default instance