<template>
    <div class="header-item">
        <div class="item-info" :class="active===1?'item-info-active':''" @click="tabClick(1)">
            <div class="item-box">
                <span class="iconfont icon-a-zu939"></span>
                <span class="box-text">{{this.state.step1+'%'}}</span>
            </div>
            <canvas ref="canvas1" id="canvas" width="90" height="65"></canvas>
            <span>用户档案</span>   
        </div>
        <span class="iconfont icon-a-lujing586"></span>
        <div class="item-info" :class="active===2?'item-info-active':''" @click="tabClick(2)">
            <div class="item-box">
                <span class="iconfont icon-a-zu941"></span>
                <span class="box-text">{{this.state.step2+'%'}}</span>
            </div>
            <canvas ref="canvas2" id="canvas" width="90" height="65"></canvas>
            <span>签订合同</span>   
        </div>
        <span class="iconfont icon-a-lujing586"></span>
        <div class="item-info" :class="active===3?'item-info-active':''" @click="tabClick(3)">
            <div class="item-box">
                <span class="iconfont icon-a-zu942"></span>
                <span class="box-text">{{this.state.step3+'%'}}</span>
            </div>
            <canvas ref="canvas3" id="canvas" width="90" height="65"></canvas>
            <span>定制方案</span>   
        </div>
        <span class="iconfont icon-a-lujing586"></span>
        <div class="item-info" :class="active===4?'item-info-active':''" @click="tabClick(4)">
            <div class="item-box">
                <span class="iconfont icon-a-zu943"></span>
                <span class="box-text">{{this.state.step4+'%'}}</span>
            </div>
            <canvas ref="canvas4" id="canvas" width="90" height="65"></canvas>
            <span>项目实施</span>   
        </div>
        <span class="iconfont icon-a-lujing586"></span>
        <div class="item-info" :class="active===5?'item-info-active':''" @click="tabClick(5)">
            <div class="item-box">
                <span class="iconfont icon-a-zu944"></span>
                <span class="box-text">{{this.state.step5+'%'}}</span>
            </div>
            <canvas ref="canvas5" id="canvas" width="90" height="65"></canvas>
            <span>项目验收</span>   
        </div>
    </div>
</template>
<script>
let globalID = []
export default {
    data () {
        return {
            canceAnimation:'',
            isAst:true
        }
    },
    props:{
        active:{
            type:Number,
            default:1
        },
        state:{
            type:Object,
            default:{
                step1: 0,
                step2: 0,
                step3: 0,
                step4: 0,
                step5: 0
            }
        }
    },
    mounted () {
        const arr = ['canvas1','canvas2','canvas3','canvas4','canvas5']

        arr.forEach((item,index)=>{
            this.setCanvas(this.$refs[item],this.state['step'+(index+1)])
        })
    },
    methods: {
        setCanvas(canvas,num){
            let _this = this
            let ctx = canvas.getContext('2d');
            let radians = (Math.PI / 180) * 180;
            let startTime = Date.now();
            let time = 1000;
            let clockwise = 2;
            let cp1x, cp1y, cp2x, cp2y;
            // 初始状态
            // ctx.bezierCurveTo(90, 28, 92, 179, 200, 100);
            // 末尾状态
            // ctx.bezierCurveTo(145, 100, 41, 100, 200, 100);
            let numVal = 65-(num*65/100)
            //  let numVal = 0
            globalID.push(requestAnimationFrame(function waveDraw() {  
                let t = Math.min(1.0, (Date.now() - startTime) / time);
                if(numVal<20) {
                    if(clockwise===1) {
                        cp1x = numVal + (5 * t);
                        cp1y = numVal + (3 * t);
                        cp2x = numVal - (1 * t);
                        cp2y = numVal - (2 * t);
                    }else if(clockwise===2) {
                        cp1x = (numVal+5) - (5 * t);
                        cp1y = (numVal+3) - (3 * t);
                        cp2x = (numVal-1) + (1 * t);
                        cp2y = (numVal-2) + (2 * t);
                    }else if(clockwise===3) {
                        cp1x = numVal - (3 * t);
                        cp1y = numVal - (7 * t);
                        cp2x = numVal + (2 * t);
                        cp2y = numVal + (5 * t);
                    }else{
                        cp1x = (numVal-3)  + (3 * t);
                        cp1y = (numVal-7)  + (7 * t);
                        cp2x = (numVal+2) - (2 * t);
                        cp2y = (numVal+5)  - (5 * t);
                    }
                }else {
                    if(clockwise===1) {
                        cp1x = numVal + (11 * t);
                        cp1y = numVal + (15 * t);
                        cp2x = numVal - (5 * t);
                        cp2y = numVal - (9 * t);
                    }else if(clockwise===2) {
                        cp1x = (numVal+11)  - (11 * t);
                        cp1y = (numVal+15)  - (15 * t);
                        cp2x = (numVal-5) + (5 * t);
                        cp2y = (numVal-9) + (9 * t);
                    }else if(clockwise===3) {
                        cp1x = numVal - (10 * t);
                        cp1y = numVal - (15 * t);
                        cp2x = numVal + (8 * t);
                        cp2y = numVal + (10 * t);
                    }else{
                        cp1x = (numVal-10)  + (10 * t);
                        cp1y = (numVal-15)  + (15 * t);
                        cp2x = (numVal+8) - (8 * t);
                        cp2y = (numVal+10)  - (10 * t);
                    }
                }
                ctx.clearRect(0, 0, 200, 200);  // 	起始一条路径，或重置当前路径
                ctx.beginPath(); //	起始一条路径，或重置当前路径
                ctx.moveTo(0, numVal); //开始坐标
                // 绘制三次贝塞尔曲线
                ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, 90, numVal);
                // 绘制圆弧
                ctx.arc(100, 200, 200, 0, radians, 0);
                ctx.fillStyle = "#2176FF";
                ctx.fill();
                ctx.save();  
                
                if( t == 1 ) {
                    startTime = Date.now();
                    clockwise = clockwise +1
                    if(clockwise > 4){
                        clockwise = 1
                    }
                }
                if(_this.isAst) {
                    requestAnimationFrame(waveDraw)
                }
            }))
        },
        tabClick(index){
            this.$emit('change',index)
        }
    },
    beforeDestroy(){
        this.isAst = false
        globalID.forEach(item=>{
            cancelAnimationFrame(item)
        })
        globalID = []
    }
}
</script>

<style scoped lang="scss">
    .header-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px;
        .item-info {
            width: 102px;
            height: 96px;
            background-color: #fff;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #333;
            position: relative;
            cursor: pointer;
            .item-box {
                width: 90px;
                height: 65px;
                background: #DCDDDE;
                margin-bottom: 5px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex-direction: column;
                .iconfont {
                    color: #fff;
                    font-size: 35px;
                    position: absolute;
                    z-index: 999;
                    top: 15px;
                }
                .box-text {
                    font-size: 12px; 
                    color: #fff;
                    margin-top: 7px;
                    position: absolute;
                    bottom: 26px;
                    z-index: 999;
                }
            }
            #canvas {
                position: absolute;
                top: 4px;
                    box-sizing: content-box;
                    background-color: #dededc; 
            }
        }
        .item-info-active {
            background-image:linear-gradient(#679CF6, #4072EE);
            box-shadow: 0px 1px 10px rgba(103, 156, 246, 1);
            color: #fff;
        }
        .iconfont {
            color: #2276ff;
        }
    }
</style>