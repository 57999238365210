<template>
  <div id="index">
    <div class="content">
        <div class="menu" v-if="this.type==1">
            <Menu ref="leftMenu" @on-select="onSelect" accordion :theme="theme3" :active-name="activNname" :open-names="open" width="200px">
                <!-- <Submenu name="1">
                    <template slot="title">
                        <span class="iconfont icon-a-zu792"></span>
                        实施进度
                    </template>
                    <Menu-item name="userArchives">用户档案</Menu-item>
                    <Menu-item name="home">项目实施</Menu-item>
                </Submenu> -->
                <MenuGroup>
                    <MenuItem name="userArchives">
                        <span class="iconfont icon-a-zu792"></span>
                        实施进度
                    </MenuItem>
                    <MenuItem name="homeAll">
                        <span class="iconfont icon-a-zu791"></span>
                        居家监护与服务
                    </MenuItem>
                    <!-- <Submenu name="1">
                        <template slot="title">
                            <span class="iconfont icon-a-zu791"></span>
                            居家监护与服务
                        </template>
                        <Menu-item name="home">健康监护</Menu-item>
                        <Menu-item name="homeProject">安全监护</Menu-item>
                        <Menu-item name="server">居家服务</Menu-item>
                    </Submenu> -->
                    <MenuItem name="equipment">
                        <span class="iconfont icon-a-zu794"></span>
                        设备管理
                    </MenuItem>
                    <MenuItem name="serveAdmin">
                        <span class="iconfont icon-a-zu845"></span>
                        数据统计分析
                    </MenuItem>
                    <!-- <MenuItem name="serveAdmin">
                        <span class="iconfont icon-a-zu796"></span>
                        服务管理
                    </MenuItem> -->
                </MenuGroup>
            </Menu>
        </div>
        <router-view/>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/http.js'
import wodrHttp from '@/utils/wodrHttp'
import {mapState} from 'vuex'
import ServeTable from '@/components/serve-table.vue';
export default {
    name: "Home",
    computed:{

        ...mapState(['userId','type','tag','vcCardNO','times','stateIndex']),
        setTabIcon1(){
            let icon = ''
            if(this.stageType>=1&&this.stageType!==6) {
                icon = require('@/assets/image/buzou1-1.png')
            }else {
                icon = require('@/assets/image/buzou1-2.png')
            }
            return icon
        },
        setTabIcon2(){
            let icon = ''
            if(this.stageType>=2&&this.stageType!==6) {
                icon = require('@/assets/image/buzou2-1.png')
            }else {
                icon = require('@/assets/image/buzou2-2.png')
            }
            return icon
        },
        setTabIcon3(){
            let icon = ''
            if(this.stageType>=3&&this.stageType!==6) {
                icon = require('@/assets/image/buzou3-1.png')
            }else {
                icon = require('@/assets/image/buzou3-2.png')
            }
            return icon
        },
        setTabIcon4(){
            let icon = ''
            if(this.stageType>=4&&this.stageType!==6) {
                icon = require('@/assets/image/buzou4-1.png')
            }else {
                icon = require('@/assets/image/buzou4-2.png')
            }
            return icon
        },
        setTabIcon5(){
            let icon = ''
            if(this.stageType===5){
                icon = require('@/assets/image/buzou5-1.png')
            }else if(this.stageType===6){
                icon = require('@/assets/image/buzou6.png')
            }else {
                icon = require('@/assets/image/buzou5-2.png')
            }
            return icon
        }
    },
    components:{
        ServeTable
    },
    data () {
        return {
            theme3: 'light',
            activNname:'userArchives',
            baseInfo:{
                "gUserID": "",
                "vcTrueName": "",
                "cMobile": "",
                "vcAddress": "",
                "vcCardNO": "",
                "dBornDate": "",
                "bSex": ''
            },
            gridData: [],
            dialogTableVisible:false,
            abnormaChuli:[],
            state:{
                step1: 0,
                step2: 0,
                step3: 0,
                step4: 0,
                step5: 0
            },
            open: [],
            stageType:0
        }
    },
    mounted () { 
        this.open = ["1"];
        this.$nextTick(function() {
            this.$refs.leftMenu.updateOpened();
            this.$refs.leftMenu.updateActiveName();
        })
        if(!this.vcCardNO) return
        axios.get(this.$api.GetFamilyBedDocByCardNo + `?cardNo=${this.vcCardNO}`).then(res=>{
            console.log(res)
            this.$store.commit('setStageType',res.data.result.stageType) 
            this.$store.commit('setUserId',res.data.result.userInfo.id) 
            this.$store.commit('setPhone',res.data.result.userInfo.mobile)
            this.$store.commit('setBaseInfo',res.data.result)
            this.$store.commit('setTimes',{
                startTime:res.data.result.familyBed.startTime,
                endTime:res.data.result.familyBed.finishTime
            })
        })
    },
    methods: {
        onSelect(name){
            if(this.activNname===name) return
            this.activNname = name
            this.$router.push({
                path:name
            })
        },
        setStates(state,type,colors){
            let result = ''
            if(colors) {
                switch(state) {
                    case 0:
                        result = '#ff481e'
                        break;
                    case 1:
                        result = '#f0ad1c'
                        break;
                    default:
                        result = '#18d777'
                } 
            }else {
                switch(state) {
                    case 0:
                        result = type===1?'未处理':'代办'
                        break;
                    case 1:
                        result = type===1?'正在处理':'进行中'
                        break;
                    default:
                        result = type===1?'已处理':'已完成'
                } 
            }
            return result
        }
    }
};
</script>

<style lang="scss">
    #index {
        display: flex;
        flex-direction: column;
        height: 100%;
        .ivu-menu-submenu-title {
            display: flex;
        }
        .ivu-menu-item-group-title {
            display: none;
        }
        // .ivu-icon {
        //     display: none;
        // }
        .ivu-menu-item {
            display: flex;
            align-items: center;
            position: relative;
            padding: 14px 15px;
            margin: 20px 0;
        }
        .content {
            height: 100%;
            display: flex;
            flex-shrink: 0;
            overflow: hidden;
            .menu {
                width: 200px;
                display: flex;
                // overflow-y: auto;
                flex-shrink: 0;
                background-image: linear-gradient(#679CF6, #4072EE);
            }
        }
        .ivu-menu-vertical.ivu-menu-light:after {
            width: 0;
        }
        .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
            z-index: 0 !important;
            background-color: #fff;
            color: #444 !important;
            font-size: 22px;
            width: 220px;
            position: relative;
            z-index: 999;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
            border-radius: 0px 5px 5px 0px;
            .iconfont {
                color: #444 !important;
                font-size: 29px;
            }
        }
        .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
            display: none;
        }
        .ivu-menu-light {
            background-color: transparent;
        }
        .ivu-menu {
            color: #000;
        }
        // .ivu-menu-item-active {
        //     color: #48c9ff;
        //     font-weight: 700;
        // }
        // .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
        //     color: #48c9ff;
        //     font-weight: 700;
        // }
        .ivu-menu-item {
            color: #fff;
            font-size: 18px;
            // margin-bottom: 40px;
            width: 200px;
            .iconfont {
                color: #fff !important;
                font-size: 29px;
            }
        }
        .ivu-menu-item:hover {
            color: #fff;
            .iconfont {
                color: #9f9f9f !important;
            }
        }
        .headerCarousel {
            flex: 1;
            .el-carousel__item h3 {
                color: #475669;
                font-size: 14px;
                opacity: 0.75;
                margin: 0 50px;
            }
            .carousel-content {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .headerServe {
            margin-right: 30px;
            cursor: pointer;
            color: #9bc2f7;
        }
        .serve-warp {
            height: 400px;
        }
        .menu {
            padding-top: 145px;
            .iconfont {
               color: #666;
               margin-right: 5px;
            } 
            .infonWeight {
                font-weight: 700;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #fff;
        }
        .carousel-img {
            width: 20px;
            margin-right: 10px;
        }
        .footer {
            width: 135px;
            height: 100%;
            display: flex;
            align-items: center;
            // padding: 30px 0;
            flex-shrink: 0;
            .footer-view {
                height: 100%;
                width: 100%;
                background-color: #fff;
                box-shadow: 0px 5px 15px #E8E8E8;
                // border-radius: 5px;
                display: flex;
                flex-direction: column;
                .footer-title {
                    // height: 130px;
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    .titles {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #666;
                        font-size: 18px;
                        .iconfont {
                            color: #2591eb;
                            font-size: 20px;
                            margin-right: 5px;
                        }
                    }
                    .title-num {
                        font-size: 60px;
                    }
                    .times {
                        font-size: 12px;
                        color: #444;
                        display: flex;
                        flex-direction: column;
                        .state-time {
                            margin-bottom: 5px;
                        }
                    }
                }
                .footer-items {
                    display: flex;
                    flex-direction: column;
                    flex-flow: row wrap;
                    align-content:space-between;
                    margin-top: 50px;
                    flex: 1;
                    margin-bottom: 20px;
                    .item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        .iconfont {
                            font-size: 40px;
                            color: #3a9ced;
                            margin-bottom: 9px;
                        }
                        .item-text {
                            color: #444;
                        }
                        .item-img {
                            width: 50px;
                            margin-bottom: 9px;
                        }
                    }
                    .icon-a-zu860 {
                        font-size: 25px;
                        color: #3A9CED;
                        width: 100%;
                        // margin: 22% 0 19%;
                    }
                    .colors {
                        color: #e1e1e1;
                    }
                }
            }
        }
        .menu-name {
            margin-right: 20px;
        }
        .el-progress {
            position: absolute;
            right: 20px;
        }
        .el-progress__text {
            position: absolute;
            top: 50%;
            transform:scale(0.8) translateY(-50%);
        }
        .ivu-menu-submenu {
            .ivu-menu-submenu-title {
                color: #fff;
                font-size: 18px;
                width: 200px;
                display: flex;
                align-items: center;
                position: relative;
                padding: 14px 15px;
                .iconfont {
                    color: #fff !important;
                    font-size: 29px;
                }
            }
            .ivu-menu {
                margin-top: 10px;
            }
            .ivu-menu-item {
                margin-bottom: 10px;
                background-color: transparent;
                font-size: 16px !important;
                padding: 0;
                padding-left: 82px !important;
            }
            .ivu-menu-item-active:not(.ivu-menu-submenu) {
                box-shadow: none !important;
                width: 200px !important;
                background-color: transparent !important;
                color: #444444 !important;
                font-size: 16px !important;
                margin-bottom: 10px;
            }
        }
        .ivu-menu-item-active {
            .ivu-menu-submenu-title {
                display: flex;
                align-items: center;
                color: #000;
                background-color: #fff;
                box-shadow:0px 5px 15px rgb(0 0 0 / 16%);
                width: 220px;
                font-size: 22px;
                .iconfont {
                    color: #444 !important;
                    font-size: 29px;
                }
            }
        }
        .el-steps {
            padding: 0 10px;
        }
        .el-step__title {
            font-size: 14px;
        }
        .el-step__icon {
            width: 20px;
            height: 20px;
        }
        .el-step.is-horizontal .el-step__line {
            top: 9px;
        }
        .el-step__head.is-success {
            color: #48c9ff;
            border-color: #48c9ff;
        }
        .el-step__title.is-success {
            color: #48c9ff;
        }
        .el-step__head.is-process .is-text{
            color: #fff;
            background-color: #48c9ff;
            border-color: #48c9ff;
        }
        .ivu-progress-bg {
            border-radius: 0;
        }
        .ivu-progress-active .ivu-progress-bg:before {
            border-radius: 0;
        }
        .ivu-progress-inner {
            display: block;
        }
        .ivu-progress {
            height: 5px;
        }
        .progress-warp {
            height: 5px;
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
        }
        .ivu-menu-submenu {
            .ivu-menu {
                color: #999;
            }
        }
        .menu-num {
            position: absolute;
            right: 5px;
        }
    }
</style>
