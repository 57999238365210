<template>
    <div id="server">
        <div class="server-center">
            <div class="title-view">
                <span class="title-text">居家服务</span>
            </div>
            <div class="center-top">
                <div class="top-btn">{{setSeleteVal}}</div>
                <Select v-model="model1" style="width:200px" @on-change="selectChange">
                    <Option v-for="item in cityList" :value="item.typeID" :key="item.typeID">{{item.typeName}}</Option>
                </Select>
                <Input @on-change="throttle(inputChange,500)" :style="{marginLeft:'10px'}" prefix="ios-search" v-model="serchValue" placeholder="订单号/服务商/服务名称" style="width: 200px" />
                <div style="width:145px;height:32px;marginLeft:10px">
                    <el-date-picker
                        v-model="timeValue"
                        type="month"
                        value-format="yyyy-MM"
                        placeholder='请选择月份'
                        @change="timeChange"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </div>
            </div>
            <div class="server-right">
                <Table stripe :columns="columns1" :data="dataList"></Table>
                <div class="center-footer">
                    <Page 
                        :total="total" 
                        show-elevator 
                        :current="current"
                        :page-size="pageSize"
                        @on-change="pageChange"
                    />
                </div>
            </div>
        </div>
        <div class="center-info">
            <div class="info-views">
                <div class="user-name">
                    <span class="name">{{baseInfo.userInfo.userName?baseInfo.userInfo.userName:'--'}}</span>
                    <span class="sex" v-if="baseInfo.userInfo.sex===0">男</span>
                    <span class="sex" v-else-if="baseInfo.userInfo.sex===1">女</span>
                    <span class="age">{{!baseInfo.userInfo.age||baseInfo.userInfo.age===null?'':baseInfo.userInfo.age+'岁'}}</span>
                </div>
                <div class="user-cerner">
                    <span class="center-title">地址：</span>
                    <p class="center-cotnet">{{baseInfo.userInfo.address}}</p>
                </div>
                <div class="user-cerner">
                    <span class="center-title">总服务时长：</span>
                    <p class="center-cotnet">{{timeAll.totalTime+'分钟'}}</p>
                </div>
                <div class="user-cerner">
                    <span class="center-title">当月服务时长：</span>
                    <p class="center-cotnet">{{timeAll.currentMonthTime+'分钟'}}</p>
                </div>
                <!-- <div class="info-list">
                    <div class="infoss">
                        <span class="info-labe">住址</span>
                        <p class="info-text">{{baseInfo.userInfo.address}}</p>
                    </div>
                    <div class="infos">
                        <span class="info-labe">总服务时长</span>
                        <p class="info-text">{{timeAll.totalTime+'小时'}}</p>
                    </div>
                    <div class="infos">
                        <span class="info-labe info-labes">当月服务时长</span>
                        <p class="info-text">{{timeAll.currentMonthTime+'小时'}}</p>
                    </div>
                </div> -->
            </div>
            <div class="img-list">
                <span class="list-title">服务类别与数量</span>
                <div class="list-center">
                    <div class="list-item" v-for="item in oaderData" :key="item.typeID">
                        <div class="icon-view">
                            <span class="iconfont" :class="item.iconName"></span>
                        </div>
                        <span class="title1">{{item.typeName}}</span>
                        <span class="title2">{{item.count}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import wodrHttp from '@/utils/wodrHttp'
    import axios from '@/utils/http'
    import {mapState} from 'vuex'
    export default {
        data () {
            return {
                radeoVal:'order',
                columns1: [
                    {
                        title: '订单号',
                        key: 'RelaOrders_ID',
                        minWidth:200,
                        align:'center',
                    },
                    {
                        title: '服务时间',
                        key: 'CreateTime',
                        minWidth:100,
                        ellipsis:true,
                        tooltip:true
                    },
                    {
                        title: '服务名称',
                        key: 'serveName',
                        minWidth:80,
                        ellipsis:true,
                        tooltip:true
                    },
                    {
                        title: '服务商',
                        key: 'FName',
                        minWidth:150,
                        ellipsis:true,
                        tooltip:true
                    },
                    {
                        title: '订单金额',
                        key: 'TotaAmou',
                        align:'center',
                        minWidth:50
                    }
                ],
                dataList: [],
                current:1,
                oaderData:[],
                total:0,
                pageSize:20,
                serchValue:'',
                startVal: 0,
                cityList: [
                    {
                        typeID: "0",
                        typeName: "全部",
                        state: 1
                    }
                ],
                model1: '0',
                num1:0,
                num2:0,
                num3:0,
                num4:0,
                num5:0,
                num6:0,
                num7:0,
                icons1:[],
                icons2:[],
                abnormaChuli:[],
                timer:null,
                users:{
                    name: "--",
                    belongAreaName: "--",
                    genderName:'',
                    age:'--'
                },
                timeAll:{
                    currentMonthTime: 0,
                    totalTime: 0
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
                },
                timeValue:'',
                year:'',
                month:''
            }
        },
        mounted () {
            // if(!this.vcCardNO) return
            this.getOrderData()
        },
        computed:{
            ...mapState(['vcCardNO','tag','baseInfo']),
            setSeleteVal(){
               const vals = this.cityList.find(item=>{return item.typeID===this.model1})
               return vals.typeName
            }
        },
        methods: {
            timeChange(e){
                if(this.timeValue&&this.timeValue!==null) {
                    this.year = this.timeValue.split('-')[0]
                    this.month = this.timeValue.split('-')[1]
                }else {
                    this.year = ''
                    this.month = ''
                }
                this.getOrderData()
            },
            getDataAll(){
                this.getOrderData()
            },
            pageChange(e){
                this.current = e
                this.dataList = []
                this.getDataAll()
            },
            inputChange(){
                this.current = 1
                this.dataList = []
                this.getDataAll()
            },
            radioChange(){
                this.model1 = '0'
                this.current = 1
                this.cityList = [...[{id: "0",name: "全部",state: 1}],...this.icons1] 
                this.getDataAll()
            },
            getOrderData(){
                axios.get(this.$api.SumServiceCountGroupByType + `?cardNo=${this.vcCardNO}&year=${this.year}&month=${this.month}`).then(res=>{
                    this.oaderData = []
                    this.dataList = []
                    this.cityList = [{
                        typeID: "0",
                        typeName: "全部",
                        state: 1
                    }]
                    this.cityList =[...this.cityList,...res.data.result.data] 
                    res.data.result.data.forEach(item=>{
                        this.oaderData.push({
                            count: item.count,
                            typeID: item.typeID,
                            typeName: item.typeName,
                            iconName : this.setOrderIcon(item.typeID)
                        })
                    })
                }).then(()=>{
                    let subTypeID = this.model1==='0'?'':this.model1
                    axios.get(this.$api.GetOrderPager + `?cardNo=${this.vcCardNO}&key=${this.serchValue}&pageInde=${this.current-1}&pageSize=20&subTypeID=${subTypeID}&year=${this.year}&month=${this.month}`).then(res=>{
                        res.data.result.data.forEach(item=>{
                            item.serveName = item.ServSubName + '-' + item.ServItemName
                        })
                        console.log(res.data.result.data)
                        this.total = res.data.result.total
                        this.dataList = res.data.result.data
                        this.$Spin.hide();
                    })
                })
                axios.get(this.$api.SumUserOrderLengthOfTime + `?cardNo=${this.vcCardNO}&year=${this.year}&month=${this.month}`).then(res=>{

                    this.timeAll = res.data.result
                })
            },
            selectChange(){
                this.current = 1
                this.getDataAll()
            },
            throttle(fn, delay) { //节流
                let that = this
                if(that.timer===null){
                    return function () {
                        that.timer = setTimeout(() => {
                            fn.apply(this, arguments);
                            that.timer = null
                        }, delay)
                    }()
                }
            },
            // getWorkSelete(){
            //     if(this.icons2.length===0) {
            //         axios.get('WorkOrder/GetFamilyBedWorkType').then(res=>{
            //             this.icons2 = res.data.result
            //             this.icons2.forEach(item=>{
            //                 item.iconName = this.setOrderIcon(item.name)
            //                 item.num = 0
            //             })
            //         }).then(res=>{
            //             this.getWorkData()
            //         })
            //     }else {
            //         this.getWorkData()
            //     }
            // },
            setOrderIcon(id){
                let result = ''
                switch(id) {
                    case 'A31FJI0NCA00000A':  //安全监护
                        result = 'iconfont icon-a-zu1391' 
                        break;
                    case 'A31FJI0QDVB0000A':  //实时监测
                        result = 'iconfont icon-a-zu1390'
                        break;
                    case 'A31FJI0HQDV0000A':  //健康管理
                        result = 'iconfont icon-a-zu1389'
                        break;
                    case 'A31FJI0GS2H0000A':  //生活照料
                        result = 'iconfont icon-a-zu1388'
                        break;
                    case 'A31FJI0JBL40000A':  //护理服务
                        result = 'iconfont icon-a-zu1387'
                        break;
                    case 'A31FJI0KIHG0000A':  //医疗保健
                        result = 'iconfont icon-a-zu1386'
                        break;
                    case 'A31FJI0LLBE0000A':  //精神慰藉
                        result = 'iconfont icon-a-zu1385'
                        break;
                    case '-2':  //公益服务
                        result = 'iconfont icon-a-zu1392'
                        break;    
                    case '-1':  //三助一护
                        result = 'iconfont icon-a-zu1394'
                        break;    
                    case 'A31FN179JA20000A':  //设备巡检
                        result = 'iconfont icon-shebei'
                        break;
                    case '燃气报警':  //燃气报警
                        result = 'iconfont icon-ranqiguan'
                        break;
                    case '电话回访':  //电话回访
                        result = 'iconfont icon-caozuo-dianhua'
                        break;
                    case '上门服务':  //上门服务
                        result = 'iconfont icon-shangmenfuwu'
                        break;
                    case '烟感报警':  //烟感报警
                        result = 'iconfont icon-yangan'
                        break;
                    case '紧急呼叫':  //紧急呼叫
                        result = 'iconfont icon-gaojing-jinji'
                        break;                                             
                    default:
                        result = 'iconfont icon-jingshenke'
                }
                return result 
            },
            setOrderColor(id){
                let result = ''
                switch(id) {
                    case '安全监护':  //安全监护
                        result = 'color1' 
                        break;
                    case '实时监测':  //实时监测
                        result = 'color2'
                        break;
                    case '健康管理':  //健康管理
                        result = 'color3'
                        break;
                    case '生活照料':  //生活照料
                        result = 'color4'
                        break;
                     case '护理服务':  //护理服务
                        result = 'color5'
                        break;
                    case '医疗保健':  //医疗保健
                        result = 'color6'
                        break;
                    case '精神慰藉':  //精神慰藉
                        result = 'color7'
                        break;
                    case '三助一护':  //三助一护
                        result = 'color8'
                        break; 
                    case '公益服务':  //公益服务
                        result = 'color9'
                        break;       
                    case '设备巡检':  //设备巡检
                        result = 'color2'
                        break;
                    case '燃气报警':  //燃气报警
                        result = 'color4'
                        break;
                    case '电话回访':  //电话回访
                        result = 'color3'
                        break;
                    case '上门服务':  //上门服务
                        result = 'color1'
                        break;
                    case '烟感报警':  //烟感报警
                        result = 'color5'
                        break;
                    case '紧急呼叫':  //紧急呼叫
                        result = 'color6'
                        break;        
                    default:
                        result = 'color7'
                }
                return result 
            },
            setOrderState(id){
                let result = ''
                switch(id) {
                    case 1:  //未支付
                        result = '未支付' 
                        break;
                    case 2:  //已支付
                        result = '已支付'
                        break;
                    case 3:  //已接单
                        result = '已接单'
                        break;
                    case 4:  //已完成
                        result = '已完成'
                        break;  
                    default: //已取消
                        result = '已取消'
                }
                return result
            },

        }
    }
</script>

<style lang="scss">
    #server {
        width: 100%;
        min-height: 700px;
        padding: 20px 20px 10px;
        display: flex;
        .el-date-editor {
            width: 100%;
            height: 100%;
            .el-input__inner,
            .el-input__icon {
                height: 100%;
                line-height: 32px;
            }
        }
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 30px;
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .server-center {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;
            margin-right: 20px !important;
            .center-top {
                height: 50px;
                background-color: #fff;
                padding-top: 9px;
                display: flex;
                border-radius: 5px;
                margin-bottom: 14px;
                .top-btn {
                    width: 100px;
                    height: 38px;
                    background: url('../assets/image/btn-img.png') no-repeat;
                    background-size: 100% 100%;
                    padding-top: 5px;
                    color: #fff;
                    transform: translateX(-6px);
                    margin-right: 30px;
                }
                .ivu-select-selected-value {
                    text-align: left;
                }
            }
            .server-right {
                flex: 1;
                overflow-y: auto;
                .label-header {
                    padding: 20px 0;
                    position: relative;
                }
                td.label-header {
                    padding: 0;
                    position: relative;
                }
                th.label-header::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    width: 1px;
                    height: 26px;
                    background-color: #ddd;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .ivu-table-header {
                    background-color: #fff;
                    box-shadow: 0px 10px 40px #D6DCEA;
                }
                .ivu-table th {
                    background-color: #fff;
                }
            }
            .center-footer {
                height: 110px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 20px;
            }
        }
        .center-info {
            width: 270px;
            .info-views {
                width: 100%;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);              
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                color: #fff;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 20px;
                .user-name {
                    display: flex;
                    align-items: center;
                    .user-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #ccc;
                        margin-right: 10px;
                    }
                    .name {
                        font-size: 18px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        text-align: left;
                        width: 170px;
                    }
                    .sex {
                        font-size: 12px;
                        flex-shrink: 0;
                        margin: 0 3px;
                    }
                    .age {
                        flex-shrink: 0;
                    }
                }
                .user-cerner {
                    display: flex;
                    margin-top: 5px;
                    font-size: 16px;
                    justify-content: space-between;
                    .center-title {
                        flex-shrink: 0;
                        font-weight: 700;
                    }
                    .center-cotnet {
                        text-align: right;
                    }
                }
            }
            .img-list {
                width:270px;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                .list-title {
                    text-align: left;
                    margin-bottom: 11px;
                    color: #333;
                }
                .list-center {
                    background-color: #fff;
                    padding: 20px;
                    padding-bottom: 0;
                    box-shadow: 0px 1px 10px #D6DCEA;
                    border-radius: 5px;
                    // height: 365px;
                    display: flex;
                    flex-wrap: wrap;
                    .list-item {
                        display: flex;
                        flex-direction: column;
                        width: 33.33%;
                        align-items: center;
                        margin-bottom: 15px;
                        .icon-view {
                            width: 63px;
                            height: 60px;
                            border: 1px solid #2176FF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            .iconfont {
                                font-size: 38px;
                                color: #2176FF;
                            }
                        }
                        .title1 {
                            font-size: 12px;
                            color: #2176FF;
                            margin-bottom: 4px;
                            margin-top: 4px;
                        }
                        .title2 {
                            font-size: 12px;
                            color: #2176FF;
                        }
                    }
                    .list-item:hover {
                        .icon-view {
                            background-color: #2176FF;
                            .iconfont {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
</style>