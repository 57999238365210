<template>
    <div class="homeAll">
        <ul class="ul-list">
            <li class="li-item" v-for="item in tabList" :key="item.value" :class="item.value===value?'active':''" @click="value = item.value">
                <span class="iconfont" :class="item.icon"></span>
                <span>{{item.name}}</span>
            </li>
            <!-- <li class="li-item">
                <span class="iconfont icon-a-zu4008"></span>
                <span>安全监护</span>
            </li>
            <li class="li-item">
                <span class="iconfont icon-a-zu4009"></span>
                <span>居家服务</span>
            </li> -->
        </ul>
        <Home class="bottomView" v-if="value===1"></Home>
        <HomeProject class="bottomView" v-if="value===2"></HomeProject>
        <Server class="bottomView" v-if="value===3"></Server>
    </div>
</template>

<script>
import Home from '@/views/Home.vue'
import HomeProject from '@/views/homeProject.vue'
import Server from '@/views/server.vue'
export default {
    name: "homeAll",
    computed:{
    },
    components:{
        Home,
        HomeProject,
        Server
    },
    data () {
        return {
            tabList:[
                {
                    icon:'icon-a-zu1351',
                    name:'健康监护',
                    value:1,
                },
                {
                    icon:'icon-a-zu4008',
                    name:'安全监护',
                    value:2,
                },
                {
                    icon:'icon-a-zu4009',
                    name:'居家服务',
                    value:3,
                }
            ],
            value:1
        }
    },
    mounted () { 
        
    },
    methods: {
       
    }
};
</script>

<style lang="scss">
    .homeAll {
        height: 100%;
        width: 100%;
        overflow: auto;
        background-color:#f5f6fa;
        display: flex;
        flex-direction: column;
        .ul-list {
            display: flex;
            margin: 20px 0 0;
            padding-left: 20px;
            .li-item {
                transition: all 1s;
                width: 180px;
                height: 55px;
                background: url('../assets/image/tabbg.png') no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                padding-left: 30px;
                font-size: 24px;
                color: #fff;
                cursor: pointer;
                .iconfont {
                   font-size: 24px;
                   margin-right: 10px;
                }
            }
            .active {
                background: url('../assets/image/tabbg-active.png') no-repeat;
                background-size: cover;
            }
        }
        .bottomView {
            flex: 1;
            overflow: hidden;
        }
    }
</style>
