var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"implementView"}},[_c('div',{staticClass:"lists"},[_c('Card',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"titles",attrs:{"slot":"title"},slot:"title"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/007.png"),"alt":""}}),_c('span',{staticClass:"title"},[_vm._v("实施记录")])]),_c('div',{staticClass:"table-warp"},[_c('serve-table',{attrs:{"tableData":_vm.tableData,"type":"0","maxHeight":"100%"}})],1)])],1),_c('div',{staticClass:"lists"},[_c('Card',{staticStyle:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"titles",attrs:{"slot":"title"},slot:"title"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/image/008.png"),"alt":""}}),_c('span',{staticClass:"title"},[_vm._v("设备列表")])]),_c('div',{staticClass:"table-warp"},[_c('Table',{attrs:{"border":"","columns":_vm.columns12,"data":_vm.data6,"height":411},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.name))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [(row.isSetup==1)?_c('span',[_vm._v("已安装")]):_c('div',[_vm._v("未安装")])]}},{key:"img",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Poptip',{attrs:{"placement":"right","width":"200"},on:{"on-popper-show":function($event){return _vm.onPopperShow(index)},"on-popper-hide":function($event){return _vm.onPopperHide(index)}}},[(row.imgShow)?_c('img',{staticClass:"btnImg",attrs:{"src":require("@/assets/image/show.png")}}):_c('img',{staticClass:"btnImg",attrs:{"src":require("@/assets/image/hide.png"),"alt":""}}),_c('div',{staticClass:"img-wrap",attrs:{"slot":"content"},slot:"content"},[(row.installUrl)?_c('img',{staticClass:"img-item",attrs:{"src":row.installUrl,"alt":""}}):_c('div',[_vm._v("暂无图片")])])])]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }