<template>
    <div id="home">
        <div class="center-left">
            <div class="center-top">
                <div class="right-abnormal">
                    <div class="title-view">
                        <span class="title-text">健康监护数据</span>
                        <span style="fontSize:16px;color:#666;marginLeft:20px;">{{users.monitorStartDate+'~'+users.monitorEndDate}}</span>
                    </div>
                    <div class="right-list">
                        <div class="healthy-item" :class="setStateXinlv">
                            <div class="img-view" :style="{'backgroundColor':'#FCE0E7'}">
                                <img class="item-img" src="@/assets/image/home/healthy2.png" alt="">
                            </div>
                            <div class="healthy-info">
                                <span class="info-title">平均心率</span>
                                <span class="info-num">{{infoData.xinlv}}</span>
                            </div>
                        </div>
                        <div class="healthy-item" :class="setStateHuxi">
                            <div class="img-view">
                                <img class="item-img" src="@/assets/image/home/healthy1.png" alt="">
                            </div>
                            <div class="healthy-info">
                                <span class="info-title">平均呼吸率</span>
                                <span class="info-num">{{infoData.huxi}}</span>
                            </div>
                        </div>
                        <div class="healthy-item" :class="setStateTidong" v-if="tabList.length>2">
                            <div class="img-view" :style="{'backgroundColor':'#CFDEFE'}">
                                <img class="item-img" src="@/assets/image/home/healthy3.png" alt="">
                            </div>
                            <div class="healthy-info">
                                <span class="info-title">体动总次数</span>
                                <span class="info-num">{{infoData.tidong}}</span>
                            </div>
                        </div>
                        <!-- <div class="healthy-item" :class="setStateTidong">
                            <div class="img-view" :style="{'backgroundColor':'#CFDEFE'}">
                                <img class="item-img" src="@/assets/image/home/healthy3.png" alt="">
                            </div>
                            <div class="healthy-info">
                                <span class="info-title">体动总次数</span>
                                <span class="info-num">{{infoData.tidong}}</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="center-top">
                <div class="title-view">
                    <span class="title-text">健康预警服务</span>
                </div>
                <div class="abnormalState">
                    <div class="abnormalState-item" @click="openTabel(item.valueList,item.label)" v-for="item in warningList" :key="item.value">
                        <div class="item-left">
                            <div class="img-view" :style="{backgroundColor:item.color?item.color:''}">
                                <img class="item-img" :src="item.img" alt="">
                            </div>
                            <span class="item-title1">{{item.label}}</span>
                        </div>
                        <span class="item-title2">{{`${stateData[item.value]}次`}}</span>
                    </div>
                    <!-- <div class="abnormalState-item">
                        <div class="item-left">
                            <div class="img-view">
                                <img class="item-img" src="@/assets/image/news/state1.png" alt="">
                            </div>
                            <span class="item-title1">心率过速</span>
                        </div>
                        <span class="item-title2">{{`${stateData.heartFast_WarningCount}次`}}</span>
                    </div>
                    <div class="abnormalState-item">
                        <div class="item-left">
                            <div class="img-view" style="background-color:#EEFFFA">
                                <img class="item-img" src="@/assets/image/news/state3.png" alt="">
                            </div>
                            <span class="item-title1">夜间离床超时</span>
                        </div>
                        <span class="item-title2">{{`${stateData.nightLeaveBed_WarningCount}次`}}</span>
                    </div>
                    <div class="abnormalState-item">
                        <div class="item-left">
                            <div class="img-view" style="background-color:#FFEABB">
                                <img class="item-img" src="@/assets/image/news/state2.png" alt="">
                            </div>
                            <span class="item-title1">呼吸过缓</span>
                        </div>
                        <span class="item-title2">{{`${stateData.breathSlow_WarningCount}次`}}</span>
                    </div>
                    <div class="abnormalState-item">
                        <div class="item-left">
                            <div class="img-view" style="background-color:#FFEABB">
                                <img class="item-img" src="@/assets/image/news/state2.png" alt="">
                            </div>
                            <span class="item-title1">呼吸过速</span>
                        </div>
                        <span class="item-title2">{{`${stateData.breathFast_WarningCount}次`}}</span>
                    </div>
                    <div class="abnormalState-item">
                        <div class="item-left">
                            <div class="img-view" style="background-color:#FFEABB">
                                <img class="item-img" src="@/assets/image/news/state2.png" alt="">
                            </div>
                            <span class="item-title1">呼吸暂停</span>
                        </div>
                        <span class="item-title2">{{`${stateData.breathStop_WarningCount}次`}}</span>
                    </div> -->
                </div>
            </div>
            <div class="healthy-view">
                <div class="title-view">
                    <span class="title-text">健康数据</span>
                </div>
                <div class="healthy-center">
                    <div class="healthy-left">
                        <div class="tabs-view">
                            <ul class="tab-ul">
                                <li v-for="item in tabList" :key="item.value" class="tab-item" :class="item.value===tabActive.value?'tab-item-active':''" @click="tabClick(item)">{{item.label}}</li>
                            </ul>
                        </div>
                        <div class="echart-view">
                            <div id="echarts5" class="echarts"  ref="chart"> </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="center-right">
            <div class="healthy-right">
                <div class="user-info">
                    <div class="user-name">
                        <span class="name">{{baseInfo.userInfo.userName?baseInfo.userInfo.userName:'--'}}</span>
                        <span class="sex" v-if="baseInfo.userInfo.sex===0">男</span>
                        <span class="sex" v-else-if="baseInfo.userInfo.sex===1">女</span>
                        <span class="age">{{!baseInfo.userInfo.age||baseInfo.userInfo.age===null?'':baseInfo.userInfo.age+'岁'}}</span>
                    </div>
                    <div class="user-cerner">
                        <span class="center-title">地址：</span>
                        <p class="center-cotnet">{{baseInfo.userInfo.address}}</p>
                    </div>
                     <div class="user-cerner" v-if="tabList.length>2">
                        <span class="center-title">在床总时长：</span>
                        <p class="center-cotnet">{{infoData.zaichuang}}</p>
                    </div>
                     <!-- <div class="user-cerner">
                        <span class="center-title">睡眠时长：</span>
                        <p class="center-cotnet">{{`${stateData.nightSleepTime}小时`}}</p>
                    </div> -->
                    <!-- <div class="user-cerner">
                        <span class="center-title">睡眠评分：</span>
                        <p class="center-cotnet">{{`${stateData.sleepScore}`}}</p>
                    </div> -->
                </div>
                <div class="account-box">
                    <el-calendar v-model="value"></el-calendar>
                </div>
                <div class="video-btn" @click="openVideo">
                    <span class="iconfont icon-chuang1"></span>
                    <span class="video-title">智能查房</span>
                    <span class="video-state" v-if="!videoState" :style="{color:'#35c196',fontSize:'18px'}">在床</span>
                    <span class="video-state" v-else :style="{color:'#BCBCBC',fontSize:'18px'}">离床</span>
                    <Icon @click.stop="getVidoList" type="ios-eye" size="40" color="#333"></Icon>
                </div>
                <div class="healthy-bottom" v-if="tabList.length>2">
                    <div class="healthy-bottom-titles">
                        <div class="frequency">
                            <div class="img-view">
                                <img class="title-img" src="@/assets/image/news/state2.png" alt="">
                            </div>
                            <span style="color:#666;">呼吸暂停</span>
                        </div>
                        <span class="frequency-num">{{`${stateData.breatheStopTotal}次`}}</span>
                    </div>
                    <div class="healthy-bottom-list" v-for="item in stopList" :key="item.value">
                        <div class="healthy-bottom-item" :class="stateData['breatheStop'+item.value]>0?'active-item':''">
                            <span>{{item.label}}</span>
                            <span>{{`${stateData['breatheStop'+item.value]}次`}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal footer-hide v-model="modal" draggable scrollable :title="mdalTitle" width="800">
            <Table border :columns="columns" :data="tableList" :height="300">
                <template slot-scope="{ row }" slot="endTime">
                    <span>{{row.endTime===null||!row.endTime?'--':row.endTime}}</span>
                </template>
                 <template slot-scope="{ row }" slot="readName">
                    <span>{{row.readName===null||!row.readName?'--':row.readName}}</span>
                </template>
                <template slot-scope="{ row }" slot="readType">
                    <span class="yichuli" v-if="row.readType==1">用户处理</span>
                    <span class="yichuli" v-else-if="row.readType==2">关注人干预处理</span>
                    <span class="yichuli" v-else-if="row.readType==3">平台干预处理</span>
                    <span class="weichuli" v-else>未处理</span>
                </template>
                <template slot-scope="{ row }" slot="waringType">
                    <span v-if="row.waringType==1">高心率</span>
                    <span v-else-if="row.waringType==2">呼吸过缓</span>
                    <span v-else-if="row.waringType==3">呼吸过速</span>
                    <span v-else-if="row.waringType==4">呼吸暂停</span>
                    <span v-else-if="row.waringType==6">离床超时</span>
                    <span v-else>低心率</span>
                </template>
            </Table>
        </Modal>
        <Modal footer-hide v-model="videoModal" draggable scrollable title="查房记录" width="600">
            <Table border :columns="videoColumns" :data="videoList" :height="300">
                <template slot-scope="{ row }" slot="endTime">
                    <span>{{row.endTime===null||!row.endTime?'--':row.endTime}}</span>
                </template>
                 <template slot-scope="{ row }" slot="readName">
                    <span>{{row.readName===null||!row.readName?'--':row.readName}}</span>
                </template>
                <template slot-scope="{ row }" slot="readType">
                    <span class="yichuli" v-if="row.readType==1">用户处理</span>
                    <span class="yichuli" v-else-if="row.readType==2">关注人干预处理</span>
                    <span class="yichuli" v-else-if="row.readType==3">平台干预处理</span>
                    <span class="weichuli" v-else>未处理</span>
                </template>
                <template slot-scope="{ row }" slot="waringType">
                    <span v-if="row.waringType==1">高心率</span>
                    <span v-else-if="row.waringType==2">呼吸过缓</span>
                    <span v-else-if="row.waringType==3">呼吸过速</span>
                    <span v-else-if="row.waringType==4">呼吸暂停</span>
                    <span v-else-if="row.waringType==6">离床超时</span>
                    <span v-else>低心率</span>
                </template>
            </Table>
        </Modal>
    </div>
</template>

<script>
    var echarts = require('echarts/lib/echarts');
    let myChart
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    import countTo from 'vue-count-to'
    export default {
        name: "Home",
        components: {
            countTo
        },
        data () {
            return {
                value:'',
                tabList:[],
                stopList:[{
                    label:'0~10秒',
                    value:'0_10'
                },{
                    label:'10~20秒',
                    value:'10_20'
                },{
                    label:'20~30秒',
                    value:'20_30'
                },{
                    label:'30~40秒',
                    value:'30_40'
                },{
                    label:'40~50秒',
                    value:'40_50'
                },{
                    label:'50~60秒',
                    value:'50_60'
                }],
                tabActive:{},
                warningList:[
                    {
                        label:'心率过缓',
                        value:'heartSlow_WarningCount',
                        valueList:'heartSlow_WarningDetail',
                        img:require('@/assets/image/news/state1.png'),
                    },
                    {
                        label:'心率过速',
                        value:'heartFast_WarningCount',
                        valueList:'heartFast_WarningDetail',
                        img:require('@/assets/image/news/state1.png'),
                    },
                    {
                        label:'夜间离床超时',
                        value:'nightLeaveBed_WarningCount',
                        valueList:'nightLeaveBed_WarningDetail',
                        img:require('@/assets/image/news/state3.png'),
                        color:'#EEFFFA'
                    },
                    {
                        label:'呼吸过缓',
                        value:'breathSlow_WarningCount',
                        valueList:'breathSlow_WarningDetail',
                        img:require('@/assets/image/news/state2.png'),
                        color:'#FFEABB'
                    },
                    {
                        label:'呼吸过速',
                        value:'breathFast_WarningCount',
                        valueList:'breathFast_WarningDetail',
                        img:require('@/assets/image/news/state2.png'),
                        color:'#FFEABB'
                    },
                    {
                        label:'呼吸暂停异常',
                        value:'breathStop_WarningCount',
                        valueList:'breathStop_WarningDetail',
                        img:require('@/assets/image/news/state2.png'),
                        color:'#FFEABB'
                    },
                ],
                echartDatas:{},
                echartMonitor:{},
                infoData:{
                    xinlv:0,
                    huxi:0,
                    zaichuang:'--',
                    guohuan:0,
                    zanting:0,
                    caoshi:0,
                    tidong:0
                },
                users:{
                    name: "--",
                    belongAreaName: "--",
                    genderName:'',
                    age:'--',
                    tenantsName:'',
                    monitorStartDate:'',
                    monitorEndDate:''
                },
                dateTime:this.setTime(),
                stateData:{
                    nightSleepTime:0, //夜间睡眠总时长
                    heartFast_WarningCount:0, /*心率过速报警次数*/
                    heartSlow_WarningCount:0, /*心率过缓报警次数*/
                    breathFast_WarningCount:0, /*呼吸过速报警次数*/
                    breathSlow_WarningCount:0, /*呼吸过缓报警次数*/
                    breathStop_WarningCount:0, /*呼吸暂停报警次数*/
                    nightLeaveBed_WarningCount:0, /*夜间离床报警次数*/
                    breatheStop0_10: 0,  /*呼吸暂停0-10秒*/
                    breatheStop10_20: 0, /*呼吸暂停10-20秒*/
                    breatheStop20_30: 0,
                    breatheStop30_40: 0,
                    breatheStop40_50: 0,
                    breatheStop50_60: 0,
                    breatheStopTotal: 0, /*呼吸暂停0-60秒总次数*/
                    sleepScore:''
                },
                columns:[
                    {
                        title: '开始时间',
                        key: 'startTime',
                        align: "center",
                        width:170
                    },
                    {
                        title: '结束时间',
                        slot: 'endTime',
                        align: "center",
                        width:170
                    },
                    {
                        title: '异常类型',
                        slot: 'waringType',
                        align: "center"
                    },
                    {
                        title: '干预人名称',
                        slot: 'readName',
                        align: "center"
                    },
                    {
                        title: '状态',
                        slot: 'readType',
                        align: "center"
                    }
                ],
                modal:false,
                tableList:[],
                mdalTitle:'',
                nightLeaveBed_WarningDetail: [], /*夜间离床报警明细*/
                videoModal:false,
                videoList:[],
                videoColumns:[{
                    title: '查房时间',
                    key: 'createTime',
                    align: "center"
                },{
                    title: '状态',
                    key: 'remark',
                    align: "center"
                }],
                heart:0,
                breath:0
            }
        },
        created () {
            
        },
        mounted(){
            myChart = echarts.init(this.$refs.chart);
            if(!this.vcCardNO) return
            this.getchartData()
            axios.get('MatressReport/GetCurrentMatressData?cardNo=' + `${this.vcCardNO}`).then(res=>{
                this.$store.commit('setVideoState',res.data.result.leaveBed)
                this.heart = res.data.result.heart
                this.breath = res.data.result.breath
            })
            if(!this.setinter) {
                let inVal = setInterval(()=>{
                    axios.get('MatressReport/GetCurrentMatressData?cardNo=' + `${this.vcCardNO}`).then(res=>{
                        this.$store.commit('setVideoState',res.data.result.leaveBed)
                        this.heart = res.data.result.heart
                        this.breath = res.data.result.breath
                    })
                },30000)
                this.$store.commit('setSetinter',inVal)
            }
        },
        destroyed () {
            console.log('1212')
            clearInterval(this.setinter)
        },
        computed:{
            ...mapState(['userId','type','vcCardNO','phone','baseInfo','setinter','videoState']),
            setStateXinlv(){
                if(this.infoData.xinlv<60) {
                    return 'state4'
                }else if (this.infoData.xinlv>100) {
                    return 'state1'
                }else {
                    return 'state2'
                }
            },
            setStateHuxi(){
                if(this.infoData.huxi<12) {
                    return 'state4'
                }else if (this.infoData.huxi>20) {
                    return 'state1'
                }else {
                    return 'state2'
                }
            },
            setStateTidong(){
                if(this.infoData.tidong<10) {
                    return 'state4'
                }else if (this.infoData.tidong>800) {
                    return 'state1'
                }else {
                    return 'state2'
                }
            }
        },
        methods: {
            openTabel(key,label){
                this.mdalTitle = label
                this.tableList = this.stateData[key]
                this.modal = true
            },
            getchartData(){
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    // type: 'ios-sunny-outline',
                                    // type: 'md-sunny',
                                    // type: 'md-resize',
                                    type: 'md-nuclear',
                                    size: 22
                                }
                            }),
                            h('div', '加载中...')
                        ])
                    }
                });
                axios.get(this.$api.GetUserFamilyBedDevice + `?cardNo=${this.vcCardNO}`).then(res=>{
                    let datas = res.data.result.filter(item=>{return item.isSetup})
                    return datas
                }).then((req)=>{
                    const item = req.find(item=>{return item.equipmentID === '725abed0-83f6-4049-bed8-4cb48cda7bfc'||item.equipmentID === 'C5718444-3A0F-459E-AA38-6265C0E77D42'})
                    if(item&&item.equipmentID==='725abed0-83f6-4049-bed8-4cb48cda7bfc') {  //第三方
                        this.tabList = [
                            {
                                label:'心率分布',
                                value:4
                            },
                            {
                                label:'呼吸分布',
                                value:5
                            }
                        ]
                        this.tabActive = {
                            label:'心率分布',
                            value:4
                        }
                        axios.get('/MatressReport/GetThirdDaliyReport' + `?date=${this.dateTime}&cardNo=${this.vcCardNO}`).then(res=>{
                            this.setEchartData(res.data.data)
                            this.echartDatas = res.data.data
                            this.users = this.echartDatas.baseInfo
                            this.infoData.xinlv = this.echartDatas.healthSituation.avgHeart
                            this.infoData.huxi = this.echartDatas.healthSituation.avgBreath
                        })
                    }else {
                        this.tabList = [
                            {
                                label:'状态分布',
                                value:1
                            },
                            {
                                label:'异常分布',
                                value:2
                            },
                            {
                                label:'体动分布',
                                value:3
                            },
                            {
                                label:'心率分布',
                                value:4
                            },
                            {
                                label:'呼吸分布',
                                value:5
                            }
                        ]
                        this.tabActive = {
                            label:'速率分布',
                            value:1
                        }
                        axios.get(this.$api.GetDaliyReport+`?date=${this.dateTime}&phone=${this.phone}` ).then(res=>{
                            if(res.data.code==200){
                                this.echartDatas = res.data.data
                                this.infoData.xinlv = this.echartDatas.healthSituation.avgHeart
                                this.infoData.huxi = this.echartDatas.healthSituation.avgBreath
                                this.infoData.zaichuang = this.echartDatas.healthSituation.onBedTime
                                this.infoData.tidong = this.echartDatas.healthSituation.bodyMove
                                this.infoData.guohuan = this.echartDatas.chartData.warningDetails.heartSlow.length
                                this.infoData.zanting = this.echartDatas.chartData.warningDetails.apneaCount.length
                                this.infoData.caoshi = this.echartDatas.chartData.warningDetails.leaveBed.length
                                this.users = this.echartDatas.baseInfo
                                this.setEchartData(res.data.data)
                            }else {
                                this.myChart.setOption({},true)
                                this.myChart.setOption({
                                    xAxis: {
                                        type: 'category',
                                        data: ['暂无数据']
                                    },
                                    yAxis: {
                                        type: 'value'
                                    },
                                    series: [{
                                        data: [],
                                        type: 'line',
                                    }]
                                },true)
                                this.$Message.error(res.data.message);
                            }
                        })
                    }
                })
                axios.get(this.$api.GetMatressReport + `?day=${this.dateTime}&phone=${this.phone}`).then(res=>{
                    this.stateData = res.data.result
                })
            },
            tabClick(item){
                this.tabActive = item
                this.setEchartData(this.echartDatas)
            },
            setEchartData(data){
                myChart.setOption({},true)
                myChart.clear()
                switch (this.tabActive.value) {
                    case 1:
                        this.echart1(data)
                        break;
                    case 2:
                        this.echart2(data)
                        break;
                    case 3:
                        this.echart3(data)
                        break;
                    case 4:
                        this.echart4(data)
                        break;
                    case 5:
                        this.echart5(data)
                        break;
                }
            },
            echart5(data){
                console.log(data)
                var chart5Xdata=[],chart5Ydata=[]
                data.chartData.breathDetails.forEach(item=>{
                    chart5Xdata.push(item[0])
                    chart5Ydata.push(item[1]==-1?null:item[1])
                })
                myChart.setOption({
                    dataZoom: [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            xAxisIndex: [0],
                            filterMode: 'filter'
                        }
                    ],
                    color:['#3180ff'],
                    tooltip: {
                        trigger: 'item',
                        formatter: function(params) {
                            var a = params.name + ' ' + params.value +'bpm'
                            return a
                        }
                    },
                    grid:{
                        left:'5%',
                        right:'5%',
                        bottom:80,
                        top:50
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data:chart5Xdata,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            type: 'line',
                            data: chart5Ydata,
                            markPoint: {
                                symbol:'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAAyCAYAAADcD8w1AAAEWUlEQVR4Xu2bz2sTQRTHZ/YX/ZH6IzaHFAvtUS96UaREmqvHghRavVUDeu7R+h+oeNRDwUPAUmgv0YMeAr3oUcGe0yDaQht/hKShZOLKCzthspndbtLsM2snEDbdJvPefObbt5u+71CiHigEKEoUFYScBPRJPhtl9HYvyXcDy+u93YzRS46D8hkvwIHAB4Ekvsfr9aDAwMhDBOv1uiMPP9BuqPxn9xEGDbJgGBDCiiEDys/B8Vjgx5UDESqtVCqzIyMjK5TSK4SQc2HNasDH3bFtu1gqlZYTicQnB7IIHdLvKCd+oPnvNFDs0dHRY8uyHg04BNT0Dg8P74+Ojr5ywP5xgrsV3jwtA90GmBCilUql2Xg8/g51FhEJtre3dz2ZTHJlA2y3un1BA2x4gpo1xth7XddvRmTuqGkyxnKmad4mhABkDrpD1W5Fd6iZEKLbtv39FNdk34WDeq1p2iVCSEMALZYRqaK5krmadQd0BVUmEQtGKY05oEXYbaqW3Re3SgZAJoQYtm2XIzZ31HQppWcIIUxQdUet9gINgKE+w9G0bfsXauYRC0YphVvdukTVrVs92ZeStrLhgP4ZsbmjpkspPe8BulU+jgNtOKB/oGYesWCU0rgDWiwfHHLzds8LdPMiCPXZqdEKtM/iO6ABMgcNF8WuQUONLkVMZKjpUkovuBStQIexAgp0GFQlYyrQCjQSAaQwStEKNBIBpDBK0Qo0EgGkMErRCjQSAaQwStEKNBIBpDBK0Qo0EgGkMErREQNtNBqNz5qmTSLlHakw9Xp927Is8Lz09I9/3phttrJqtVp2aGjoVqQIICVbrVZfx2Kxh722sjjoZhc8n89fTafTeaTcIxMGbBhra2vphYWFgqQ522ai8fN18L6hCX3DQqFwZ2pq6nlkKIScKEAuFosr09PTWUkbK7Cvo+m5Exu0ADuXy11JpVIZy7Imh4eHb4Q8l4EcvlarfaxWq9ubm5svMpnMjlCbZQaaln1X5r0TDY5iN5x3xbm5pmnnFZ6hgslms5cXFxffiEEYY99M00yFGrjdHQrguJkRGrAcrtj9lhodA5kcHWVz6DLQMNfQXf+MsQ+6rl/kYHd3d59NTEw8/YegAbb45JCPNTlyYG22XQc0LyW8rIhqDh0yJLa/v788Pj6+zMGurq5eW1pa+ooM2q1qgMrNjYFtu6IyuTVMND1yyGLZQFEzBNnY2Jicm5sD0zdhjH0xTXM2ZMh8eNFcLqqWlxHxXGAjuhdsN3hR/UjzJaRer781DCN1cHDwIJFIwBUf6yE6j/hrUcGebn8/JYqbhNzlxH0BRCkbnGa5XL47Njb2cn19PTk/P/8bi7Jry4QMOqQiVXOQP3kv4O7PosHe2to6OzMz88QwjHtIkN07rGTb3jwB8xyDAJJ9qQmySEgc0MP0fUOnewZ+ixJkwdCJ9DGg3zbkvm1R9sv3fwfsnnsgqDJgpw1UH0Xe3VAKdHe8en73X+LTJFGmlOh8AAAAAElFTkSuQmCC',
                                data: [
                                    {
                                        type: 'max', 
                                        name: '最大值',
                                        label:{
                                            color:'#F85F3C',
                                            fontWeight:700
                                        }
                                    },
                                    {
                                        type: 'min', 
                                        name: '最小值',
                                        label:{
                                            color:'#2176FF',
                                            fontWeight:700
                                        }
                                    }
                                ]
                            },
                        }
                    ]
                });
            },
            echart1(data){
                var mycharArr = data.chartData.onBedStatusDetails.map(item=>{
                    return setChar1(item)
                })
                // data.chartData.xData.map((item,index)=>{
                //     if(index%5==0){
                //         xData2.push(item)
                //     }
                // })
                function setChar1(item) {
                    if(item==1) {
                        return '离床'
                    }else if(item==2) {
                        return '离线'
                    }else {
                        return '在床'
                    }
                }
                myChart.setOption({
                    grid:{
                        left:'50px',
                        right:'5%',
                        bottom:80,
                        top:30
                    },
                    color:['#2176FF'],
                    tooltip: {
                        trigger: 'axis',
                        formatter: `{b}:{c}`
                    },
                    dataZoom: [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            xAxisIndex: [0],
                            filterMode: 'filter'
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        data: data.chartData.xData,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: ['离床', '离线', '在床']
                    },
                    series: [{
                        data: mycharArr,
                        type: 'line',
                        smooth: true
                    }]
                });
            },
            echart2(data){
                myChart.setOption({
                    xAxis: {
                        type: 'category',
                        data:data.chartData.xData,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    grid:{
                        left:'5%',
                        right:'5%',
                        bottom:80,
                        top:50
                    },
                    legend: {
                        data: ['离床超时', '心率过速', '心率过缓', '呼吸暂停','呼吸过速','呼吸过缓'],
                        top:15
                    },
                    color:['#2176FF','#9985E7','#FFB000','#F85F3C','#FFCD33','#35C196'],
                    dataZoom: [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            xAxisIndex: [0],
                            filterMode: 'filter'
                        }
                    ],
                    tooltip: {
                        formatter:function(value){
                            return value.data[0]+' '+ value.seriesName
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: '0',
                        max: '6',
                        show:false,
                        splitLine: {
                            show: true
                        }
                    },
                    series: [
                        {
                            name: '离床超时',
                            type: 'scatter',
                            itemStyle: {
                                normal: {
                                    opacity: 0.8
                                }
                            },
                            symbolSize: function (val) {
                                return  8;
                            },
                            data: data.chartData.warningDetails.leaveBed
                        },
                        {
                            name: '心率过速',

                            type: 'scatter',
                            data: data.chartData.warningDetails.heartFast
                        },
                        {
                            name: '心率过缓',
                            type: 'scatter',
                            itemStyle: {
                                normal: {
                                    opacity: 0.8
                                }
                            },
                            symbolSize: function (val) {
                                return  8;
                            },
                            data: data.chartData.warningDetails.heartSlow
                        },
                        {
                            name: '呼吸暂停',
                            type: 'scatter',
                            itemStyle: {
                                normal: {
                                    opacity: 0.8
                                }
                            },
                            symbolSize: function (val) {
                                return  8;
                            },
                            data: data.chartData.warningDetails.apneaCount
                        },
                        {
                            name: '呼吸过速',
                            type: 'scatter',
                            itemStyle: {
                                normal: {
                                    opacity: 0.8
                                }
                            },
                            symbolSize: function (val) {
                                return  8;
                            },
                            data: data.chartData.warningDetails.breathFast
                        },
                        {
                            name: '呼吸过缓',
                            type: 'scatter',
                            itemStyle: {
                                normal: {
                                    opacity: 0.8
                                }
                            },
                            symbolSize: function (val) {
                                return  8;
                            },
                            data: data.chartData.warningDetails.breathSlow
                        },
                    ]
                });
            },
            echart3(data){
                myChart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        formatter:function(value){
                            return value[0].data[0]+'   '+ value[0].data[1]+'次'
                        }
                    },
                    grid:{
                        left:'5%',
                        right:'5%',
                        bottom:80,
                        top:50
                    },                    
                    color:['#2176FF'],
                    dataZoom: [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            xAxisIndex: [0],
                            filterMode: 'filter'
                        }
                    ],
                    xAxis: {
                        type: 'category',
                        data: data.chartData.xData,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: data.chartData.bodyMoveDetails,
                        type: 'bar'
                    }]
                });
            },
            echart4(data){
                console.log(data)
                var chart4Xdata=[],chart4Ydata=[]
                let xData2 = []
                // data.chartData.xData.map((item,index)=>{
                //     if(index%5==0){
                //         xData2.push(item)
                //     }
                // })
                data.chartData.heartDetails.forEach(item=>{
                    chart4Xdata.push(item[0])
                    chart4Ydata.push(item[1]==-1?null:item[1])
                })
                console.log(xData2)
                myChart.setOption({
                    color:['#2176FF'],
                    dataZoom: [
                        {
                            id: 'dataZoomX',
                            type: 'slider',
                            xAxisIndex: [0],
                            filterMode: 'filter'
                        }
                    ],
                    grid:{
                        left:'5%',
                        right:'5%',
                        bottom:80,
                        top:50
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function(params) {
                            var a = params.name + ' ' + params.value +'bpm'
                            return a
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: chart4Xdata,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            type: 'line',
                            data: chart4Ydata,
                            markPoint: {
                                symbol:'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAAAyCAYAAADcD8w1AAAEWUlEQVR4Xu2bz2sTQRTHZ/YX/ZH6IzaHFAvtUS96UaREmqvHghRavVUDeu7R+h+oeNRDwUPAUmgv0YMeAr3oUcGe0yDaQht/hKShZOLKCzthspndbtLsM2snEDbdJvPefObbt5u+71CiHigEKEoUFYScBPRJPhtl9HYvyXcDy+u93YzRS46D8hkvwIHAB4Ekvsfr9aDAwMhDBOv1uiMPP9BuqPxn9xEGDbJgGBDCiiEDys/B8Vjgx5UDESqtVCqzIyMjK5TSK4SQc2HNasDH3bFtu1gqlZYTicQnB7IIHdLvKCd+oPnvNFDs0dHRY8uyHg04BNT0Dg8P74+Ojr5ywP5xgrsV3jwtA90GmBCilUql2Xg8/g51FhEJtre3dz2ZTHJlA2y3un1BA2x4gpo1xth7XddvRmTuqGkyxnKmad4mhABkDrpD1W5Fd6iZEKLbtv39FNdk34WDeq1p2iVCSEMALZYRqaK5krmadQd0BVUmEQtGKY05oEXYbaqW3Re3SgZAJoQYtm2XIzZ31HQppWcIIUxQdUet9gINgKE+w9G0bfsXauYRC0YphVvdukTVrVs92ZeStrLhgP4ZsbmjpkspPe8BulU+jgNtOKB/oGYesWCU0rgDWiwfHHLzds8LdPMiCPXZqdEKtM/iO6ABMgcNF8WuQUONLkVMZKjpUkovuBStQIexAgp0GFQlYyrQCjQSAaQwStEKNBIBpDBK0Qo0EgGkMErRCjQSAaQwStEKNBIBpDBK0Qo0EgGkMErREQNtNBqNz5qmTSLlHakw9Xp927Is8Lz09I9/3phttrJqtVp2aGjoVqQIICVbrVZfx2Kxh722sjjoZhc8n89fTafTeaTcIxMGbBhra2vphYWFgqQ522ai8fN18L6hCX3DQqFwZ2pq6nlkKIScKEAuFosr09PTWUkbK7Cvo+m5Exu0ADuXy11JpVIZy7Imh4eHb4Q8l4EcvlarfaxWq9ubm5svMpnMjlCbZQaaln1X5r0TDY5iN5x3xbm5pmnnFZ6hgslms5cXFxffiEEYY99M00yFGrjdHQrguJkRGrAcrtj9lhodA5kcHWVz6DLQMNfQXf+MsQ+6rl/kYHd3d59NTEw8/YegAbb45JCPNTlyYG22XQc0LyW8rIhqDh0yJLa/v788Pj6+zMGurq5eW1pa+ooM2q1qgMrNjYFtu6IyuTVMND1yyGLZQFEzBNnY2Jicm5sD0zdhjH0xTXM2ZMh8eNFcLqqWlxHxXGAjuhdsN3hR/UjzJaRer781DCN1cHDwIJFIwBUf6yE6j/hrUcGebn8/JYqbhNzlxH0BRCkbnGa5XL47Njb2cn19PTk/P/8bi7Jry4QMOqQiVXOQP3kv4O7PosHe2to6OzMz88QwjHtIkN07rGTb3jwB8xyDAJJ9qQmySEgc0MP0fUOnewZ+ixJkwdCJ9DGg3zbkvm1R9sv3fwfsnnsgqDJgpw1UH0Xe3VAKdHe8en73X+LTJFGmlOh8AAAAAElFTkSuQmCC',
                                data: [
                                    {
                                        type: 'max', 
                                        name: '最大值',
                                        symbolOffset:[0,-12],
                                        label:{
                                            color:'#ff4946',
                                            fontWeight:700
                                        }
                                    },
                                    {
                                        type: 'min', 
                                        name: '最小值',
                                        symbolOffset:[0,12],
                                        label:{
                                            color:'#33a6f9',
                                            fontWeight:700
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                });
            },
            setTime(){
                let time = new Date()
                let year = time.getFullYear()
                let mont = time.getMonth()+1
                let day = time.getDate()
                return year+'-'+mont+'-'+day
            },
            openVideo(){
                
                // this.videoModal = true
                const stateName = this.videoState?'2':'3'
                const name = this.videoState?'离床':'在床'
                axios.post(this.$api.SubmitRoomCheck,{
                    cardNo:this.vcCardNO,
                    status:stateName,
                    remark:this.videoState?`${name}`:`${name}（心率：${this.heart}，呼吸率：${this.breath}）`
                }).then(res=>{
                    this.$Message.info(res.data.message);
                })
            },
            getVidoList(){
                if(this.videoModal) return
                axios.get('RoomCheck/GetByCardNo?cardNo=' + `${this.vcCardNO}&day=${this.dateTime}`).then(res=>{
                    this.videoList = res.data.result
                    this.videoModal = true
                })
            }
        },
        watch: {
            value(val,avl){
                console.log(val.getTime())
                const times = new Date()
                const endTime = times.getFullYear()+'-'+(times.getMonth()+1)+'-'+times.getDate()
                if(new Date(endTime).getTime()<val.getTime()) {
                    this.value = new Date(endTime)
                    this.dateTime = endTime
                }else {
                    let starTime = val.getFullYear()+'-'+(val.getMonth()+1)+'-'+val.getDate()
                    this.dateTime = starTime
                    this.getchartData()
                }
         
            }
        }
    };
</script>

<style lang="scss">
    #home {
        width: 100%;
        padding: 20px;
        display: flex;
        overflow-y: auto;
        .video-btn {
            height: 60px;
            box-shadow: 0px 10px 40px #D6DCEA;
            border-radius: 5px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .video-title {
                color: #2176FF;
                font-weight: bold;
                font-size: 22px;
                margin: 0 0px 0 10px;
            }
            .video-state {
                margin: 0 10px;
            }
            .icon-a-zu39831 {
                color: #2176FF;
            }
            .iconfont {
                font-size: 30px;
            }
        }
        .abnormalState {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .abnormalState-item {
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 15px #D6DCEA;
                background-color: #fff;
                padding: 15px;
                width: 30%;
                justify-content: space-between;
                border-radius: 5px;
                margin-right: 10px;
                margin-bottom: 10px;
                .item-left {
                    display: flex;
                    align-items: center;
                    .img-view {
                        width: 30px;
                        height: 30px;
                        background-color: #FFD5D5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin-right: 5px;
                        .item-img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .item-title1 {
                        color: #666;
                        font-size: 17px;
                    }
                }
                .item-title2 {
                    font-size: 16px;
                    color: #F85F3C;
                }
            }
        }
        .chuandian-img {
            width: 100%;
            height: 180px;
            margin-bottom: 7px;
        }
        .title-view {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 20px;
        }
        .title-views {
            align-items: center;
            .alarm_center {
                display: flex;
                flex: 1;
                font-size: 16px;
                .item {
                    margin-left: 30px;
                    display: flex;
                    align-items: center;
                    span:nth-of-type(2) {
                        color: #18d777;
                        font-size: 30px;
                        margin: 0 10px;
                    }
                }
            }
        }
        .healthy-view {
            margin-top: 30px;
            .healthy-center {
                width: 100%;
                display: flex;
                .healthy-left {
                    flex: 1;
                }
            }
        }
        .healthy-right {
            display: flex;
            flex-direction: column;
            .account-box {
                background: #FFFFFF;            
                border-radius: 5px;
                box-shadow: 0px 1px 4px #E5E9F2;
                overflow: hidden;
            }
            .healthy-bottom {
                .healthy-bottom-titles {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 20px;
                    background-color: #fff;
                    box-shadow: 0px 4px 15px #D6DCEA;
                    border-radius: 5px;
                    margin-top: 20px;
                    .frequency {
                        display: flex;
                        align-items: center;
                        .img-view {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: #FFEABB;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            .title-img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                    .frequency-num {
                        color: #F85F3C;
                        font-size: 23px;
                        font-weight: 700;
                    }
                }
                .healthy-bottom-list {
                    display: flex;
                    flex-direction: column;
                    .healthy-bottom-item {
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;
                        height: 38px;
                        background-color: #fff;
                        margin-top: 10px;
                        color: #999999;
                    }
                    .active-item {
                        background-color: #FFEBE6;
                        color: #F85F3C;
                    }
                }
            }
        }
        .oder-view-imgs {
            width: 100%;
            display: flex;
            .oder-view-left {
                flex: 1;
            }
        }
        .oder-view {
            // display: flex;
            margin-top: 30px;
            .oder-center {
                flex: 1;
                .title-view {
                    margin-bottom: 0;
                }
            }
            .oder-right {
                width: 350px;
                height: 850px;
                background: url('../assets/image/right-bg.png') no-repeat;
                background-size: 100% 100%;
                // margin-top: 40px;
                margin-left: 20px;
                border-radius: 5px;
            }
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .center-left {
            // overflow-y: auto;
            flex: 1;
            // flex-shrink: 0;
            .center-top {
                // display: flex;
                // margin-bottom: 23px;
            }
            .left-abnormal {
                width: 50%;
                overflow: hidden;
                .abnormal-list {
                    display: flex;
                    .abnormal-item {
                        width: 33.33%;
                        height: 90px;
                        background-color: #F85F3C;
                        border-radius: 5px;
                        box-shadow: 0px 10px 40px #D6DCEA;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 3%;
                        .item-img {
                            width: 48px;
                            height: 50px;
                        }
                        .item-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .item-title {
                                font-size: 17px;
                                color: #fff;
                            }
                            .nums {
                                margin-top: 5px;
                                color: #fff;
                                font-size: 17px;
                                .num {
                                    font-size: 29px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
            .right-abnormal {
                overflow: hidden;
                .right-list {
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    .healthy-item {
                        display: flex;
                        align-items: center;
                        width: 215px;
                        justify-content: center;
                        margin-right: 30px;
                        height: 90px;
                        background-color: #fff;
                        box-shadow: 0px 4px 15px #D6DCEA;
                        margin-bottom: 23px;
                        border-radius: 5px;
                        .img-view {
                            width: 50px;
                            height: 50px;
                            background-color: #D8FFFD;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .healthy-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            margin-left: 5%;
                            .info-title {
                                font-size: 17px;
                                color: #666;
                                margin-bottom: 4px;
                            }
                            .info-num {
                                font-size: 28px;
                                color: #31394D;
                                font-weight: 700;
                            }
                            .info-time {
                                font-size: 12px;
                                color: #31394D;
                                margin-top: 10px;
                            }
                        }
                    }
                    .state1 {
                        background: url('../assets/image/news/healthy1.png') no-repeat;
                        background-size: 45px 45px;
                    }
                    .state2 {
                        background: url('../assets/image/news/healthy2.png') no-repeat;
                        background-size: 45px 45px;
                    }
                    .state3 {
                        background: url('../assets/image/news/healthy3.png') no-repeat;
                        background-size: 45px 45px;
                    }
                    .state4 {
                        background: url('../assets/image/news/healthy4.png') no-repeat;
                        background-size: 45px 45px;
                    }
                }
            }
            .tabs-view {
                display: flex;
                .tab-ul {
                    display: flex;
                    background-color: #fff;
                    border-radius: 5px;
                    box-shadow: 0px 1px 4px #E5E9F2;
                    height: 60px;
                    align-items: center;
                    width: 100%;
                    .tab-item {
                        width: 20%;
                        height: 100%;
                        line-height: 60px;
                        font-size: 18px;
                        color: #333;
                        position: relative;
                        cursor: pointer;
                    }
                    .tab-item-active{
                        height: 100px;
                        background: url('../assets/image/home/tabbg.png') no-repeat;
                        background-size: 70% 80%;
                        background-position: 40% 15px;
                        line-height: 100px;
                        color: #fff;
                    }
                    .tab-item::after {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        content: '';
                        width: 1px;
                        height: 31px;
                        background-color: #DBDBDB;
                    }
                    .tab-item:last-child::after{
                        content: normal;
                    }
                }
            }
            .echart-view {
                height: 460px;
                background-color: #fff;
                box-shadow: 0px 1px 4px #E5E9F2;
                margin-top: 15px;
                margin-bottom: 15px;
                // border: 1px solid red;
                .echarts {
                    height: 100%;
                }
            }
            .echart-view1 {
                    // background-color: #fff;
                    // box-shadow: 0px 1px 4px #E5E9F2;
                    padding-bottom: 15px;
                    margin: 20px 0;
                    padding-top: 10px;
                    display: flex;
                .echarts-view {
                    flex: 1;
              
                    display: flex;
                    flex-direction: column;
                    .echarts {
                        padding-top: 10px;
                        width: 100%;
                        height: 360px;
                        background-color: #fff;
                        box-shadow: 0px 1px 4px #E5E9F2;
                    }
                    .video-view {
                        flex: 1;
                        background-color: #000;
                        margin-top: 20px;
                    }
                }
                .oder-view-right {
                    // width: 400px;
                }
            }
            .tab-view {
                margin-top: 14px;
                width: 100%;
                // height: 390px;
                // margin-bottom: 30px;
                // display: flex;
            }
            .img-wrap {
                .img-item {
                    width: 150px;
                    height: 150px;
                }
            }
        }
        .police-view {
            margin: 14px 0 30px;
            display: flex;
            justify-content: space-between;
            .view-title {
                color: #31394D;
                margin-bottom: 5px;
                text-align: left;
            }
            .police-item {
                width: 24%;
                height: 90px;
                background-color: #fff;
                margin-bottom: 10px;                
                border-radius: 5px;
                box-shadow: 0px 1px 6px #D6DCEA;
                display: flex;
                padding: 7px 2%;
                justify-content: space-between;
                align-items: center;
                .item-center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img-view {
                        width: 30px;
                        height: 30px;
                        background-color: #FFD5D5;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 6px;
                    }
                    .title1 {
                        font-size: 12px;
                        color: #666;
                    }
                    .title2 {
                        font-size: 30px;
                        font-weight: 700;
                        margin-top: 7px;
                    }
                }
                .item-cout {
                    cursor: pointer;
                }
            }
        }
        .center-right {
            width: 270px;
            // background-color: #fff;
            margin-left: 20px;
            flex-shrink: 0;
            .police-view {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                .view-title {
                    color: #31394D;
                    margin-bottom: 5px;
                    text-align: left;
                }
                .police-item {
                    height: 60px;
                    background-color: #fff;
                    margin-bottom: 10px;                
                    border-radius: 5px;
                    box-shadow: 0px 10px 40px #D6DCEA;
                    display: flex;
                    padding: 7px 20px;
                    justify-content: space-between;
                    .item-center {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .img-view {
                            width: 30px;
                            height: 30px;
                            background-color: #FFD5D5;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .title1 {
                            font-size: 12px;
                            color: #666;
                        }
                        .title2 {
                            font-size: 24px;
                            font-weight: 700;
                            margin-top: 1px;
                        }
                    }
                }
            }
            .equi-view {
                margin-top: 6px;
                display: flex;
                flex-direction: column;   
         
              
                .view-title {
                    color: #31394D;
                    margin-bottom: 5px;
                    text-align: left;
                }
                .equi-list {
                    display: flex;
                    flex-direction: column;
                    padding: 14px 19px 0;
                    background-color: #fff;
                    box-shadow: 0px 1px 4px #E5E9F2;
                    border-radius: 5px;
                    .equi-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        .item-img {
                            width: 60px;
                            height: 60px;
                            margin-right: 11px;
                        }
                        .item-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .title1 {
                                color: #31394D;
                            }
                            .title2 {
                                font-size: 12px;
                                color: #748AA1;
                                margin-top: 2px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .user-info {
                width: 100%;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);              
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                color: #fff;
                padding:20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 20px;
                .user-name {
                    display: flex;
                    align-items: center;
                    .user-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #ccc;
                        margin-right: 10px;
                    }
                    .name {
                        font-size: 18px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        text-align: left;
                        width: 170px;
                    }
                    .sex {
                        font-size: 12px;
                        flex-shrink: 0;
                        margin: 0 3px;
                    }
                    .age {
                        flex-shrink: 0;
                    }
                }
                .user-cerner {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                    font-size: 16px;
                    .center-title {
                        flex-shrink: 0;
                        font-weight: 700;
                    }
                    .center-cotnet {
                        text-align: right;
                    }
                }
            }
        }
        .btnImg {
            width: 30px;
            height: 30px;
        }
        .el-calendar-table .el-calendar-day{
            height: 38px;
            line-height: 34px;
            padding: 0;
            span {
                display: inline-block;
                width: 28px;
                height: 35px;
                background-color: #E5E9F2;
                border-radius: 5px;
                color: #91a3b6;
            }
        }
        .el-calendar-table td {
            border: 0;
        }
        .el-calendar-table .el-calendar-day:hover {
            span {
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar-table .is-today{
            span{
                background-color: #fff;
                border: 1px solid #2176FF;
                color: #7b90a6;
            }
        }
        .el-calendar-table .is-selected {
            span{
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar__body {
            padding: 0 15px 10px;
        }
        .el-calendar__header {
            display: flex;
            flex-direction: column;
            border-bottom: 0;
            padding: 12px 20px 0;
        }
        .el-calendar-table .prev,
        .el-calendar-table .next {
            span {
                background-color: #F4F7FB;
            }
        }
    }
</style>
