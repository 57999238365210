<template>
    <div id="homeProject">
        <div class="center-left">
            <div class="center-top">
                <div class="right-abnormal">
                    <div class="title-view">
                        <span class="title-text">安全预警次数</span>
                    </div>
                    <div class="police-view">
                        <div class="police-item" v-for="item in infoData.alarmTypes" :key="item.id" @click="getList(item.alarmRecords,item.name)">
                            <div class="item-center">
                                <div class="img-view" style="background:#FFD5D5">
                                    <!-- <img :src="setImgs(item.id)" alt=""> -->
                                    <span class="iconfont" :class="setImgs(item.id)" style="color:#e54040"></span>
                                </div>
                                <span class="title1" style="color:#F85F3C">{{item.name}}</span>
                            </div>
                            <div class="item-center item-cout">
                                <span class="title1">异常</span>
                                <span class="title2" :style="{color:'#F85F3C'}">{{item.count}}</span>
                            </div>
                            <!-- <div class="item-center item-cout" @click="getList(item.doneRecords,item.name)">
                                <span class="title1">已处理</span>
                                <span class="title2" :style="{color:'#35C196'}">{{item.doneCount}}</span>
                            </div> -->
                        </div>
                        <div class="police-item">
                            <div class="item-center">
                                <div class="img-view" style="background:#FFD5D5">
                                    <span class="iconfont icon-a-zu3956" style="color:#e54040"></span>
                                </div>
                                <span class="title1" style="color:#F85F3C">无活动</span>
                            </div>
                            <div class="item-center item-cout">
                                <span class="title1">异常</span>
                                <span class="title2" :style="{color:'#F85F3C'}">{{infoData.noneActivityCount}}</span>
                            </div>
                        </div>
                        <div class="police-item">
                            <div class="item-center">
                                <div class="img-view" style="background:#FFD5D5">
                                    <span class="iconfont icon-a-zu3960" style="color:#e54040"></span>
                                </div>
                                <span class="title1" style="color:#F85F3C">出行异常</span>
                            </div>
                            <div class="item-center item-cout">
                                <span class="title1">异常</span>
                                <span class="title2" :style="{color:'#F85F3C'}">{{infoData.goOutECount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center-top" style="display:flex">
                <div class="right-abnormal" style="width:100%;">
                    <div class="title-view">
                        <span class="title-text">安全预警服务</span>
                    </div>
                    <div class="oader-view">
                        <div class="oader-item" v-for="item in infoData.alarmTypes" :key="item.id" @click="getList(item.doneRecords,item.name)">
                            <div class="icon-view">
                                <span class="iconfont" :class="setImgs(item.id)"></span>
                            </div>
                            <div class="info-view">
                                <span class="name">{{item.name}}</span>
                                <span class="count">{{item.doneCount}}</span>
                            </div>
                        </div>
                        <div class="oader-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3939"></span>
                            </div>
                            <div class="info-view">
                                <span class="name">无活动</span>
                                <!-- <span class="count">{{infoData.noneActivityWorkOrderCount}}</span> -->
                                <span class="count">{{infoData.noneActivityCount}}</span>
                            </div>
                        </div>
                        <div class="oader-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3958"></span>
                            </div>
                            <div class="info-view">
                                <span class="name">出行异常</span>
                                <!-- <span class="count">{{infoData.goOutWCount}}</span> -->
                                <span class="count">{{infoData.goOutECount}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="frequency-list">
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3939"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`总活动 ${infoData.totalActivityCount} 次`}}</span>
                            </div>
                        </div>
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3956"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`无活动 ${infoData.noneActivityCount} 次`}}</span>
                            </div>
                        </div>
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3957"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`平台干预 ${infoData.noneActivityWorkOrderCount} 次`}}</span>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="right-abnormal" style="width:50%;marginLeft:20px;">
                    <div class="title-view">
                        <span class="title-text">出行频次</span>
                    </div>
                    <div class="frequency-list">
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3958"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`出行 ${infoData.goOutCount} 次`}}</span>
                            </div>
                        </div>
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3960"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`出行异常 ${infoData.goOutECount} 次`}}</span>
                            </div>
                        </div>
                        <div class="frequency-item">
                            <div class="icon-view">
                                <span class="iconfont icon-a-zu3953"></span>
                            </div>
                            <div class="frequency-titles">
                                <span style="marginTop:5px;">{{`平台干预 ${infoData.goOutWCount} 次`}}</span>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="healthy-view">
                <div class="title-view title-views">
                    <span class="title-text">监护</span>
                    <div class="alarm_center">
                        <div class="item">
                            <span>活动</span>
                            <span>{{report1}}</span>
                            <span>次</span>
                        </div>
                        <div class="item">
                            <span>门磁</span>
                            <span>{{report2}}</span>
                            <span>次</span>
                        </div>
                        <div class="item">
                            <span>出行</span>
                            <span>{{infoData.goOutCount}}</span>
                            <span>次</span>
                        </div>
                    </div>
                </div>
                <div class="echart-view1">
                    <!-- <div class="echarts-view"> -->
                        <div id="echarts5" class="echarts" ref="chart2"></div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="center-right">
            <div class="healthy-right">
                <div class="user-info">
                    <div class="user-name">
                        <span class="name">{{baseInfo.userInfo.userName?baseInfo.userInfo.userName:'--'}}</span>
                        <span class="sex" v-if="baseInfo.userInfo.sex===0">男</span>
                        <span class="sex" v-else-if="baseInfo.userInfo.sex===1">女</span>
                        <span class="age">{{!baseInfo.userInfo.age||baseInfo.userInfo.age===null?'':baseInfo.userInfo.age+'岁'}}</span>
                    </div>
                    <div class="user-cerner">
                        <span class="center-title">地址：</span>
                        <p class="center-cotnet">{{baseInfo.userInfo.address}}</p>
                    </div>
                    <!-- <div class="user-cerner">
                        <span class="center-title">总服务时长：</span>
                        <p class="center-cotnet">240小时</p>
                    </div>
                    <div class="user-cerner">
                        <span class="center-title">当月服务时长：</span>
                        <p class="center-cotnet">189小时</p>
                    </div> -->
                    <div class="user-cerner">
                        <span class="center-title">异常总次数：</span>
                        <p class="center-cotnet">{{`${infoData.totalAlarmCount}次`}}</p>
                    </div>
                    <div class="user-cerner">
                        <span class="center-title">平台处理次数：</span>
                        <p class="center-cotnet">{{`${infoData.totalAlarmDoneCount}次`}}</p>
                    </div>
                </div>
                <div class="account-box">
                    <el-calendar v-model="value"></el-calendar>
                </div>
                <div class="video-btn" @click="videoChange">
                    <span class="iconfont icon-a-zu39831"></span>
                    <span class="video-title">智能查房</span>
                    <span class="iconfont icon-a-lujing2769" :style="{color:videoTime.length>0?'#2176FF':'#BCBCBC'}"></span>
                </div>
                <div class="healthy-bottom">
                    <div class="healthy-bottom-list" v-for="item in videoTime" :key="item.id">
                        <div class="healthy-bottom-item">
                            <span>{{`${item.createTime}`}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <!-- <iframe	
                src=""
                width="100%"
                height="400"
                id="ysOpenDevice"
                allowfullscreen
                ref="iframe"
                frameborder="0"
            >
            </iframe> -->
        <Modal footer-hide v-model="modal1" draggable scrollable :title="mdalTitle" width="600">
            <Table border :columns="columns13" :data="tableList" :height="300">
                <template slot-scope="{ row }" slot="endTime">
                    <span>{{row.endTime===null||!row.endTime?'--':row.endTime}}</span>
                </template>
                <template slot="state" slot-scope="{ row }" >
                    <span class="weichuli" v-if="row.state==0">未处理</span>
                    <div class="yichuli" v-else-if="row.state==2">已处理</div>
                    <span class="zaichuli" v-else>正在处理</span>
                </template>
            </Table>
        </Modal>
        <Modal footer-hide v-model="modalVideo" draggable scrollable title="视频监控" width="1000" @on-cancel="onCancel">
            <div class="tabs-view">
                <el-radio-group v-model="tabPosition" @change="tabChange">
                    <el-radio-button :label="item.position" v-for="(item,index) in cameras" :key="index">{{item.position?item.position:'--'}}</el-radio-button>
                </el-radio-group>
                <el-button v-if="cameras.length>0&&!cameras[camerasIndex].msg&&cameras[camerasIndex].url" @click="SubmitRoom" :loading="btnLoading">查房</el-button>
            </div>
            <div style="height:400px" v-if="cameras.length>0&&cameras[camerasIndex].msg">{{`播放错误:${cameras[camerasIndex].msg}`}}</div>
            <div style="height:400px" v-else-if="cameras.length>0&&!cameras[camerasIndex].url">请检查设备是否在线</div>
            <div v-else id="video-container" style="width:100%;minHeight:400px"></div>
        </Modal>
    </div>
</template>

<script>
    var echarts = require('echarts/lib/echarts');
    let myChart,player = null
    import axios from '@/utils/http.js'
    import wodrHttp from '@/utils/wodrHttp'
    import {mapState} from 'vuex'
    import countTo from 'vue-count-to'
    import EZUIKit from 'ezuikit-js'
    export default {
        name: "Home",
        components: {
            countTo
        },
        data () {
            return {
                abnormal:{
                    hujiao:{
                        yichang:[],
                        chuli:[]
                    },
                    yanwu:{
                        yichang:[],
                        chuli:[]
                    },
                    ranqi:{
                        yichang:[],
                        chuli:[]
                    },
                    shuiqin:{
                        yichang:[],
                        chuli:[]
                    }
                },
                report1:0,
                report2:0,
                modal1:false,
                columns13: [
                    {
                        title: '开始时间',
                        key: 'startTime',
                        align: "center"
                    },
                    {
                        title: '结束时间',
                        slot: 'endTime',
                        align: "center"
                    },
                    {
                        title: '状态',
                        slot: 'state',
                        align: "center"
                    }
                ],
                tableList:[],
                mdalTitle:'',
                isSetup:'',
                value:'',
                echartMonitor:{},
                infoData:{
                    totalActivityCount:0, /*总活动次数*/
                    noneActivityCount:0, /*无活动次数*/
                    noneActivityWorkOrderCount:0, /*无活动关联工单数*/
                    goOutCount: 0,  /*出行次数*/
                    goOutECount: 0,  /*出行异常次数*/
                    goOutWCount: 0,  /*出行异常关联工单数*/
                    alarmTypes:[],
                    totalAlarmCount: 0,  /*报警总次数*/
                    totalAlarmDoneCount: 0,  /*报警总处理次数*/
                },
                users:{
                    name: "--",
                    belongAreaName: "--",
                    genderName:'',
                    age:'--',
                    tenantsName:''
                },
                reportData1:[],
                reportData2:[],
                echartTimes:[],
                dateTime:this.setTime(),
                imgs:{
                    police1:require('@/assets/image/home/police1.png'), 
                    police2:require('@/assets/image/home/police2.png'),
                    police3:require('@/assets/image/home/police3.png'),
                    police4:require('@/assets/image/home/police4.png'),
                },
                modalVideo:false,
                videoTime:[],
                tabPosition:'',
                cameras:[],
                camerasIndex:0,
                btnLoading:false
            }
        },
        mounted(){
            if(!this.vcCardNO) return
            this.getAbnormalData()
            this.getchartData()
            console.log(this.baseInfo)
        },
        computed:{...mapState(['userId','type','vcCardNO','phone','baseInfo'])},
        destroyed () {
            player = null
        },
        methods: {
            onCancel(){
                if(this.cameras[this.camerasIndex].msg||!this.cameras[this.camerasIndex].url) return
                player.stop()
                this.camerasIndex = 0
            },
            tabChange(){
                this.camerasIndex = this.cameras.findIndex(item=>{ return item.position===this.tabPosition})
            },
            SubmitRoom(){
                this.btnLoading = true
                axios.post(this.$api.SubmitRoomCheck,{cardNo:this.vcCardNO}).then(res=>{
                    this.btnLoading = false
                    this.$Message.info(res.data.message);
                })
            },
            videoChange(){
                this.modalVideo = true
                if(this.cameras[this.camerasIndex].msg||!this.cameras[this.camerasIndex].url) return
                this.$nextTick(()=>{
                    if(player===null) {
                        player =  new EZUIKit.EZUIKitPlayer({
                            autoplay: true,
                            id: "video-container",
                            accessToken:this.cameras[this.camerasIndex].cameraToken,
                            url: this.cameras[this.camerasIndex].url,
                            template: "voice",
                            plugin: ['talk']
                        });
                    }else {
                        player.play({url:this.cameras[this.camerasIndex].url});
                    }
                })
            },
            getAbnormalData(){
                //紧急呼叫
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'c530db59-713b-4aa7-826f-e050208178ee').then(res=>{ //紧急呼叫异常
                //     if(res.data.code===200) {
                //         this.abnormal.hujiao.yichang = res.data.result.alarmDataList
                //         this.abnormal.hujiao.chuli = res.data.result.doneDataList
                //     }
                // })
                // //烟雾告警
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'9807fd8e-82b1-4751-ab10-363c6b889c22').then(res=>{
                //     if(res.data.code===200) {
                //         this.abnormal.yanwu.yichang = res.data.result.alarmDataList
                //         this.abnormal.yanwu.chuli = res.data.result.doneDataList
                //     }

                // })
                // //燃气告警
                // wodrHttp.get('WorkOrder/SumUserWorkOrder?cardNo='+this.vcCardNO+'&typeID='+'617ef70c-e907-4e21-8807-eaa520609abf').then(res=>{
                //     if(res.data.code===200) {
                //         this.abnormal.ranqi.yichang = res.data.result.alarmDataList
                //         this.abnormal.ranqi.chuli = res.data.result.doneDataList
                //     }
                // })
            },
            getchartData(){
                this.$Spin.show({
                    render: (h) => {
                        return h('div', [
                            h('Icon', {
                                'class': 'demo-spin-icon-load',
                                props: {
                                    // type: 'ios-sunny-outline',
                                    // type: 'md-sunny',
                                    // type: 'md-resize',
                                    type: 'md-nuclear',
                                    size: 22
                                }
                            }),
                            h('div', '加载中...')
                        ])
                    }
                });
                axios.get(this.$api.GetFamilyBedDataReport + `?cardNo=${this.vcCardNO}&day=${this.dateTime}`).then(res=>{
                    console.log(res)
                    const {
                        totalActivityCount, /*总活动次数*/
                        noneActivityCount, /*无活动次数*/
                        noneActivityWorkOrderCount, /*无活动关联工单数*/
                        goOutCount,  /*出行次数*/
                        goOutECount,  /*出行异常次数*/
                        goOutWCount,  /*出行异常关联工单数*/
                        alarmTypes,
                        totalAlarmCount,  /*报警总次数*/
                        totalAlarmDoneCount  /*报警总处理次数*/
                    } = res.data.result
                    this.report1 = res.data.result.totalActivityCount
                    this.report2 = res.data.result.totalDoorDataCount
                    this.echartTimes = res.data.result.activityReport.times
                    this.reportData1 = res.data.result.activityReport.data
                    this.reportData2 = res.data.result.doorReport.data
                    this.infoData = {
                        totalActivityCount, /*总活动次数*/
                        noneActivityCount, /*无活动次数*/
                        noneActivityWorkOrderCount, /*无活动关联工单数*/
                        goOutCount,  /*出行次数*/
                        goOutECount,  /*出行异常次数*/
                        goOutWCount,  /*出行异常关联工单数*/
                        alarmTypes,
                        totalAlarmCount,  /*报警总次数*/
                        totalAlarmDoneCount  /*报警总处理次数*/
                    }
                    this.$nextTick(()=>{
                        this.echart6()
                    })
                    this.cameras = res.data.result.cameras
                    this.tabPosition = res.data.result.cameras.length>0?res.data.result.cameras[0].position:''
                }).then(()=>{
                    axios.get(this.$api.GetByCardNo + `?cardNo=${this.vcCardNO}&day=${this.dateTime}`).then(res=>{
                        this.videoTime = res.data.result
                    })
                })

            },
            getList(item,name){
                console.log(item)
                this.isSetup = item.state
                this.tableList = item
                this.mdalTitle = name
                this.modal1 = true
            },
            echart6(data){
                var charts = {
                    unit: 'Kbps',
                    names: ['活动', '门磁'],
                    lineX: this.echartTimes,
                    value: [
                        this.reportData1,
                        this.reportData2
                    ]

                }
                var color = ['rgba(23, 255, 243', 'rgba(255,100,97']
                var lineY = []
                for (var i = 0; i < charts.names.length; i++) {
                    var x = i
                    if (x > color.length - 1) {
                        x = color.length - 1
                    }
                    var data = {
                        name: charts.names[i],
                        type: 'line',
                        color: color[x] + ')',
                        smooth: true,
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: color[x] + ', 0.3)'
                                }, {
                                    offset: 0.8,
                                    color: color[x] + ', 0)'
                                }], false),
                                shadowColor: 'rgba(0, 0, 0, 0.1)',
                                shadowBlur: 10
                            }
                        },
                        symbol: 'circle',
                        symbolSize: 5,
                        data: charts.value[i]
                    }
                    lineY.push(data)
                }
                lineY[0].markLine = {
                    silent: true,
                    data: [{
                        yAxis: 5
                    }, {
                        yAxis: 100
                    }, {
                        yAxis: 200
                    }, {
                        yAxis: 300
                    }, {
                        yAxis: 400
                    }]
                }
                var myChart1 = echarts.init(this.$refs.chart2);
                myChart1.setOption({},true)
                myChart1.setOption({
                    legend: {
                        data: charts.names,
                        textStyle: {
                            fontSize: 12,
                            color: 'rgb(0,253,255,0.6)'
                        },
                        right: '4%'
                    },
                    grid:{
                        left:'5%',
                        right:'5%',
                        bottom:50,
                        top:50
                    },
                    xAxis: {
                        type: 'category',
                        // data: this.reportData.times,
                        data: charts.lineX,
                        axisTick:{
                            inside:true
                        },
                        axisLine:{
                            lineStyle:{
                                color:'#EBEDF4'
                            }
                        },
                        axisLabel:{
                            color:'#99aaba'
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                color: 'rgb(0,253,255,0.6)'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: 'rgb(23,255,243,0.3)'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgb(0,253,255,0.6)'
                            }
                        }
                    },
                    series: lineY
                });
            },
            setTime(){
                let time = new Date()
                let year = time.getFullYear()
                let mont = time.getMonth()+1
                let day = time.getDate()
                return year+'-'+mont+'-'+day
            },
            setImgs(id,state){
                let result
                if(state) {
                    switch (id) {
                        case 'c530db59-713b-4aa7-826f-e050208178ee':
                            result = state==1?'#F85F3C':'#FFD5D5'
                            break;
                        case '9807fd8e-82b1-4751-ab10-363c6b889c22':
                            result = state==1?'#35C196':'#EEFFFA'
                            break;
                        case '617ef70c-e907-4e21-8807-eaa520609abf':
                            result = state==1?'#FFB000':'#FFEABB'
                            break;
                        default:
                            result = state==1?'#2176FF':'#D3E6F5'
                    }
                }else {
                    switch (id) {
                        case 'c530db59-713b-4aa7-826f-e050208178ee':
                            result = 'icon-a-zu1407'
                            break;
                        case '9807fd8e-82b1-4751-ab10-363c6b889c22':
                            result = 'icon-a-zu1412'
                            break;
                        case '617ef70c-e907-4e21-8807-eaa520609abf':
                            result = 'icon-a-zu1406'
                            break;
                        default:
                            result = 'icon-a-zu39681'
                    }
                }
                return result
            }
        },
        watch: {
            value(val,avl){
                const times = new Date()
                const endTime = times.getFullYear()+'-'+(times.getMonth()+1)+'-'+times.getDate()
                if(new Date(endTime).getTime()<val.getTime()) {
                    this.value = new Date(endTime)
                    this.dateTime = endTime
                }else {
                    let starTime = val.getFullYear()+'-'+(val.getMonth()+1)+'-'+val.getDate()
                    this.dateTime = starTime
                    this.getchartData()
                }
               
            }
        }
    };
</script>

<style lang="scss">
    .tabs-view {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    #homeProject {
        width: 100%;
        min-height: 700px;
        padding: 20px;
        display: flex;
        overflow-y: auto;
        .healthy-bottom {
            flex: 1;
            overflow: auto;
            .healthy-bottom-list {
                display: flex;
                flex-direction: column;

                .healthy-bottom-item {
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 20px;
                    height: 47px;
                    background-color: #fff;
                    margin-top: 10px;
                    color: #999999;
                }
            }
        }

        .abnormalState {
            display: flex;
            justify-content: space-between;
            .abnormalState-item {
                display: flex;
                align-items: center;
                box-shadow: 0px 4px 15px #D6DCEA;
                background-color: #fff;
                padding: 15px;
                width: 19%;
                justify-content: space-between;
                border-radius: 5px;
                .item-left {
                    display: flex;
                    align-items: center;
                    .img-view {
                        width: 30px;
                        height: 30px;
                        background-color: #FFD5D5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        margin-right: 5px;
                        .item-img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .item-title1 {
                        color: #666;
                        font-size: 17px;
                    }
                }
                .item-title2 {
                    font-size: 16px;
                    color: #F85F3C;
                }
            }
        }
        .chuandian-img {
            width: 100%;
            height: 180px;
            margin-bottom: 7px;
        }
        .title-view {
            display: flex;
            font-size: 20px;
            color: #333;
            font-weight: 700;
            padding-left: 14px;
            position: relative;
            margin-bottom: 20px;
        }
        .video-btn {
            height: 60px;
            box-shadow: 0px 10px 40px #D6DCEA;
            border-radius: 5px;
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .video-title {
                color: #2176FF;
                font-weight: bold;
                font-size: 22px;
                margin: 0 70px 0 10px;
            }
            .icon-a-zu39831 {
                color: #2176FF;
            }
            .iconfont {
                font-size: 30px;
            }
        }
        .title-views {
            align-items: center;
            .alarm_center {
                display: flex;
                flex: 1;
                font-size: 16px;
                .item {
                    margin-left: 30px;
                    display: flex;
                    align-items: center;
                    span:nth-of-type(2) {
                        color: #18d777;
                        font-size: 30px;
                        margin: 0 10px;
                    }
                }
            }
        }
        .healthy-view {
            flex: 1;
            display: flex;
            flex-direction: column;
            .healthy-center {
                width: 100%;
                display: flex;
                .healthy-left {
                    flex: 1;
                }
            }
        }
        .healthy-right {
            height: 100%;
            display: flex;
            flex-direction: column;
            .account-box {
                background: #FFFFFF;            
                border-radius: 5px;
                box-shadow: 0px 1px 4px #E5E9F2;
                overflow: hidden;
            }
            .healthy-bottom {
                .healthy-bottom-titles {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 20px;
                    background-color: #fff;
                    box-shadow: 0px 4px 15px #D6DCEA;
                    border-radius: 5px;
                    margin-top: 30px;
                    .frequency {
                        display: flex;
                        align-items: center;
                        .img-view {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background-color: #FFEABB;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            .title-img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                    .frequency-num {
                        color: #F85F3C;
                        font-size: 23px;
                        font-weight: 700;
                    }
                }
                .healthy-bottom-list {
                    display: flex;
                    flex-direction: column;
                    .healthy-bottom-item {
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;
                        height: 47px;
                        background-color: #fff;
                        margin-top: 10px;
                        color: #999999;
                    }
                }
            }
        }
        .oder-view-imgs {
            width: 100%;
            display: flex;
            .oder-view-left {
                flex: 1;
            }
        }
        .oder-view {
            // display: flex;
            margin-top: 30px;
            .oder-center {
                flex: 1;
                .title-view {
                    margin-bottom: 0;
                }
            }
            .oder-right {
                width: 350px;
                height: 850px;
                background: url('../assets/image/right-bg.png') no-repeat;
                background-size: 100% 100%;
                // margin-top: 40px;
                margin-left: 20px;
                border-radius: 5px;
            }
        }
        .title-view::after {
            content: '';
            width: 6px;
            height: 22px;
            background: #2176FF;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 1px;
        }
        .center-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            .center-top {
                .frequency-list {
                    display: flex;
                    background-color: #fff;
                    box-shadow: 0px 10px 40px #D6DCEA;
                    border-radius: 5px;
                    padding: 15px;
                    justify-content: space-between;
                    .frequency-item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .icon-view {
                            width: 60px;
                            height: 60px;
                            background-color: #2176FF;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            flex-shrink: 0;
                            .iconfont {
                                color: #fff;
                                font-size: 36px;
                            }
                        }
                        .frequency-titles {
                            margin-left: 5px;
                            margin-top: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 17px;
                            color: #2176FF;
                        }
                    }
                }
            }
            .left-abnormal {
                width: 50%;
                overflow: hidden;
                .abnormal-list {
                    display: flex;
                    .abnormal-item {
                        width: 33.33%;
                        height: 90px;
                        background-color: #F85F3C;
                        border-radius: 5px;
                        box-shadow: 0px 10px 40px #D6DCEA;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 3%;
                        .item-img {
                            width: 48px;
                            height: 50px;
                        }
                        .item-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            .item-title {
                                font-size: 17px;
                                color: #fff;
                            }
                            .nums {
                                margin-top: 5px;
                                color: #fff;
                                font-size: 17px;
                                .num {
                                    font-size: 29px;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
            .right-abnormal {
                overflow: hidden;
                .right-list {
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    .healthy-item {
                        display: flex;
                        align-items: center;
                        width: 215px;
                        justify-content: center;
                        margin-right: 30px;
                        height: 90px;
                        background-color: #fff;
                        box-shadow: 0px 4px 15px #D6DCEA;
                        margin-bottom: 23px;
                        border-radius: 5px;
                        .img-view {
                            width: 50px;
                            height: 50px;
                            background-color: #D8FFFD;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .healthy-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            margin-left: 5%;
                            .info-title {
                                font-size: 17px;
                                color: #666;
                                margin-bottom: 4px;
                            }
                            .info-num {
                                font-size: 28px;
                                color: #31394D;
                                font-weight: 700;
                            }
                            .info-time {
                                font-size: 12px;
                                color: #31394D;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
            .echart-view1 {
                    // background-color: #fff;
                    // box-shadow: 0px 1px 4px #E5E9F2;
                    // padding-bottom: 15px;
                    // margin: 20px 0;
                    // padding-top: 10px;
                    display: flex;
                    flex: 1;
                    .echarts {
                        padding-top: 10px;
                        width: 100%;
                        height: 100%;
                        background-color: #fff;
                        // box-shadow: 0px 1px 4px #E5E9F2;
                    }
                .echarts-view {
                    width: 100%;
                    height: 100%;
                    
                    // .video-view {
                    //     flex: 1;
                    //     background-color: #000;
                    //     margin-top: 20px;
                    // }
                }
                .oder-view-right {
                    // width: 400px;
                }
            }
            .tab-view {
                margin-top: 14px;
                width: 100%;
                // height: 390px;
                // margin-bottom: 30px;
                // display: flex;
            }
            .img-wrap {
                .img-item {
                    width: 150px;
                    height: 150px;
                }
            }
        }
        .oader-view {
            display: flex;
            margin: 14px 0 30px;
            .oader-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 16.6%;
                height: 70px;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0px 1px 6px #D6DCEA;
                padding: 7px 1%;
                margin-right: 10px;
                .icon-view {
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2176FF;
                    border-radius: 5px;
                    .iconfont {
                        font-size: 28px;
                        color: #fff;
                    }
                }
                .info-view {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .name {
                        color: #666;
                    }
                    .count {
                        font-weight: 700;
                        font-size: 24px;
                        color: #2176FF;
                        margin-top: 5px;
                    }
                }
            }
            .oader-item:last-child {
                margin-right: 0;
            }
        }
        .police-view {
            margin: 14px 0 30px;
            display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            .view-title {
                color: #31394D;
                margin-bottom: 5px;
                text-align: left;
            }
            .police-item {
                width: 16.6%;
                height: 70px;
                background-color: #fff;
                margin-bottom: 10px;                
                border-radius: 5px;
                box-shadow: 0px 1px 6px #D6DCEA;
                display: flex;
                padding: 7px 2%;
                justify-content: space-between;
                align-items: center;
                margin-right: 10px;
                .item-center {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img-view {
                        width: 30px;
                        height: 30px;
                        background-color: #FFD5D5;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 6px;
                    }
                    .title1 {
                        font-size: 12px;
                        color: #666;
                    }
                    .title2 {
                        font-size: 24px;
                        font-weight: 700;
                        margin-top: 7px;
                    }
                }
                .item-cout {
                    cursor: pointer;
                }
            }
            .police-item:last-child {
                margin-right: 0;
            }
        }
        .center-right {
            width: 270px;
            // background-color: #fff;
            margin-left: 20px;
            flex-shrink: 0;
            .police-view {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                .view-title {
                    color: #31394D;
                    margin-bottom: 5px;
                    text-align: left;
                }
                .police-item {
                    height: 60px;
                    background-color: #fff;
                    margin-bottom: 10px;                
                    border-radius: 5px;
                    box-shadow: 0px 10px 40px #D6DCEA;
                    display: flex;
                    padding: 7px 20px;
                    justify-content: space-between;
                    .item-center {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .img-view {
                            width: 30px;
                            height: 30px;
                            background-color: #FFD5D5;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .title1 {
                            font-size: 12px;
                            color: #666;
                        }
                        .title2 {
                            font-size: 24px;
                            font-weight: 700;
                            margin-top: 1px;
                        }
                    }
                }
            }
            .equi-view {
                margin-top: 6px;
                display: flex;
                flex-direction: column;   
         
              
                .view-title {
                    color: #31394D;
                    margin-bottom: 5px;
                    text-align: left;
                }
                .equi-list {
                    display: flex;
                    flex-direction: column;
                    padding: 14px 19px 0;
                    background-color: #fff;
                    box-shadow: 0px 1px 4px #E5E9F2;
                    border-radius: 5px;
                    .equi-item {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        .item-img {
                            width: 60px;
                            height: 60px;
                            margin-right: 11px;
                        }
                        .item-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .title1 {
                                color: #31394D;
                            }
                            .title2 {
                                font-size: 12px;
                                color: #748AA1;
                                margin-top: 2px;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .user-info {
                width: 100%;
                // height: 143px;
                background: linear-gradient(180deg, #679CF6 0%, #4072EE 100%);              
                box-shadow: 0px 5px 20px #C0C7D6;
                border-radius: 5px;
                color: #fff;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 20px;
                .user-name {
                    display: flex;
                    align-items: center;
                    .user-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        background-color: #ccc;
                        margin-right: 10px;
                    }
                    .name {
                        font-size: 18px;
                        margin-right: 10px;
                        flex-shrink: 0;
                        text-align: left;
                        width: 170px;
                    }
                    .sex {
                        font-size: 12px;
                        flex-shrink: 0;
                        margin: 0 3px;
                    }
                    .age {
                        flex-shrink: 0;
                    }
                }
                .user-cerner {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 5px;
                    font-size: 16px;
                    .center-title {
                        flex-shrink: 0;
                        font-weight: 700;
                    }
                    .center-cotnet {
                        text-align: right;
                    }
                }
            }
        }
        .btnImg {
            width: 30px;
            height: 30px;
        }
        .el-calendar-table .el-calendar-day{
            height: 38px;
            line-height: 34px;
            padding: 0;
            span {
                display: inline-block;
                width: 28px;
                height: 35px;
                background-color: #E5E9F2;
                border-radius: 5px;
                color: #91a3b6;
            }
        }
        .el-calendar-table td {
            border: 0;
        }
        .el-calendar-table .el-calendar-day:hover {
            span {
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar-table .is-today{
            span{
                background-color: #fff;
                border: 1px solid #2176FF;
                color: #7b90a6;
            }
        }
        .el-calendar-table .is-selected {
            span{
                background-color: #2176FF;
                color: #fff;
            }
        }
        .el-calendar__body {
            padding: 0 15px 10px;
        }
        .el-calendar__header {
            display: flex;
            flex-direction: column;
            border-bottom: 0;
            padding: 12px 20px 0;
        }
        .el-calendar-table .prev,
        .el-calendar-table .next {
            span {
                background-color: #F4F7FB;
            }
        }
    }
</style>
