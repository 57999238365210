<template>
    <div id="schemeView">
        <div class="abnormal">
            <Card style="width:100%;height:100%">
                <div slot="title" class="titles">
                    <img class="img" src="@/assets/image/hetong.png" alt="">
                    <span class="title">合同</span>
                </div>
                <div class="abnormal_center" v-if="srcList1.length>0">
                    <div class="item" v-for="item in srcList1" :key="item.id">
                        <el-image 
                            style="width: 100px; height: 100px"
                            :src="item.url" 
                            :z-index="2"
                            :preview-src-list="urlList1">
                        </el-image>
                        <span class="item-name">{{item.name}}</span>
                    </div>
                </div>
                <div class="noneTile" v-else>暂无合同附件</div>
            </Card>
        </div>
    </div>
</template>

<script>
    import axios from '@/utils/http.js'
    import {mapState} from 'vuex'
    export default {
        computed:{...mapState(['vcCardNO'])},
        data () {
            return {
                srcList1:[],
                urlList1:[],
            }
        },
        mounted () {
            axios.get('FamilyBed/GetFamilyBedAnnex?cardNo='+this.vcCardNO).then(res=>{
                if(res.data.code===200) {
                    this.srcList1 = res.data.result.type1
                    res.data.result.type1.forEach(item=>{
                        this.urlList1.push(item.url)
                    })
                }
            })
        }
    }
</script>

<style lang="scss">
    #schemeView{
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
        .abnormal {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 0px 20px #ecedf4;
            margin: 20px 20px 0;
            .abnormal_title {
                display: flex;
                align-items: center;
                font-size: 20px;
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #e6e6e6;
                .img {
                    margin:0 8px 0 24px;
                    width: 24px;
                    height: 24px;
                }
            }
            .abnormal_center {
                padding: 30px;
                display: flex;
                flex-wrap: wrap;
                .item {
                    display: flex;
                    flex-direction: column;
                    margin-right: 50px;
                    .item-name {
                        margin-top: 10px;
                    }
                }
            }
            .noneTile {
                height: 150px;
                line-height: 150px;
                color: #666;
            }
        }
        .titles {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 700;
            .title {
                margin-left: 5px;
            }
            .img {
                width: 20px;
                height: 20px;
            }
        }
        .ivu-card-body {
            padding: 0;
        }
    }
</style>